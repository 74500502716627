import moment from "moment";
import { Flex, Text, Icon, Tooltip, Badge } from "@chakra-ui/react";
import { BsCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import ConfirmSwitchMessage from "components/ConfirmSwitchMessage/ConfirmSwitchMessage";

export function showTextFloor(floor) {
  let text = "";
  switch (floor) {
    case 0:
    case 10:
    case 11:
    case 111:
    case 100:
      text = "GROUND FLOOR";
      break;
    case 1:
    case 20:
    case 21:
    case 22:
    case 222:
    case 200:
      text = "FIRST FLOOR";
      break;
    case 2:
    case 30:
    case 31:
    case 33:
    case 333:
    case 300:
      text = "SECOND FLOOR";
      break;
    default:
      break;
  }
  return text;
}
export function showStatusVoucherDetail(val) {
  if (val === 1) {
    return "UNPRINTED";
  } else if (val === 2) {
    return "PRINTED";
  } else {
    return "USED";
  }
}

export function typeRatePlan(val) {
  switch (val) {
    case 1:
      return "FAMILY PACKAGE";
    case 2:
      return "COMBO";
    case 3:
      return "EARLY BIRD";
    case 4:
      return "STANDARD";
    case 5:
      return "CAMPAIGN";
    case 6:
      return "PROMOTION";
    case 7:
      return "VOUCHER";
    default:
      break;
  }
}

export function zoneName(val) {
  switch (val) {
    case 1:
      return "aah![A]";
    case 2:
      return "ooh![O]";
    case 3:
      return "wow![W]";
    default:
      break;
  }
}

export function colorRatePlan(val) {
  switch (val) {
    case 1:
      return "#4FD1C5";
    case 2:
      return "#BF4FD1";
    case 3:
      return "#D1BC4F";
    case 4:
      return "#1C1B17";
    case 5:
      return "#379635";
    case 6:
      return "#2557D6";
    default:
      break;
  }
}

export function bookingStatus(status) {
  switch (status) {
    case 1:
      return "Success";
    case 2:
      return "Cancel";
    case 3:
      return "Unpaid";
    default:
      break;
  }
}

export function paymentType(payment_type) {
  switch (payment_type) {
    case 1:
      return "Paid";
    case 2:
      return "Liability";
    case 3:
      return "Unpaid";
    default:
      break;
  }
}

export function showReviewType(payment_type) {
  switch (payment_type) {
    case 1:
      return "TripAdvisor";
    case 2:
      return "Facebook";
    case 3:
      return "Guestbook";
    default:
      return "Instagram";
  }
}

export const columnsDataUser = [
  {
    header: "Full name",
    accessorKey: "fullname",
    size: 60,
  },
  {
    header: "Phone",
    accessorKey: "phone",
    size: 60,
  },
  {
    header: "Email",
    accessorKey: "email",
    size: 60,
  },
  {
    header: "Roles",
    accessorKey: "role",
    enableSorting: false,
    Cell: ({ row }) => {
      return (
        <span style={{ whiteSpace: "normal" }}>
          {row.original.role.reduce((accumulator, user) => {
            if (accumulator === "") {
              return user.name;
            } else {
              return `${accumulator}, ${user.name}`;
            }
          }, "")}
        </span>
      );
    },
  },
  {
    header: "Creation Date",
    accessorKey: "created_at",
    Cell: ({ row }) => {
      const date = moment(row.original.created_at);
      return <span>{date.format("DD/MM/YYYY")}</span>;
    },
    size: 60,
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataRole = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataShow = [
  {
    header: "Name",
    accessorKey: "name",
    size: 50,
  },
  {
    header: "Code",
    accessorKey: "code",
    size: 50,
  },
  {
    header: "Description",
    accessorKey: "description",
    size: 720,
    Cell: ({ row }) => {
      return (
        <div style={{ whiteSpace: "wrap" }}>{row.original.description}</div>
      );
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataUnit = [
  {
    header: "Unit Name",
    accessorKey: "name",
    size: 80,
  },
  {
    header: "Unit Code",
    accessorKey: "code",
    size: 80,
  },
  {
    header: "Channel",
    accessorKey: "channel",
    Cell: ({ row }) => {
      return <span>{row.original.channel.name}</span>;
    },
    size: 100,
  },
  {
    header: "Liability",
    accessorKey: "is_liability",
    size: 50,
    Cell: ({ row }) => {
      return <span>{row.original.is_liability === 1 ? "Yes" : "No"}</span>;
    },
  },
  {
    header: "Description",
    accessorKey: "description",
    size: 50,
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataChannel = [
  {
    header: "Channel Name",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataZone = [
  {
    header: "Zone Name",
    accessorKey: "name",
  },
  {
    header: "Code",
    accessorKey: "code",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataVenue = [
  {
    header: "Venue Name",
    accessorKey: "name",
  },
  {
    header: "Address",
    accessorKey: "address",
  },
  {
    header: "Location",
    accessorKey: "location",
    Cell: ({ row }) => {
      return row.original.location.name;
    },
  },
  {
    header: "Description",
    accessorKey: "description",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataLocation = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataVoucherCode = [
  {
    header: "Code Number",
    accessorKey: "code",
  },
  {
    header: "Serial Number",
    accessorKey: "serial",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <Badge
            bg={"green.400"}
            fontSize="14px"
            p="3px 10px"
            borderRadius="8px"
            color="white"
          >
            {showStatusVoucherDetail(row.original.status)}
          </Badge>
        </div>
      );
    },
  },
];
export const columnsDataRatePlanUnitsV2 = [
  {
    header: "ID",
    accessorKey: "id",
  },
  {
    header: "Name Unit",
    accessorKey: "name",
  },
  {
    header: "Name Channel",
    accessorKey: "channel",
    size: 100,
    Cell: ({ row }) => {
      return row.original.channel.name;
    },
  },
  {
    header: "PIC",
    Cell: ({ row }) => {
      return row.original.pic.user.status === 0 ? (
        <span style={{ color: "green" }}>Not Assign</span>
      ) : (
        <span style={{ color: "red" }}>Assigned</span>
      );
    },
  },
];
export const columnsDataRatePlanUnits = [
  {
    header: "id",
    accessorKey: "id",
  },
  {
    header: "Unit Name",
    accessorKey: "name",
  },
  {
    header: "Name Channel",
    accessorKey: "channel",
    size: 100,
    Cell: ({ row }) => {
      return <span>{row.original.channel.name}</span>;
    },
  },
];
export const columnsViewDataRatePlanUnits = [
  {
    header: "Unit Name",
    accessorKey: "unit",
    Cell: ({ row }) => {
      return <span>{row.original.unit.name}</span>;
    },
  },
  {
    header: "Name Channel",
    accessorKey: "channel",
    size: 100,
    Cell: ({ row }) => {
      return <span>{row.original.channel.name}</span>;
    },
  },
  {
    header: "Commission 1",
    accessorKey: "commissions1",
    enableSorting: false,
    Cell: ({ row }) => {
      return (
        <Flex minW={"120px"} direction="column">
          {row.original.commissions.map((i) => (
            <span key={i.id}>
              {zoneName(i.zone_id)} : {i.com1?.toLocaleString("en-US")}
            </span>
          ))}
        </Flex>
      );
    },
  },
  {
    header: "Commission 2",
    accessorKey: "commissions2",
    enableSorting: false,
    Cell: ({ row }) => {
      return (
        <Flex minW={"120px"} direction="column">
          {row.original.commissions.map((i) => (
            <span key={i.id}>
              {zoneName(i.zone_id)} : {i.com2?.toLocaleString("en-US")}
            </span>
          ))}
        </Flex>
      );
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsAddUnitWithRateplanUnit = [
  {
    header: "RatePlan Code",
    accessorKey: "rateplanCode",
    size: 140,
  },
  {
    header: "RatePlan Name",
    accessorKey: "rateplanName",
    size: 140,
  },
  {
    header: "Program Applied",
    accessorKey: "program_applied",
  },
  {
    header: "Commission 1",
    accessorKey: "commissions1",
    enableSorting: false,
    Cell: ({ row }) => {
      return (
        <Flex minW={"120px"} direction="column">
          {row.original.commissions1.map((i) => (
            <span key={i.id}>
              {zoneName(i.zone_id)} : {i.com1?.toLocaleString("en-US")}
            </span>
          ))}
        </Flex>
      );
    },
  },
  {
    header: "Commission 2",
    accessorKey: "commissions2",
    enableSorting: false,
    Cell: ({ row }) => {
      return (
        <Flex minW={"120px"} direction="column">
          {row.original.commissions2.map((i) => (
            <span key={i.id}>
              {zoneName(i.zone_id)} : {i.com2?.toLocaleString("en-US")}
            </span>
          ))}
        </Flex>
      );
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage
            isLocalUpdate={true}
            getValue={(status, rateplanID) =>
              row.original?.reCallValue(status, rateplanID)
            }
            row={row}
          />
        </div>
      );
    },
  },
];
export const columnsDataRatePlan = [
  {
    header: "Code",
    accessorKey: "code",
    size: 80,
  },
  {
    header: "Name",
    accessorKey: "name",
    size: 100,
  },
  {
    header: "Program Applied",
    accessorKey: "program_applied",
    size: 80,
  },
  {
    header: "Type",
    accessorKey: "type",
    size: 100,
    Cell: ({ row }) => {
      return (
        <Flex align="center">
          <Icon
            as={BsCircleFill}
            w="8px"
            h="8px"
            color={colorRatePlan(row.original.type)}
            me="6px"
          />
          <Text fontSize="sm">{typeRatePlan(row.original.type)}</Text>
        </Flex>
      );
    },
  },
  {
    header: "Show",
    accessorKey: "show",
    Cell: ({ row }) => {
      return <span>{row.original.show.name}</span>;
    },
    size: 80,
  },
  {
    header: "Venue",
    accessorKey: "venue",
    Cell: ({ row }) => {
      return <span>{row.original.venue.name}</span>;
    },
    size: 80,
  },
  {
    header: "From Date",
    accessorKey: "from_date",
    Cell: ({ row }) => {
      const date = moment(row.original.from_date);
      return <span>{date.format("DD/MM/YYYY")}</span>;
    },
    size: 70,
  },
  {
    header: "To Date",
    accessorKey: "to_date",
    Cell: ({ row }) => {
      const date = moment(row.original.to_date);
      return <span>{date.format("DD/MM/YYYY")}</span>;
    },
    size: 80,
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 60,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataVoucher = [
  {
    header: "Voucher Name",
    accessorKey: "name",
    size: 60,
  },
  {
    header: "Quantity",
    accessorKey: "quantity",
    size: 60,
    Cell: ({ row }) => {
      return <span>{row.original.quantity.toLocaleString("en-US")}</span>;
    },
  },
  {
    header: "Min los",
    accessorKey: "min_los",
    size: 40,
    Cell: ({ row }) => {
      return <span>{row.original.min_los.toLocaleString("en-US")}</span>;
    },
  },
  {
    header: "Max los",
    accessorKey: "max_los",
    size: 0,
    minSize: 0,
    Cell: ({ row }) => {
      return <span>{row.original.max_los.toLocaleString("en-US")}</span>;
    },
  },
  {
    header: "Voucher Type",
    accessorKey: "voucher_type",
    size: 60,
    Cell: ({ row }) => {
      return (
        <span>
          {row.original.voucher_type === 2 ? "Sell Voucher" : "Issue Voucher"}
        </span>
      );
    },
  },
  {
    header: "Zone",
    accessorKey: "voucher_configs",
    size: 0,
    minSize: 0,
    enableSorting: false,
    Cell: ({ row }) => {
      return (
        <Tooltip
          bg="gray.600"
          hasArrow
          arrowSize={15}
          label={
            row.original.voucher_template &&
            row.original.voucher_template.voucher_configs &&
            row.original.voucher_template.voucher_configs.length > 0
              ? row.original.voucher_template.voucher_configs.reduce(
                  (accumulator, voucherConfig) => {
                    if (accumulator === "") {
                      return (
                        voucherConfig.Venue.name +
                        "-" +
                        voucherConfig.Show.name +
                        ": " +
                        voucherConfig.Zone.name
                      );
                    } else {
                      return `${accumulator}, ${
                        voucherConfig.Venue.name +
                        "-" +
                        voucherConfig.Show.name +
                        ": " +
                        voucherConfig.Zone.name
                      }`;
                    }
                  },
                  ""
                )
              : ""
          }
        >
          <Text
            cursor="pointer"
            maxW="100px"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {row.original.voucher_template &&
            row.original.voucher_template.voucher_configs &&
            row.original.voucher_template.voucher_configs.length > 0
              ? row.original.voucher_template.voucher_configs.reduce(
                  (accumulator, voucherConfig) => {
                    if (accumulator === "") {
                      return (
                        voucherConfig.Venue.name +
                        "-" +
                        voucherConfig.Show.name +
                        ": " +
                        voucherConfig.Zone.name
                      );
                    } else {
                      return `${accumulator}, ${
                        voucherConfig.Venue.name +
                        "-" +
                        voucherConfig.Show.name +
                        ": " +
                        voucherConfig.Zone.name
                      }`;
                    }
                  },
                  ""
                )
              : ""}
          </Text>
        </Tooltip>
      );
    },
  },
  {
    header: "Channel",
    accessorKey: "channel",
    size: 60,
    Cell: ({ row }) => {
      return <span>{row.original.nameChannel}</span>;
    },
  },
  {
    header: "Unit",
    accessorKey: "unit",
    size: 60,
    Cell: ({ row }) => {
      return <span>{row.original.Unit.name}</span>;
    },
  },
  {
    header: "Start-End Date",
    accessorKey: "date",
    Cell: ({ row }) => {
      const dateStart = moment(row.original.from_date);
      const dateEnd = moment(row.original.to_date);
      return (
        <span>
          {dateStart.format("DD/MM/YYYY")} - {dateEnd.format("DD/MM/YYYY")}
        </span>
      );
    },
    size: 100,
  },
  {
    header: "Start-End Show Date",
    accessorKey: "showdate",
    Cell: ({ row }) => {
      const dateStart = moment(row.original.from_showdate);
      const dateEnd = moment(row.original.to_showdate);
      return (
        <span>
          {dateStart.format("DD/MM/YYYY")} - {dateEnd.format("DD/MM/YYYY")}
        </span>
      );
    },
    size: 80,
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 60,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];

export const columnsDataVoucherTrackingReport = [
  {
    header: "No.",
    accessorKey: "no",
    Cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    header: "Voucher Code",
    accessorKey: "code",
  },
  {
    header: "Issued By",
    accessorKey: "created_by",
  },
  {
    header: "Issued Date",
    accessorKey: "created_at",
    Cell: ({ row }) => {
      const date = moment(row.original.issued_date);
      return <span>{date.format("DD-MMM-YYYY")}</span>;
    },
  },
  {
    header: "Voucher Type",
    accessorKey: "type_name",
  },
  {
    header: "Tool Type",
    accessorKey: "tool_type_name",
  },
  {
    header: "Issued Name",
    accessorKey: "issue_name",
  },
  {
    header: "Voucher Template",
    accessorKey: "template_name",
  },
  {
    header: "Policy Price",
    accessorKey: "price",
  },
  {
    header: "Sale Price",
    accessorKey: "sale_price",
  },
  {
    header: "Channel",
    accessorKey: "channel_name",
  },
  {
    header: "Unit",
    accessorKey: "unit_name",
  },
  {
    header: "From",
    accessorKey: "from_date",
    Cell: ({ row }) => {
      const date = moment(row.original.from_date);
      return <span>{date.format("DD-MMM-YYYY")}</span>;
    },
  },
  {
    header: "To",
    accessorKey: "to_date",
    Cell: ({ row }) => {
      const date = moment(row.original.to_date);
      return <span>{date.format("DD-MMM-YYYY")}</span>;
    },
  },
  {
    header: "Voucher Status",
    accessorKey: "status",
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <Badge
            bg={"green.400"}
            fontSize="14px"
            p="3px 10px"
            borderRadius="8px"
            color="white"
          >
            {showStatusVoucherDetail(row.original.status)}
          </Badge>
        </div>
      );
    },
  },
  {
    header: "Booking Code",
    accessorKey: "booking_code",
  },
];

export const columnsData2 = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "CUSTOMER",
    accessor: "customer",
  },
  {
    Header: "PRODUCT",
    accessor: "product",
  },
  {
    Header: "REVENUE",
    accessor: "revenue",
  },
];
export const columnsDataUnitUser = [
  {
    header: "Unit Name",
    accessorKey: "unit",
    Cell: ({ row }) => {
      return row.original.unit.name;
    },
  },
  {
    header: "Channel Name",
    accessorKey: "unit.channel",
    Cell: ({ row }) => {
      return row.original.unit.channel.name;
    },
  },
  {
    header: "Create Date",
    accessorKey: "created_at",
    Cell: ({ row }) => {
      const date = moment(row.original.created_at);
      return <span>{date.format("DD/MM/YYYY")}</span>;
    },
  },
  {
    header: "Update Date",
    accessorKey: "updated_at",
    Cell: ({ row }) => {
      const date = moment(row.original.updated_at);
      return <span>{date.format("DD/MM/YYYY")}</span>;
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 60,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataRatePlanConfigs = [
  {
    Header: "Zone",
    accessor: "zone_id",
    Cell: (row) => {
      return <span>{row.value}</span>;
    },
  },
  {
    Header: "Sale Price",
    accessor: "sale_price",
  },
  {
    Header: "Show Date From",
    accessor: "show_date_from",
    Cell: (row) => {
      const date = moment(row.value);
      return <span>{date.format("DD/MM/YYYY")}</span>;
    },
  },
  {
    Header: "Show Date To",
    accessor: "show_date_to",
    Cell: (row) => {
      const date = moment(row.value);
      return <span>{date.format("DD/MM/YYYY")}</span>;
    },
  },
  {
    Header: "Discount Rate",
    accessor: "discount_rate",
  },
  {
    Header: "Min Applied",
    accessor: "min_applied",
  },
  {
    Header: "Min Applied",
    accessor: "min_applied",
  },
  {
    Header: "Max Applied",
    accessor: "max_applied",
  },
  {
    Header: "status",
    accessor: "status",
  },
  {
    Header: "actions",
    accessor: "",
    disableSortBy: true,
  },
];
export const columnsBookingList = [
  {
    header: "Booking Code",
    accessorKey: "code",
    Cell: ({ row }) => {
      return row.depth === 0 ? (
        <Link
          to={`/admin/booking/create/detail/${row.original.id}`}
          target="_blank"
        >
          <Text minW="150px" textDecoration="underline" color={"blue.400"}>
            {row.original.code}
          </Text>
        </Link>
      ) : (
        <Text minW="150px" textAlign="center" color={"blue.400"}>
          {row.original.code}
        </Text>
      );
    },
  },
  {
    header: "Confirmation Code",
    accessorKey: "confirm_code",
  },
  {
    header: "Seat No.",
    accessorKey: "seat_no",
  },
  {
    header: "Unit",
    accessorKey: "unit_name",
    Cell: ({ row }) => {
      return <span>{row.original.unit_name}</span>;
    },
  },
  {
    header: "Channel",
    accessorKey: "channel_name",
    Cell: ({ row }) => {
      return <span>{row.original.channel_name}</span>;
    },
  },
  {
    header: "Zone",
    accessorKey: "zone_name",
    Cell: ({ row }) => {
      return <span>{row.original.zone.name}</span>;
    },
  },
  {
    header: "Amount",
    accessorKey: "total_amount",
    Cell: ({ row }) => {
      return <span>{row.original.total_amount.toLocaleString("en-US")}</span>;
    },
  },
  {
    header: "Booker info",
    accessorKey: "booker_name",
    size: 250,
    Cell: ({ row }) => {
      return (
        <div style={{ whiteSpace: "break-spaces" }}>
          <p>{row?.original?.booker_name}</p>
          <p>{row?.original?.booker_phone}</p>
          <p>{row?.original?.booker_email}</p>
          <p>{row?.original?.booker_country}</p>
        </div>
      );
    },
  },
  {
    header: "Audience info",
    accessorKey: "guest_name",
    size: 250,
    Cell: ({ row }) => {
      return (
        <div style={{ whiteSpace: "break-spaces" }}>
          <p> {row.original.guest_name}</p>
          <p>{row?.original?.guest_phone}</p>
          <p> {row.original.guest_email}</p>
          <p> {row.original.guest_country}</p>
        </div>
      );
    },
  },
  {
    header: "Quantity",
    accessorKey: "adult",
    Cell: ({ row }) => {
      return <span>{row.original.adult + row.original.child}</span>;
    },
  },
  {
    header: "Special Request",
    accessorKey: "notes",
    size: 320,
    Cell: ({ row }) => {
      return (
        <Text
          whiteSpace={"break-spaces"}
          dangerouslySetInnerHTML={{ __html: row.original.notes }}
        ></Text>
      );
    },
  },
  {
    header: "Created By",
    accessorKey: "created_by",
  },
  {
    header: "Payment Type",
    accessorKey: "payment_type_name",
    Cell: ({ row }) => {
      return <span>{row.original.payment_type_name}</span>;
    },
  },
  {
    header: "Booking Status",
    accessorKey: "status_name",
    Cell: ({ row }) => {
      return <span>{row.original.status_name}</span>;
    },
  },
];
export const columnsDataBookingDetailCode = [
  {
    header: "Status",
    accessorKey: "status",
    size: 40,
    Cell: ({ row }) => {
      return (
        <svg
          width="32px"
          height="32px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              opacity="0.5"
              d="M14.005 4H9.99502C6.21439 4 4.32407 4 3.14958 5.17157C2.34091 5.97823 2.08903 7.12339 2.01058 8.98947C1.99502 9.35954 1.98724 9.54458 2.05634 9.66802C2.12545 9.79147 2.40133 9.94554 2.95308 10.2537C3.56586 10.5959 3.98007 11.2497 3.98007 12C3.98007 12.7503 3.56586 13.4041 2.95308 13.7463C2.40133 14.0545 2.12545 14.2085 2.05634 14.332C1.98724 14.4554 1.99502 14.6405 2.01058 15.0105C2.08903 16.8766 2.34091 18.0218 3.14958 18.8284C4.32407 20 6.21438 20 9.99502 20H14.005C17.7856 20 19.6759 20 20.8504 18.8284C21.6591 18.0218 21.911 16.8766 21.9894 15.0105C22.005 14.6405 22.0128 14.4554 21.9437 14.332C21.8745 14.2085 21.5987 14.0545 21.0469 13.7463C20.4341 13.4041 20.0199 12.7503 20.0199 12C20.0199 11.2497 20.4341 10.5959 21.0469 10.2537C21.5987 9.94554 21.8745 9.79147 21.9437 9.66803C22.0128 9.54458 22.005 9.35954 21.9894 8.98947C21.911 7.12339 21.6591 5.97823 20.8504 5.17157C19.6759 4 17.7856 4 14.005 4Z"
              fill={
                row.original.status === 1
                  ? "#FBED21"
                  : row.original.status === 2
                  ? "#FF0000"
                  : "#1C274C"
              }
            ></path>
            <path
              d="M15.5475 8.46967C15.8411 8.76256 15.8411 9.23744 15.5475 9.53033L9.53254 15.5303C9.23892 15.8232 8.76286 15.8232 8.46924 15.5303C8.17562 15.2374 8.17562 14.7626 8.46924 14.4697L14.4842 8.46967C14.7778 8.17678 15.2539 8.17678 15.5475 8.46967Z"
              fill="#1C274C"
            ></path>
            <path
              d="M14.5146 15.5C15.0683 15.5 15.5171 15.0523 15.5171 14.5C15.5171 13.9477 15.0683 13.5 14.5146 13.5C13.9609 13.5 13.5121 13.9477 13.5121 14.5C13.5121 15.0523 13.9609 15.5 14.5146 15.5Z"
              fill="#1C274C"
            ></path>
            <path
              d="M9.50214 10.5C10.0558 10.5 10.5046 10.0523 10.5046 9.5C10.5046 8.94772 10.0558 8.5 9.50214 8.5C8.94848 8.5 8.49965 8.94772 8.49965 9.5C8.49965 10.0523 8.94848 10.5 9.50214 10.5Z"
              fill="#1C274C"
            ></path>
          </g>
        </svg>
      );
    },
  },
  {
    header: "Rate Plan",
    accessorKey: "rateplan_config",
    Cell: ({ row }) => {
      return (
        <span>
          {row.original.voucher_code_id > 0
            ? row.original.voucher_code.voucher.name
            : row.original.rateplan_config.rateplan.program_applied}
        </span>
      );
    },
  },
  {
    header: "Seat",
    accessorKey: "seat",
  },
  {
    header: "Confirmation Code",
    accessorKey: "code",
  },
  {
    header: "Official Price",
    accessorKey: "official_price",
    Cell: ({ row }) => {
      return <span>{row.original.official_price.toLocaleString("en-US")}</span>;
    },
  },
  {
    header: "Amount",
    accessorKey: "amount",
    Cell: ({ row }) => {
      return <span>{row.original.amount.toLocaleString("en-US")}</span>;
    },
  },
  {
    header: "Printed Status",
    accessorKey: "status_name",
  },
];
export const columnsDataToolType = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
  },
  {
    header: "Prefix",
    accessorKey: "prefix",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataShowTime = [
  {
    header: "Times",
    accessorKey: "name",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataPaymentMethod = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataCurrency = [
  {
    header: "Name",
    accessorKey: "key",
  },
  {
    header: "Amount",
    Cell: ({ row }) => {
      return (
        <Flex minW={"120px"}>
          {parseInt(row.original.value).toLocaleString("en-US")}
        </Flex>
      );
    },
  },
];
export const columnsDataPaymentStatus = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataTimeToRelease = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Hours",
    accessorKey: "code",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataSubAccount = [
  {
    header: "Full name",
    accessorKey: "fullname",
  },
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Phone number",
    accessorKey: "phone",
  },
  // {
  //   header: "Channel",
  //   accessorKey: "channel",
  //   Cell: ({ row }) => {
  //     return <span>{row.original.channel.name}</span>;
  //   },
  // },
  // {
  //   header: "Unit",
  //   accessorKey: "unit",
  //   Cell: ({ row }) => {
  //     return <span>{row.original.unit.name}</span>;
  //   },
  // },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataSeatingPlan = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Show Name",
    accessorKey: "show",
    Cell: ({ row }) => {
      return <span>{row.original.show.name}</span>;
    },
  },
  {
    header: "Venue Name",
    accessorKey: "venue",
    Cell: ({ row }) => {
      return <span>{row.original.venue.name}</span>;
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataAssignSeatingPlan = [
  {
    header: "Seat Number",
    accessorKey: "seat_number",
  },
  {
    header: "Floor",
    accessorKey: "floor",
    Cell: ({ row }) => {
      return <span>{showTextFloor(row.original.floor)}</span>;
    },
  },
  {
    header: "Unit",
    accessorKey: "unit",
    Cell: ({ row }) => {
      return (
        <Flex minW={"120px"} direction="column">
          {row.original.unit.map((i, index) => (
            <span key={i.id}>
              {i.name} {row.original.unit.length < index && ","}
            </span>
          ))}
        </Flex>
      );
    },
  },
];
export const columnsDataAssignSeatingPlanShowSchedule = [
  {
    header: "Seat Number",
    accessorKey: "seat_number",
  },
  {
    header: "Floor",
    accessorKey: "floor",
    Cell: ({ row }) => {
      return <span>{showTextFloor(row.original.floor)}</span>;
    },
  },
  {
    header: "Unit",
    accessorKey: "unit_name",
    Cell: ({ row }) => {
      return (
        <Flex key={row.original.id} minW={"120px"} direction="column">
          {row.original.unit.map((i, index) => (
            <span key={i.id}>
              {i.name} {row.original.unit.length < index && ","}
            </span>
          ))}
        </Flex>
      );
    },
  },
];
export const columnsDataShowSchedule = [
  {
    header: "Show Name",
    accessorKey: "show",
    Cell: ({ row }) => {
      return <span>{row.original.show.name}</span>;
    },
  },
  {
    header: "Venue Name",
    accessorKey: "venue",
    Cell: ({ row }) => {
      return <span>{row.original.venue.name}</span>;
    },
  },
  {
    header: "Seating Plan",
    accessorKey: "seatingplan",
    Cell: ({ row }) => {
      return <span>{row.original.seatingplan.name}</span>;
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataShowScheduleDetail = [
  {
    header: "Show Date",
    accessorKey: "date",
    Cell: ({ row }) => {
      return <span>{moment(row.original.date).format("DD/MM/YYYY")}</span>;
    },
  },
  {
    header: "Show Time",
    accessorKey: "time",
    enableSorting: false,
    Cell: ({ row }) => {
      return <span>{row.original.time.slice(0, -3)}</span>;
    },
  },
  {
    header: "Seating Plan",
    accessorKey: "show_schedule",
    Cell: ({ row }) => {
      return <span>{row.original.show_schedule.seatingplan.name}</span>;
    },
  },
  {
    header: "Note",
    accessorKey: "note",
  },
  {
    header: "Status",
    accessorKey: "status",
    Cell: ({ row }) => {
      return (
        <span>
          {row.original.status === 1 ? (
            <span style={{ color: "green" }}>Active</span>
          ) : row.original.status === 2 ? (
            <span style={{ color: "red" }}>InActive</span>
          ) : (
            <span style={{ color: "grey" }}>Cancel</span>
          )}
        </span>
      );
    },
  },
];
export const columnsDataShowScheduleDateTime = [
  {
    header: "Date",
    accessorKey: "date",
  },
  {
    header: "Times",
    accessorKey: "times",
  },
];
export const columnsInvoiceList = [
  {
    header: "Booking Code",
    accessorKey: "code",
    Cell: ({ row }) => {
      return row.depth === 0 ? (
        <Link
          to={`/admin/booking/create/detail/${row.original.id}`}
          target="_blank"
        >
          <Text minW="150px" textDecoration="underline" color={"blue.400"}>
            {row.original.code}
          </Text>
        </Link>
      ) : (
        <Text minW="150px" textAlign="center" color={"blue.400"}>
          {row.original.code}
        </Text>
      );
    },
  },
  {
    header: "E-invoice",
    accessorKey: "invoice_response",
    Cell: ({ row }) => {
      return <span>{row.original.invoice_response}</span>;
    },
  },
  {
    header: "Invoice Type",
    accessorKey: "invoice_type",
    Cell: ({ row }) => {
      return (
        <span>
          {row.original.invoice_type === 1
            ? "Invoice with receipt"
            : "Invoice without receipt"}
        </span>
      );
    },
  },
  {
    header: "Unit",
    accessorKey: "unit",
    Cell: ({ row }) => {
      return <span>{row.original.unit.name}</span>;
    },
  },
  {
    header: "Channel",
    accessorKey: "channel_name",
    size: 100,
    Cell: ({ row }) => {
      return <span>{row.original.unit.channel.name}</span>;
    },
  },
  {
    header: "Zone",
    accessorKey: "zone",
    Cell: ({ row }) => {
      return <span>{row.original.zone.name}</span>;
    },
  },
  {
    header: "Amount",
    accessorKey: "total_amount",
    Cell: ({ row }) => {
      return <span>{row.original.total_amount.toLocaleString("en-US")}</span>;
    },
  },
  {
    header: "Booker info",
    accessorKey: "booker_name",
    size: 250,
    Cell: ({ row }) => {
      return (
        <div style={{ whiteSpace: "break-spaces" }}>
          <p>{row?.original?.booker_name}</p>
          <p>{row?.original?.booker_phone}</p>
          <p>{row?.original?.booker_email}</p>
          <p>{row?.original?.booker_country}</p>
        </div>
      );
    },
  },
  {
    header: "Audience info",
    accessorKey: "guest_name",
    size: 250,
    Cell: ({ row }) => {
      return (
        <div style={{ whiteSpace: "break-spaces" }}>
          <p> {row.original.guest_name}</p>
          <p>{row?.original?.guest_phone}</p>
          <p> {row.original.guest_email}</p>
          <p> {row.original.guest_country}</p>
        </div>
      );
    },
  },
  {
    header: "Quantity",
    accessorKey: "adult",
    Cell: ({ row }) => {
      return <span>{row.original.adult + row.original.child}</span>;
    },
  },
  {
    header: "Special Request",
    accessorKey: "notes",
    size: 320,
    Cell: ({ row }) => {
      return (
        <Text
          whiteSpace={"break-spaces"}
          dangerouslySetInnerHTML={{ __html: row.original.notes }}
        ></Text>
      );
    },
  },
  {
    header: "Created By",
    accessorKey: "created_by",
  },
  {
    header: "Payment Type",
    accessorKey: "payment_type_name",
    Cell: ({ row }) => {
      return <span>{row.original.payment_type_name}</span>;
    },
  },
  {
    header: "Booking Status",
    accessorKey: "status_name",
    Cell: ({ row }) => {
      return <span>{row.original.status_name}</span>;
    },
  },
];

export const columnsDataTicketPrice = [
  {
    header: "Venue",
    accessorKey: "venue",
    Cell: ({ row }) => {
      return <span>{row.original.venue.name}</span>;
    },
  },
  {
    header: "Show Name",
    accessorKey: "show",
    Cell: ({ row }) => {
      return <span>{row.original.show.name}</span>;
    },
  },
  {
    header: "Zone",
    accessorKey: "zone",
    Cell: ({ row }) => {
      return <span>{row.original.zone.name}</span>;
    },
  },
  {
    header: "Price",
    accessorKey: "official_price",
    Cell: ({ row }) => {
      return <span>{row.original.official_price.toLocaleString("en-US")}</span>;
    },
  },
];

export const columnsDataVoucherTemplate = [
  {
    header: "Name",
    accessorKey: "name",
    size: 40,
  },

  {
    header: "Zone",
    accessorKey: "voucher_configs",
    enableSorting: false,
    size: 120,
    Cell: ({ row }) => {
      return (
        <Text>
          {row.original.voucher_configs
            ? row.original.voucher_configs.reduce(
                (accumulator, voucherConfig) => {
                  if (accumulator === "") {
                    return `
                    ${voucherConfig.Show.name} - ${
                      voucherConfig.Venue.name
                    } - ${
                      voucherConfig.Zone.name
                    }: ${voucherConfig.amount.toLocaleString("en-US")} VND
                    `;
                  } else {
                    return (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p>{accumulator}</p>
                        <p>
                          {voucherConfig.Show.name} - {voucherConfig.Venue.name}{" "}
                          -{voucherConfig.Zone.name}:{" "}
                          {voucherConfig.amount.toLocaleString("en-US")} VND
                        </p>
                      </div>
                    );
                  }
                },
                ""
              )
            : ""}
        </Text>
      );
    },
  },
  {
    header: "Sale Price",
    accessorKey: "amount",

    Cell: ({ row }) => {
      return <span>{row.original.amount.toLocaleString("en-US")} VND</span>;
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 60,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];

export const columnsDataNews = [
  {
    header: "Title",
    accessorKey: "title",
    size: 60,
  },
  {
    header: "Slug",
    accessorKey: "slug",
    size: 60,
  },
  {
    header: "Thumbnail",
    accessorKey: "thumbnail_url",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return (
        <img
          src={row.original.thumbnail_url}
          width={"150px"}
          height={"100px"}
        />
      );
    },
  },
  {
    header: "Image",
    accessorKey: "image_url",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return (
        <img src={row.original.image_url} width={"150px"} height={"100px"} />
      );
    },
  },
];

export const columnsDataNewsGroup = [
  {
    header: "Name",
    accessorKey: "name",
    size: 60,
  },
  {
    header: "Description",
    accessorKey: "description",
    Cell: ({ row }) => {
      return (
        <Text
          cursor="pointer"
          maxW="100px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          dangerouslySetInnerHTML={{ __html: row.original.description }}
        ></Text>
      );
    },
    size: 60,
  },
  {
    header: "Slug",
    accessorKey: "slug",
    size: 60,
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 60,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];

export const columnsDataPartner = [
  {
    header: "Name",
    accessorKey: "name",
    size: 60,
  },
  {
    header: "Logo",
    accessorKey: "logo",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return <img src={row.original.logo} width={"150px"} height={"100px"} />;
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 60,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataVenueWeb = [
  {
    header: "Name",
    accessorKey: "name",
    size: 60,
  },
  {
    header: "Address",
    accessorKey: "address",
    size: 60,
  },
  {
    header: "Slug",
    accessorKey: "slug",
    size: 60,
  },
  {
    header: "Code",
    accessorKey: "code",
    size: 60,
  },
  {
    header: "Short Name",
    accessorKey: "short_name",
    size: 60,
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 60,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataLocationWeb = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];

export const columnsDataShowWeb = [
  {
    header: "Name",
    accessorKey: "name",
    size: 60,
  },
  {
    header: "Slug",
    accessorKey: "slug",
    size: 60,
  },
  {
    header: "Thumbnail",
    accessorKey: "thumbnail",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return (
        <img src={row.original.thumbnail} width={"150px"} height={"100px"} />
      );
    },
  },
  {
    header: "Logo",
    accessorKey: "logo",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return <img src={row.original.logo} width={"150px"} height={"100px"} />;
    },
  },

  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataOffer = [
  {
    header: "Name",
    accessorKey: "name",
    size: 60,
  },
  {
    header: "Slug",
    accessorKey: "slug",
    size: 60,
  },
  {
    header: "Thumbnail",
    accessorKey: "thumbnail_url",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return (
        <img
          src={row.original.thumbnail_url}
          width={"150px"}
          height={"100px"}
        />
      );
    },
  },
  {
    header: "Start Date",
    accessorKey: "start_date",
    Cell: ({ row }) => {
      return (
        <span>{moment(row.original.start_date).format("DD/MM/YYYY")}</span>
      );
    },
  },
  {
    header: "End Date",
    accessorKey: "end_date",
    Cell: ({ row }) => {
      return <span>{moment(row.original.end_date).format("DD/MM/YYYY")}</span>;
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataOffersGroup = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];

export const columnsDataMenu = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Slug",
    accessorKey: "slug",
    size: 60,
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Position",
    accessorKey: "position",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];

export const columnsDataRecruitment = [
  {
    header: "Position",
    accessorKey: "position",
    size: 60,
  },
  {
    header: "Description",
    accessorKey: "description",
    size: 60,
  },
  {
    header: "Responsibilities",
    accessorKey: "responsibilities",
    size: 60,
  },
  {
    header: "Requirements",
    accessorKey: "requirements",
    size: 60,
  },
  {
    header: "Posting Date",
    accessorKey: "posting_date",
    Cell: ({ row }) => {
      return (
        <span>{moment(row.original.posting_date).format("DD/MM/YYYY")}</span>
      );
    },
  },
  {
    header: "Closing Date",
    accessorKey: "closing_date",
    Cell: ({ row }) => {
      return (
        <span>{moment(row.original.closing_date).format("DD/MM/YYYY")}</span>
      );
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];

export const columnsDataFaq = [
  {
    header: "Question",
    accessorKey: "question",
    size: 320,
    Cell: ({ row }) => {
      return (
        <Text
          whiteSpace={"break-spaces"}
          dangerouslySetInnerHTML={{ __html: row.original.question }}
        ></Text>
      );
    },
  },
  {
    header: "Answer",
    accessorKey: "answer",
    size: 320,
    Cell: ({ row }) => {
      return (
        <Text
          whiteSpace={"break-spaces"}
          dangerouslySetInnerHTML={{ __html: row.original.answer }}
        ></Text>
      );
    },
  },

  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];

export const columnsDataFaqGroup = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];

export const columnsDataBanner = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Key",
    accessorKey: "key",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} isCMS />
        </div>
      );
    },
  },
];
export const columnsDataBannerImages = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Url",
    accessorKey: "url",
  },
  {
    header: "Image",
    accessorKey: "image_url",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return (
        <img src={row.original.image_url} width={"150px"} height={"100px"} />
      );
    },
  },
  {
    header: "Thumbnail Image",
    accessorKey: "thumbnail_image_url",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return (
        <img
          src={row.original.thumbnail_image_url}
          width={"150px"}
          height={"100px"}
        />
      );
    },
  },
  {
    header: "Medium image",
    accessorKey: "medium_image_url",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return (
        <img
          src={row.original.medium_image_url}
          width={"150px"}
          height={"100px"}
        />
      );
    },
  },
];

export const columnsDataShowKeyFeature = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
    Cell: ({ row }) => {
      return (
        <Tooltip
          bg="gray.600"
          hasArrow
          arrowSize={15}
          label={
            <div
              dangerouslySetInnerHTML={{ __html: row.original.description }}
            />
          }
        >
          <Text
            maxW={"200px"}
            className="long-paragraph"
            dangerouslySetInnerHTML={{ __html: row.original.description }}
          ></Text>
        </Tooltip>
      );
    },
    size: 60,
  },
  {
    header: "Thumbnail",
    accessorKey: "thumbnail",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return (
        <img src={row.original.thumbnail} width={"150px"} height={"100px"} />
      );
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];

export const columnsDataShowReview = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Type",
    accessorKey: "type",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      return <div>{showReviewType(row.original.type)}</div>;
    },
  },
  {
    header: "Rating",
    accessorKey: "rating",
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => {
      return (
        <div style={{ textAlign: "center" }}>
          <ConfirmSwitchMessage row={row} />
        </div>
      );
    },
  },
];
export const columnsDataTarget = [
  {
    header: "Show Name",
    accessorKey: "show",
    Cell: ({ row }) => {
      return <span>{row.original.show.name}</span>;
    },
  },
  {
    header: "Channel Name",
    accessorKey: "channel",
    Cell: ({ row }) => {
      return <span>{row.original.channel.name}</span>;
    },
  },
  {
    header: "Location Name",
    accessorKey: "location_id",
    Cell: ({ row }) => {
      return <span>{row.original.location.name}</span>;
    },
  },
  {
    header: "Target Net",
    accessorKey: "target_net",
    Cell: ({ row }) => {
      return <span>{row.original.target_net.toLocaleString("en-US")} VND</span>;
    },
  },
  {
    header: "Target Ticket",
    accessorKey: "target_ticket",
    Cell: ({ row }) => {
      return (
        <span>{row.original.target_ticket.toLocaleString("en-US")} VND</span>
      );
    },
  },
  {
    header: "Target Value",
    accessorKey: "target_value",
    Cell: ({ row }) => {
      return (
        <span>{row.original.target_value.toLocaleString("en-US")} VND</span>
      );
    },
    size: 40,
  },
  {
    header: "Year",
    accessorKey: "year",
    size: 60,
  },
  {
    header: "Month",
    accessorKey: "month",
    size: 40,
  },
];

export const columDataCommission = [
  {
    header: "No.",
    accessorKey: "no",
    Cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    header: "Show Name",
    accessorKey: "show_name",
    size: 40,
  },
  {
    header: "Venue",
    accessorKey: "venue_name",
    size: 40,
  },
  {
    header: "Show Date",
    accessorKey: "show_date",
    size: 40,
    Cell: ({ row }) => {
      return <span>{moment(row.original.show_date).format("DD/MM/YYYY")}</span>;
    },
  },
  {
    header: "Show Time",
    accessorKey: "show_time",
    size: 40,
  },
  {
    header: "Booked Date",
    accessorKey: "booked_date",
    size: 40,
    Cell: ({ row }) => {
      return (
        <span>{moment(row.original.booked_date).format("DD/MM/YYYY")}</span>
      );
    },
  },
  {
    header: "Paid Date",
    accessorKey: "paid_date",
    size: 40,
    Cell: ({ row }) => {
      return <span>{moment(row.original.paid_date).format("DD/MM/YYYY")}</span>;
    },
  },
  {
    header: "Channel",
    accessorKey: "channel_name",
    size: 40,
  },
  {
    header: "Unit",
    accessorKey: "unit_name",
    size: 40,
  },
  {
    header: "Booker Name",
    accessorKey: "booker_name",
    size: 40,
  },
  {
    header: "Booking No.",
    accessorKey: "code",
    size: 40,
  },
  {
    header: "Guest notes/ Special Notes",
    accessorKey: "notes",
    size: 60,
    Cell: ({ row }) => {
      return (
        <Text
          whiteSpace={"break-spaces"}
          dangerouslySetInnerHTML={{ __html: row.original.notes }}
        ></Text>
      );
    },
  },
  {
    header: "Rate Plan / Voucher Template",
    accessorKey: "unknow",
    size: 60,
  },
  {
    header: "Quantity",
    accessorKey: "quantity",
    size: 40,
  },
  {
    header: "Zone",
    accessorKey: "zone_name",
    size: 40,
  },
  {
    header: "Amount",
    accessorKey: "amount",
    size: 40,
    Cell: ({ row }) => {
      return <span>{row.original.amount.toLocaleString("en-US")} VND</span>;
    },
  },
  {
    header: "Extra Cost",
    accessorKey: "extra_cost",
    size: 40,
  },
  {
    header: "Commissions 1",
    accessorKey: "commissions1",
    size: 40,
    Cell: ({ row }) => {
      return <span>{row.original.commissions1.toLocaleString("en-US")}</span>;
    },
  },
  {
    header: "Commissions 2",
    accessorKey: "commissions2",
    size: 40,
    Cell: ({ row }) => {
      return <span>{row.original.commissions2.toLocaleString("en-US")}</span>;
    },
  },
  {
    header: "Booking Status",
    accessorKey: "status",
    size: 40,
  },
];

export const columnsDataCheckPaymentReport = [
  {
    header: "Check/Uncheck all",
    accessorKey: "check",
    Cell: ({ row }) => <input type="checkbox" />,
  },
  {
    header: "No.",
    accessorKey: "id",
    Cell: ({ row }) => <span>{row.index + 1}</span>,
  },
  {
    header: "Booking No.",
    accessorKey: "code",
    Cell: ({ row }) => (
      <Link
        to={`/admin/booking/create/detail/${row.original.id}`}
        target="_blank"
      >
        <Text minW="150px" textDecoration="underline" color={"blue.400"}>
          {row.original.code}
        </Text>
      </Link>
    ),
  },
  {
    header: "Show Name",
    accessorKey: "show_name",
    Cell: ({ row }) => <span>{row.original.show_name || "N/A"}</span>,
  },
  {
    header: "Venue",
    accessorKey: "venue",
    Cell: ({ row }) => <span>{row.original.venue || "N/A"}</span>,
  },
  {
    header: "Show Date",
    accessorKey: "show_date",
    Cell: ({ row }) => <span>{row.original.show_date}</span>,
  },
  {
    header: "Show Time",
    accessorKey: "show_time",
    Cell: ({ row }) => <span>{row.original.show_time || "N/A"}</span>,
  },
  {
    header: "Created At (Booked Date)",
    accessorKey: "created_at",
    Cell: ({ row }) => (
      <span>{new Date(row.original.created_at).toLocaleString()}</span>
    ),
  },
  {
    header: "Paid Date",
    accessorKey: "paid_date",
    Cell: ({ row }) => <span>{row.original.paid_date || "N/A"}</span>,
  },
  {
    header: "Channel",
    accessorKey: "channel_name",
    Cell: ({ row }) => <span>{row.original.channel_name}</span>,
  },
  {
    header: "Unit",
    accessorKey: "unit_name",
    Cell: ({ row }) => <span>{row.original.unit_name}</span>,
  },
  {
    header: "Booker Name (Sub Account)",
    accessorKey: "booker_name",
    Cell: ({ row }) => <span>{row.original.booker_name || "N/A"}</span>,
  },
  {
    header: "Guest notes / Special Notes",
    accessorKey: "notes",
    Cell: ({ row }) => (
      <Text
        whiteSpace={"break-spaces"}
        dangerouslySetInnerHTML={{ __html: row.original.notes }}
      ></Text>
    ),
  },
  {
    header: "Booking Status",
    accessorKey: "status_name",
    Cell: ({ row }) => <span>{row.original.status_name || "N/A"}</span>,
  },
  {
    header: "Payment Status",
    accessorKey: "payment_status",
    Cell: ({ row }) => <span>{row.original.payment_status || "N/A"}</span>,
  },
  {
    header: "Payment Method",
    accessorKey: "payment_type_name",
    Cell: ({ row }) => <span>{row.original.payment_type_name || "N/A"}</span>,
  },
  {
    header: "Created By (Check Paid by)",
    accessorKey: "created_by",
    Cell: ({ row }) => <span>{row.original.created_by}</span>,
  },
  {
    header: "Amount",
    accessorKey: "amount",
    Cell: ({ row }) => (
      <span>{row.original.amount.toLocaleString("en-US")}</span>
    ),
  },
  {
    header: "Payment Received Status",
    accessorKey: "payment_received",
    Cell: ({ row }) => (
      <span>{row.original.payment_received ? "Received" : "Not Received"}</span>
    ),
  },
  {
    header: "Payment Verified Amount",
    accessorKey: "payment_verified_amount",
    Cell: ({ row }) => <span>{row.original.payment_verified_amount || 0}</span>,
  },
  {
    header: "Payment Verified By",
    accessorKey: "payment_verified_by",
    Cell: ({ row }) => <span>{row.original.payment_verified_by || "N/A"}</span>,
  },
  {
    header: "Payment Verified Date",
    accessorKey: "payment_verified_date",
    Cell: ({ row }) => (
      <span>{row.original.payment_verified_date || "N/A"}</span>
    ),
  },
];

export const columnsDataScanTheaterPass = [
  {
    header: "No.",
    accessorKey: "no",
    Cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  { header: "Show Name", accessorKey: "show_name" },
  {
    header: "Show Date",
    accessorKey: "show_date",
    Cell: ({ row }) => {
      return <span>{row.original.showdate || "N/A"}</span>;
    },
  },
  { header: "Show Time", accessorKey: "showtime" },
  { header: "Zone", accessorKey: "zone_name" },
  { header: "Booking Code", accessorKey: "booking_code" },
  { header: "Confirmation Code", accessorKey: "code" },
  { header: "Seat Number", accessorKey: "seat" },
  {
    header: "Scanned At",
    accessorKey: "scanned_at",
    Cell: ({ row }) => {
      return <span>{row.original.scanned_at || "N/A"}</span>;
    },
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  { header: "Booker Name", accessorKey: "booker_name" },
  { header: "Guest Name", accessorKey: "guest_name" },
];