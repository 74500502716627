/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
  colors: {
    gray: {
      300: '#1f2733',
      400: '#1f2733',
      700: '#1f2733',
      800: '#171923',
    },
    blue: {
      300: '#004aad',
      400: '#002d57',
      500: '#002d57',
      600: '#002d57',
    },
    navy: {
      50: '#d0dcfb',
      100: '#aac0fe',
      200: '#a3b9f8',
      300: '#728fea',
      400: '#3652ba',
      500: '#1b3bbb',
      600: '#24388a',
      600: '#24388a',
      700: '#1b254b',
      800: '#111c44',
      900: '#0b1437',
    },
  },
  fontSizes: {
    base: '0.75rem',
    xs: '0.75rem',
    sm: '0.75rem',
    md: '0.75rem',
    lg: '1.125rem',
    xl: '1.125rem',
    '2xl': '1.125rem',
    '3xl': '1.5rem',
    '4xl': '1.875rem',
    '5xl': '2.25rem',
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: 'hidden',
        bg: 'white',
        fontFamily: 'Helvetica, sans-serif',
        fontSize: 'var(--chakra-fontSizes-base)',
      },
      html: {
        fontFamily: 'Helvetica, sans-serif',
      },
      input: {
        '@media (max-width: 768px)': {
          fontSize: '16px !important',
        },
      },
      select: {
        '@media (max-width: 768px)': {
          fontSize: '16px !important',
        },
      },
      textarea: {
        '@media (max-width: 768px)': {
          fontSize: '16px !important',
        },
      },
      '.chakra-select__input [id*="react-select-"]': {
        '@media (max-width: 768px)': {
          fontSize: '16px !important',
        },
      },
      '.mantine-Select-root input.mantine-Select-input': {
        '@media (max-width: 768px)': {
          width: '95px',
        },
      },
      '.mantine-Text-root': {
        '@media (max-width: 768px)': {
          paddingTop: '24px;',
        },
      },
      '.mantine-Text-root + *': {
        '@media (max-width: 768px)': {
          paddingTop: '24px;',
        },
      },
      '.mantine-Paper-root': {
        '@media (max-width: 768px)': {
          '& > div:first-child': {
            minHeight: '24px',
          },
        },
      },
      'span.chakra-switch__track': {
        background: '#a1a1aa',
      },
    }),
  },
};
