import {
  Button,
  Flex,
  Input,
  Stack,
  useColorModeValue,
  Text,
  Grid,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  HStack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  ModalContent,
  useDisclosure,
  useToast,
  Select,
  Tooltip,
  Radio,
  RadioGroup,
  Image,
  FormErrorMessage,
  Icon,
  Box,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { MdOutlineCancel, MdPublish, MdHistory } from 'react-icons/md';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { FaRegClone } from 'react-icons/fa';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import QRCode from 'qrcode.react';
import CardHeader from 'components/Card/CardHeader';
import { HSeparator } from 'components/Separator/Separator';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { showTextFloor } from 'variables/columnsData';
import { useBookingActions } from 'hooks/useBookingActions';
import { useHistory, useParams } from 'react-router-dom';
import { IoAdd, IoMail, IoPrintOutline } from 'react-icons/all';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Editor from 'components/Editor/Editor';
import { checkForbidden } from 'helpers/checkForbidden';
import { NumericFormat } from 'react-number-format';
import cancelLogo from '../../../assets/img/cancel.png';
import { Select as ReactSelect } from 'chakra-react-select';
import { ShowAllIcon } from 'components/Icons/Icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedShowAtom, paymentMethodListAtom } from '../../../state/show';
import ReactDatePicker from 'react-datepicker';
import { setHours, setMinutes } from 'date-fns';

export default function BookingDetail() {
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'white');
  const { booking_id } = useParams();
  const [bookingDetail, setBookingDetail] = useState();
  const [bookerandguestInfo, setBookerandguestInfo] = useState({});
  const [editBooker, setEditBooker] = useState(false);
  const [isCreateSpecialRequest, setIsCreateSpecialRequest] = useState(true);
  const [isCreatePayment, setIsCreatePayment] = useState(true);
  const [editGuest, setEditGuest] = useState(false);
  const [actionReloadPage, setActionReloadPage] = useState();
  const bookingActions = useBookingActions();
  const listPaymentMethod = useRecoilValue(paymentMethodListAtom);
  const [listHistoryBooking, setListHistoryBooking] = useState([]);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isShowPayment, setIsShowPayment] = useState(false);
  const [isDisplayExtend, setIsDisplayExtend] = useState(false);
  const [amountPayment, setAmountPayment] = useState();
  const [remainingAmount, setRemainingAmount] = useState();
  const [countries, setCountries] = useState([]);
  const [itemsBookingPayment, setItemsBookingPayment] = useState([]);
  const [showAll, setShowAll] = useState(true);
  const [selectedShow, setSelectedShow] = useRecoilState(selectedShowAtom);
  const [itemsBookingPaymentRefund, setItemsBookingPaymentRefund] = useState(
    []
  );
  const [showAllRefund, setShowAllRefund] = useState(true);
  const [isDisplayCloneIcon, setDisplayCloneIcon] = useState();
  const BookingStatusCancel = 2;
  const PaymentTypePaid = 1;
  const PaymentTypeLiability = 2;
  const PaymentTypeUnpaid = 3;
  const FEStatusCancelWith = '1';
  const FEStatusCancelWithout = '2';
  const history = useHistory();
  const [listExtendTimes, setListExtendTimes] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };
  const [modeViewETicket, setViewETicket] = useState('');
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: '20px',
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '280px',
    }),
  };
  const {
    isOpen: isOpenAddExtendBookingModal,
    onOpen: onOpenAddExtendBookingModal,
    onClose: onCloseAddExtendBookingModal,
  } = useDisclosure();
  const {
    isOpen: isOpenAddPaymentModal,
    onOpen: onOpenAddPaymentModal,
    onClose: onCloseAddPaymentModal,
  } = useDisclosure();
  const {
    isOpen: isOpenViewHistoryModal,
    onOpen: onOpenViewHistoryModal,
    onClose: onCloseViewHistoryModal,
  } = useDisclosure();
  const {
    isOpen: isOpenCancelBookingModal,
    onOpen: onOpenCancelBookingModal,
    onClose: onCloseCancelBookingModal,
  } = useDisclosure();
  const {
    isOpen: isOpenPublishInvoiceModal,
    onOpen: onOpenPublishInvoiceModal,
    onClose: onClosePublishInvoiceModal,
  } = useDisclosure();
  const {
    isOpen: isOpenSendETicketModal,
    onOpen: onOpenSendETicketModal,
    onClose: onCloseSendETicketModal,
  } = useDisclosure();
  const [paymentMethod, setPaymentMethod] = useState([]);
  const validationSchema = Yup.object().shape({
    note: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    setError,
    formState,
    reset,
    control,
    watch,
    setValue,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const validationSchemaAddPayment = Yup.object().shape({
    paymentMethod: Yup.string().required('This field is required'),
    amount: Yup.string().required('This field is required'),
  });
  const formAddPaymentOptions = {
    resolver: yupResolver(validationSchemaAddPayment),
  };
  const {
    register: registerAddPayment,
    handleSubmit: handleSubmitAddPayment,
    formState: { isSubmitting: isSubmittingAddPayment },
    reset: resetAddPayment,
    control: controlPayment,
    setValue: setValuePayment,
  } = useForm(formAddPaymentOptions);

  const validationSchemaAddExtendBooking = Yup.object().shape({
    validity: Yup.string().required('This field is required'),
  });
  const validationSchemaSendETicket = Yup.object().shape({
    email: Yup.string().required('This field is required').email(),
  });
  const formAddExtendBookingOptions = {
    resolver: yupResolver(validationSchemaAddExtendBooking),
  };
  const formETicket = {
    resolver: yupResolver(validationSchemaSendETicket),
  };
  const {
    register: registerAddExtendBooking,
    handleSubmit: handleSubmitAddExtendBooking,
    formState: {
      isSubmitting: isSubmittingExtendBooking,
      errors: errorsExtendBooking,
    },
    watch: watchAddExtendBooking,
    reset: resetAddExtendBooking,
    setError: setErrorAddExtendBooking,
    control: controlAddExtendBooking,
    setValue: setValueAddExtendBooking,
  } = useForm(formAddExtendBookingOptions);
  const validateTime = watchAddExtendBooking('validity');
  const validationSchemaCancelBooking = Yup.object().shape({
    noteCancel: Yup.string().required('This field is required'),
  });
  const formCancelBookingOptions = {
    resolver: yupResolver(validationSchemaCancelBooking),
  };
  const {
    register: registerCancelBooking,
    handleSubmit: handleSubmitCancelBooking,
    formState: {
      isSubmitting: isSubmittingCancelBooking,
      errors: errorsCancelBooking,
    },
    reset: resetCancelBooking,
    control: controlCancelBooking,
    watch: watchCancelBooking,
    setValue: setValueCancelBooking,
    setError: setErrorCancelBooking,
  } = useForm(formCancelBookingOptions);

  const {
    register: registerPublishInvoice,
    handleSubmit: handleSubmitPublishInvoice,
    reset: resetPublishInvoice,
    formState: { isSubmitting: isSubmittingPublish },
  } = useForm();
  const {
    register: registerSendETicket,
    handleSubmit: handleSubmitSendETicket,
    reset: resetSendETicket,
    control: controlSendETicket,
    formState: { isSubmitting: isSubmittingETicket },
  } = useForm(formETicket);
  const optionCancel = watchCancelBooking('optionCancel');

  useEffect(async () => {
    const {
      data: { data },
    } = await bookingActions.getListExtendTimes({
      status: 1,
    });
    setListExtendTimes(data);
    const {
      data: { data: listCountries },
    } = await bookingActions.getCountries();
    setCountries(listCountries);
  }, []);
  useEffect(async () => {
    await bookingActions.getListPaymentMethod({ status: 1 }, 0, '');
    setValueAddExtendBooking('validity', '');
  }, []);
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  useEffect(async () => {
    const {
      data: { data: historyBooking },
    } = await bookingActions.getHistoriesBooking(booking_id);
    setListHistoryBooking(historyBooking);
  }, [actionReloadPage]);
  useEffect(() => {
    if (remainingAmount === 0) {
      setIsShowPayment(false);
    }
  }, [remainingAmount]);
  useEffect(async () => {
    const {
      data: { data: bookingDetail },
    } = await bookingActions.getBookingDetail(booking_id);
    console.log(bookingDetail, 'bookingDetail');
    setBookingDetail(bookingDetail);
    setValueAddExtendBooking('validity', new Date(bookingDetail?.expire_at));
    const bookingPaymentSlice = bookingDetail?.booking_payment
      ? bookingDetail?.booking_payment.slice(0, 4)
      : [];
    const bookingPaymentRefundSlice = bookingDetail?.booking_refund
      ? bookingDetail?.booking_refund.slice(0, 4)
      : [];

    setItemsBookingPayment(bookingPaymentSlice);
    setItemsBookingPaymentRefund(bookingPaymentRefundSlice);
    //default value of cancel booking
    if (bookingDetail && bookingDetail?.status != BookingStatusCancel) {
      setValueCancelBooking('optionCancel', FEStatusCancelWithout);
      if (bookingDetail.booking_payment) {
        bookingDetail.booking_payment.map((i, index) => {
          setValueCancelBooking(
            `amountCancel[${index}]`,
            i.amount.toLocaleString('en-US')
          );
          setValueCancelBooking(
            `paymentMethodCancel[${index}]`,
            i.payment_method.toString()
          );
        });
      }
    }

    let totalPayment = 0;
    if (bookingDetail.booking_payment) {
      bookingDetail.booking_payment.map((i, _) => {
        totalPayment = totalPayment + parseInt(i.amount);
      });
    }
    setAmountPayment(totalPayment);
    const remaining = bookingDetail.total_amount - totalPayment;
    setValuePayment('amount', remaining);
    setRemainingAmount(remaining);
    if (bookingDetail.status == 1 && bookingDetail.payment_type == 3) {
      setIsDisplayExtend(true);
    }
    if (
      (bookingDetail.payment_type === 3 &&
        totalPayment < parseInt(bookingDetail.total_amount)) ||
      (bookingDetail.payment_type === 2 && bookingDetail.status === 1)
    ) {
      setIsShowPayment(true);
    }
    setBookerandguestInfo({
      booker_name: bookingDetail?.booker_name,
      booker_email: bookingDetail?.booker_email,
      booker_address: bookingDetail?.booker_address,
      booker_address2: bookingDetail?.booker_address2,
      booker_city: bookingDetail?.booker_city,
      booker_code: bookingDetail?.booker_code,
      booker_country: bookingDetail?.booker_country,
      booker_phone: bookingDetail?.booker_phone,
      booker_company: bookingDetail?.booker_company,
      booker_notes: bookingDetail?.booker_notes,
      guest_name: bookingDetail?.guest_name,
      guest_address: bookingDetail?.guest_address,
      guest_address2: bookingDetail?.guest_address2,
      guest_city: bookingDetail?.guest_city,
      guest_code: bookingDetail?.guest_code,
      guest_country: bookingDetail?.guest_country,
      guest_email: bookingDetail?.guest_email,
      guest_phone: bookingDetail?.guest_phone,
      guest_company: bookingDetail?.guest_company,
      guest_note: bookingDetail?.guest_note,
    });

    //check display clone icon
    let dateShowDetail = moment(bookingDetail?.show_schedule_detail?.date);
    let monthShow = moment().month() + 1;
    let stringMonth = monthShow > 10 ? '' + monthShow : '0' + monthShow;
    let stringDate =
      moment().date() > 10 ? '' + moment().date() : '0' + moment().date();
    let currentDate = moment(
      moment().year() + '-' + stringMonth + '-' + stringDate
    );
    setDisplayCloneIcon(dateShowDetail.diff(currentDate) < 0 ? false : true);
  }, [actionReloadPage, booking_id]);
  useEffect(() => {
    if (bookingDetail?.status === 1) {
      setIsShowPayment(false);
    }
  }, [bookingDetail?.status]);
  function seatStatus(statusRow) {
    if (!bookingDetail) {
      return undefined; // Handle the case where bookingDetail is not provided
    }
    //statusRow : status của từng vé
    const {
      status: statusBooking,
      payment_type,
      show_schedule_detail,
    } = bookingDetail;
    const dateObject = new Date(show_schedule_detail?.date);
    if (statusBooking === 1 || statusBooking === 3) {
      if (payment_type === 1 || payment_type === 2) {
        const [hours, minutes, seconds] = show_schedule_detail?.time.split(':');
        dateObject.setHours(hours);
        dateObject.setMinutes(minutes);
        dateObject.setSeconds(seconds);

        const currentDate = new Date().getTime();
        if (dateObject.getTime() > currentDate || statusRow == 2) {
          switch (statusRow) {
            case 1:
              return 'Printed';
            case 2:
              return 'Occupied';
            default:
              if (payment_type === 2) {
                return 'Liability';
              } else {
                return 'Paid';
              }
          }
        } else {
          return 'No show';
        }
      } else if (payment_type === 3) {
        return 'Unpaid';
      }
    } else if (statusBooking === 2) {
      return 'Released';
    }
    return undefined; // Handle the case where none of the conditions are met
  }
  const handlePaymentMethodChange = (option) => {
    setPaymentMethod(option.target.value);
  };
  const handleLanguageChange = (option) => {
    if (option.target.value == '') {
      return;
    }
    viewETicket(option.target.value);
    setViewETicket(option.target.value);
  };

  function cloneBooking() {
    bookingActions
      .selectShow({
        id: parseInt(bookingDetail?.show_schedule_detail_id),
      })
      .then((res) => {
        if (res.status === 200) {
          setSelectedShow(res.data.data);
          history.push('/admin/booking/create/information');
        }
      });
  }
  function bookingStatus(status) {
    switch (status) {
      case 1:
        return 'Success';
      case 2:
        return 'Cancel';
      case 3:
        return 'Unpaid';
      default:
        break;
    }
  }
  function paymentType(pt) {
    switch (pt) {
      case PaymentTypePaid:
        return 'Paid';
      case PaymentTypeLiability:
        return 'Liability';
      case PaymentTypeUnpaid:
        return 'Unpaid';
      default:
        break;
    }
  }
  function goToPrintTheaterPass() {
    history.push(`/admin/booking/create/print-theater-pass/${booking_id}`);
  }
  function paymentMethodText(paymentMethod) {
    switch (paymentMethod) {
      case 1:
        return 'Pay by Cash';
      case 2:
        return 'Pay by CreditCard VCB';
      case 3:
        return 'Pay by CreditCard AGR';
      case 4:
        return 'Pay via Paypal';
      case 5:
        return 'Pay by Bank Transfer';
      case 6:
        return '[T] Test Payment deleted';
      case 7:
        return 'Complimentary';
      case 8:
        return 'Pay via OnePay';
      case 9:
        return 'Pay by CreditCard BIDV';
      default:
        break;
    }
  }

  function cancelledTypeText(type) {
    switch (type) {
      case 0:
        return 'Cancel without refund';
      case 1:
        return 'Cancel without charge';
      case 2:
        return 'Cancel with refund';
      case 3:
        return 'Cancel with charge';
      default:
        return 'Cancel by system due to Release Time';
    }
  }

  async function updateBookingInfoBookerandGuest(type) {
    const res = await bookingActions.updateBookingInfoGuestandBooker(
      bookerandguestInfo,
      booking_id
    );
    if (res.status === 200) {
      if (type === 'booker') {
        setEditBooker(false);
      } else {
        setEditGuest(false);
      }
      setActionReloadPage(Math.random());
    }
  }
  async function viewETicket(value) {
    const part = value.split('_');
    const response = await bookingActions.getETicketPDF(
      { code: bookingDetail?.code, lang: part[0], type: part[1] },
      booking_id
    );
    const url = URL.createObjectURL(response.data);
    window.open(url);
  }
  async function onSubmit({ note }) {
    try {
      if (isCreateSpecialRequest) {
        const res = await bookingActions.addSpecialRequest(
          {
            note,
          },
          booking_id
        );
        if (res.status === 200) {
          toast({
            description: `Add Special Request successful`,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
        setActionReloadPage(Math.random());
      }

      onClose();
      reset();
    } catch (err) {
      setError('apiError', { message: err.response.data.meta.message });
    }
  }
  async function onSubmitAddPayment({ paymentMethod, amount }) {
    const valAmount = amount.replace(/,/g, '');
    if (parseInt(valAmount) <= 0) {
      setError('amount', {
        message: 'Amount should be greater than 0',
      });
      return false;
    }
    let remaining = parseInt(bookingDetail.total_amount) - amountPayment;
    if (parseInt(valAmount) > parseInt(remaining)) {
      setError('amount', {
        message:
          'Amount should be equal to or less than ' +
          remaining.toLocaleString('en-US') +
          ' VND',
      });
      return false;
    }
    const res = await bookingActions.addPayment(
      {
        payment_method: parseInt(paymentMethod),
        amount: parseInt(valAmount),
      },
      booking_id
    );
    if (res.status === 200) {
      toast({
        description: `Add Payment successful`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setActionReloadPage(Math.random());
      onCloseAddPaymentModal();
      resetAddPayment();
      setError('apiError', {
        message: '',
      });
    }
  }
  async function onSubmitAddExtendBooking({ validity }) {
    try {
      const res = await bookingActions.addExtendBooking(
        {
          validity: new Date(validity).toISOString(),
        },
        booking_id
      );
      if (res.status === 200) {
        toast({
          description: `Add Extend Booking successful`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        setActionReloadPage(Math.random());
        onCloseAddExtendBookingModal();
        // resetAddExtendBooking();
        setErrorAddExtendBooking('apiError', {
          message: '',
        });
      }
    } catch (error) {
      console.warn(error);
    }
  }

  async function onSubmitCancelBooking({ optionCancel, noteCancel }) {
    try {
      let items = [];
      let amount = 0;
      if (bookingDetail && bookingDetail.booking_payment) {
        items = bookingDetail.booking_payment.map((i, index) => {
          if (optionCancel == FEStatusCancelWithout) {
            return {
              type: parseInt(optionCancel) || 0,
              note: noteCancel,
              amount: 0,
              refund_method: 0,
            };
          }

          amount = parseInt(
            watchCancelBooking(`amountCancel[${index}]`)?.replace(/,/g, '') || 0
          );
          if (amount === 0) {
            setErrorCancelBooking('apiError', {
              message: 'Amount should be greater than 0',
            });
            return;
          } else if (amount > i.amount) {
            setErrorCancelBooking('apiError', {
              message:
                'Amount should be equal to or less than Max ' +
                i.amount.toLocaleString('en-US'),
            });
            return;
          }

          return {
            type: parseInt(optionCancel) || 0,
            note: noteCancel,
            amount: amount,
            refund_method: parseInt(
              watchCancelBooking(`paymentMethodCancel[${index}]`)?.replace(
                /,/g,
                ''
              ) || 0
            ),
          };
        });
      } else if (
        bookingDetail &&
        (bookingDetail?.payment_type === PaymentTypeLiability ||
          bookingDetail?.payment_type === PaymentTypeUnpaid ||
          bookingDetail?.payment_type === PaymentTypePaid)
      ) {
        if (optionCancel == FEStatusCancelWithout) {
          items = [
            {
              type: parseInt(optionCancel) || 0,
              note: noteCancel,
              amount: 0,
              refund_method: 0,
            },
          ];
        } else {
          amount = parseInt(
            watchCancelBooking(`amountCancel[0]`)?.replace(/,/g, '') || 0
          );
          if (amount == 0) {
            setErrorCancelBooking('apiError', {
              message: 'Amount should be greater than 0',
            });
            return;
          } else if (amount > parseInt(bookingDetail.total_amount)) {
            setErrorCancelBooking('apiError', {
              message:
                'Amount should be equal to or less than Max ' +
                bookingDetail.total_amount.toLocaleString('en-US'),
            });
            return;
          } else {
            items = [
              {
                type: parseInt(optionCancel) || 0,
                note: noteCancel,
                amount: amount,
                refund_method: parseInt(
                  watchCancelBooking(`paymentMethodCancel[${0}]`)?.replace(
                    /,/g,
                    ''
                  ) || 0
                ),
              },
            ];
          }
        }
      }
      const res = await bookingActions.cancelBookingAdminTool(
        {
          items,
        },
        booking_id
      );
      if (res.status === 200) {
        toast({
          description: `Cancel Booking successful`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        setActionReloadPage(Math.random());
        onCloseCancelBookingModal();
        resetCancelBooking();
        setErrorCancelBooking('apiError', {
          message: '',
        });
      }
    } catch (error) {
      setErrorCancelBooking('apiError', {
        message: error.response.data.meta.message,
      });
      console.warn(error);
    }
  }
  const Unpaid = 3;
  const Cancel = 2;
  async function showViewHistory() {
    onOpenViewHistoryModal();
    const {
      data: { data: historyBooking },
    } = await bookingActions.getHistoriesBooking(booking_id);
    setListHistoryBooking(historyBooking);
  }
  async function onSubmitPublishInvoice({
    email,
    invoice_issuer,
    company_name,
    tax_code,
    company_address,
  }) {
    const params = {
      booking_id: bookingDetail.id,
      company_name,
      tax_code,
      company_address,
      email,
      invoice_issuer: parseInt(invoice_issuer),
    };
    try {
      const res = await bookingActions.publishInvoice(params);
      if (res.status === 200) {
        onClosePublishInvoiceModal();
        toast({
          description: 'Publish Invoice success',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        window.location.reload();
      }
    } catch (err) {
      console.warn(err);
    }
  }
  async function onSubmitSendETicket({ email, subject, eTicketType, content }) {
    const params = {
      'e-ticket-type': parseInt(eTicketType),
      subject,
      content,
      email,
    };
    try {
      const res = await bookingActions.sendETicket(params, booking_id);
      if (res.status === 200) {
        onCloseSendETicketModal();
        toast({
          description: 'Send ETicket success',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        resetSendETicket();
      }
    } catch (err) {
      resetSendETicket();
      console.warn(err);
    }
  }
  const textColorIcon = useColorModeValue('blue.500', 'white');

  function getSubject(lang) {
    const prefix =
      lang === 'vi' ? 'VÉ ĐIỆN TỬ CỦA MÃ ĐẶT VÉ ' : 'ETICKET FOR BOOKING ';
    return (
      '[' +
      bookingDetail?.show_schedule_detail?.show_schedule?.show.name +
      '] - ' +
      prefix +
      bookingDetail?.code
    );
  }

  function enableCancelWithRefund() {
    if (!bookingDetail || !Array.isArray(bookingDetail.booking_payment)) {
      return false;
    }
    return !(
      bookingDetail.booking_payment.filter((i) => i.payment_method === 7)
        .length === bookingDetail.booking_payment.length
    );
  }

  return (
    <Card>
      <CardHeader px='22px'>
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpenViewHistoryModal}
          onClose={() => {
            onCloseViewHistoryModal();
            reset();
          }}
          size='6xl'
        >
          <ModalOverlay />
          <form id='view-history-form'>
            <ModalContent>
              <ModalHeader>View History</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Box overflowX={'auto'}>
                  <Table variant='simple' color={textColor}>
                    <Thead>
                      <Tr my='.8rem' pl='0px' color='gray.400'>
                        <Th borderColor={borderColor} p='16px 12px'>
                          Action
                        </Th>
                        <Th borderColor={borderColor} p='16px 12px'>
                          Updated by
                        </Th>
                        <Th borderColor={borderColor} p='16px 12px'>
                          History
                        </Th>
                        <Th borderColor={borderColor} p='16px 12px'>
                          Updated at
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {listHistoryBooking?.map((item, index) => (
                        <Tr key={index}>
                          <Td
                            borderColor={borderColor}
                            p='16px 12px'
                            borderRight='1px solid gray'
                          >
                            <Text fontSize='xs' color={textColor}>
                              {item?.history_action}
                            </Text>
                          </Td>
                          <Td
                            borderColor={borderColor}
                            p='16px 12px'
                            borderRight='1px solid gray'
                          >
                            <Text fontSize='xs' color={textColor}>
                              {item?.history_user_mail}
                            </Text>
                          </Td>
                          <Td
                            borderColor={borderColor}
                            p='16px 12px'
                            borderRight='1px solid gray'
                          >
                            <Text fontSize='xs' color={textColor}>
                              {item?.diff &&
                                item.diff.map((i, index) => (
                                  <div key={index}>
                                    <Text fontSize='base' fontWeight='bold'>
                                      {i.path}
                                    </Text>

                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: i.to,
                                      }}
                                    ></div>
                                  </div>
                                ))}
                            </Text>
                          </Td>

                          <Td borderColor={borderColor} p='16px 12px'>
                            <Text fontSize='xs' color={textColor}>
                              {moment(item?.created_at).format(
                                'DD/MM/YYYY HH:mm:ss'
                              )}
                            </Text>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </ModalBody>
              <ModalFooter pt='0'>
                <Button
                  onClick={() => {
                    onCloseViewHistoryModal();
                    reset();
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpenAddPaymentModal}
          onClose={() => {
            onCloseAddPaymentModal();
            resetAddPayment();
            reset();
          }}
          size='xl'
        >
          <ModalOverlay />
          <form
            id='add-payment-method-form'
            onSubmit={handleSubmitAddPayment(onSubmitAddPayment)}
          >
            <ModalContent>
              <ModalHeader>Add Payment</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Grid gap='16px' mb={3}>
                  <Flex direction='column'>
                    <FormControl>
                      <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                        Remaining Amount:{' '}
                        {remainingAmount
                          ? remainingAmount.toLocaleString('en-US')
                          : 0}{' '}
                        VND
                      </FormLabel>
                    </FormControl>
                  </Flex>
                </Grid>
                <Grid gap='16px' mb={3}>
                  <Flex direction='column'>
                    <FormControl isRequired>
                      <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                        Payment Method
                      </FormLabel>
                      <Select
                        ms='4px'
                        type='text'
                        placeholder=''
                        size='sm'
                        name='paymentMethod'
                        {...registerAddPayment('paymentMethod')}
                        onChange={handlePaymentMethodChange}
                        isInvalid={errors.paymentMethod}
                        control={controlPayment}
                      >
                        {listPaymentMethod.map((item, index) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                      <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                        {errors.paymentMethod?.message}
                      </Text>
                    </FormControl>
                  </Flex>
                </Grid>
                <Grid gap='16px' mb={3}>
                  <Flex direction='column'>
                    <FormControl isRequired>
                      <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                        Amount
                      </FormLabel>
                      <Controller
                        name='amount'
                        control={controlPayment}
                        render={({ field }) => (
                          <NumericFormat
                            customInput={Input}
                            thousandSeparator=','
                            fontSize='base'
                            ms='4px'
                            placeholder='Amount'
                            size='sm'
                            {...field}
                            className='amount'
                          />
                        )}
                      />
                      <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                        {errors.amount?.message}
                      </Text>
                    </FormControl>
                  </Flex>
                </Grid>
                <Text color='red' ml='5px'>
                  {errors.apiError && errors.apiError?.message}
                </Text>
              </ModalBody>
              <ModalFooter pt='0'>
                <Button
                  isLoading={isSubmittingAddPayment}
                  disabled={isSubmittingAddPayment}
                  colorScheme='blue'
                  mr={3}
                  type='submit'
                >
                  Add
                </Button>
                <Button
                  onClick={() => {
                    onCloseAddPaymentModal();
                    resetAddPayment();
                    reset();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            reset();
          }}
          size='xl'
        >
          <ModalOverlay />
          <form id='add-special-request-form' onSubmit={handleSubmit(onSubmit)}>
            <ModalContent>
              <ModalHeader>Add Special Request</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Grid gap='16px' templateColumns={{base: '1fr', lg: 'repeat(2, 1fr)'}} mb={3} display={{base: 'block', lg: 'grid'}}>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                      Note
                    </FormLabel>
                    <Editor
                      name='note'
                      {...register('note')}
                      isInvalid={errors.note}
                      control={control}
                    />

                    <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                      {errors.note?.message}
                    </Text>
                  </FormControl>
                </Grid>
                <Text color='red' ml='5px'>
                  {errors.apiError && errors.apiError?.message}
                </Text>
              </ModalBody>
              <ModalFooter pt='0'>
                <Button
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  colorScheme='blue'
                  mr={3}
                  type='submit'
                >
                  Create
                </Button>
                <Button
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpenAddExtendBookingModal}
          onClose={() => {
            onCloseAddExtendBookingModal();
            resetAddExtendBooking();
          }}
          size='xl'
        >
          <ModalOverlay />
          <form
            id='add-extend-booking-form'
            onSubmit={handleSubmitAddExtendBooking(onSubmitAddExtendBooking)}
          >
            <ModalContent>
              <ModalHeader>Add Extend Booking</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Grid gap='16px' mb={3}>
                  <Flex direction='column'>
                    <FormControl isRequired>
                      <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                        Validity
                      </FormLabel>
                      <Controller
                        name='validity'
                        control={controlAddExtendBooking}
                        render={({ field }) => (
                          <ReactDatePicker
                            {...field}
                            calendarStartDay={1}
                            filterTime={filterPassedTime}
                            closeOnScroll={false}
                            timeFormat='hh:mm aa'
                            customInput={<Input autoComplete='off' />}
                            showTimeSelect
                            selected={field?.value}
                            dateFormat='dd/MM/yyyy hh:mm aa'
                            className='booking-date-range-search'
                            minDate={new Date()}
                            maxDate={new Date(moment(bookingDetail?.showdate))}
                            minTime={
                              validateTime &&
                              validateTime?.toDateString() ===
                                new Date(
                                  new Date(bookingDetail?.showdate)
                                )?.toDateString()
                                ? setHours(setMinutes(new Date(), 0), 0)
                                : new Date(validateTime).getDate() ===
                                  new Date().getDate()
                                ? setHours(
                                    setMinutes(
                                      new Date(),
                                      new Date().getMinutes()
                                    ),
                                    new Date().getHours()
                                  )
                                : setHours(setMinutes(new Date(), 0), 0)
                            }
                            maxTime={
                              validateTime &&
                              validateTime?.toDateString() ===
                                new Date(
                                  new Date(bookingDetail?.showdate)
                                ).toDateString()
                                ? setHours(
                                    setMinutes(new Date(), 15),
                                    parseInt(
                                      bookingDetail?.showtime.slice(0, 2)
                                    )
                                  )
                                : setHours(setMinutes(new Date(), 30), 23)
                            }
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        )}
                      />
                      <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                        {errorsExtendBooking.validity?.message}
                      </Text>
                    </FormControl>
                  </Flex>
                </Grid>

                <Text color='red' ml='5px'>
                  {errorsExtendBooking.apiError &&
                    errorsExtendBooking.apiError?.message}
                </Text>
              </ModalBody>
              <ModalFooter pt='0'>
                <Button
                  isLoading={isSubmittingExtendBooking}
                  disabled={isSubmittingExtendBooking}
                  colorScheme='blue'
                  mr={3}
                  type='submit'
                >
                  Add
                </Button>
                <Button
                  onClick={() => {
                    onCloseAddExtendBookingModal();
                    resetAddExtendBooking();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpenCancelBookingModal}
          onClose={() => {
            onCloseCancelBookingModal();
            resetCancelBooking();
          }}
          size='xl'
        >
          <ModalOverlay />
          <form
            id='cancel-booking-method-form'
            onSubmit={handleSubmitCancelBooking(onSubmitCancelBooking)}
          >
            <ModalContent>
              <ModalHeader>Cancel Booking</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Grid gap='16px' mb={3}>
                  <Flex direction='column'>
                    <FormControl>
                      <Controller
                        name='optionCancel'
                        control={controlCancelBooking}
                        render={({ field }) => (
                          <RadioGroup {...field} defaultValue='2'>
                            <Stack direction='column' spacing={4}>
                              <Flex direction='column'>
                                <Radio value='2'>
                                  Cancel without{' '}
                                  {bookingDetail?.payment_type ===
                                    PaymentTypePaid ||
                                  bookingDetail?.payment_type ===
                                    PaymentTypeUnpaid
                                    ? 'Refund'
                                    : 'Charge'}
                                </Radio>
                                {((bookingDetail?.booking_payment &&
                                  bookingDetail?.payment_type ===
                                    PaymentTypeUnpaid) ||
                                  (bookingDetail?.payment_type ===
                                    PaymentTypePaid &&
                                    enableCancelWithRefund()) ||
                                  bookingDetail?.payment_type ===
                                    PaymentTypeLiability) && (
                                  <Radio value='1'>
                                    Cancel with{' '}
                                    {bookingDetail?.payment_type ===
                                      PaymentTypePaid ||
                                    bookingDetail?.payment_type ===
                                      PaymentTypeUnpaid
                                      ? 'Refund'
                                      : 'Charge'}
                                  </Radio>
                                )}
                              </Flex>
                            </Stack>
                          </RadioGroup>
                        )}
                      />
                    </FormControl>

                    {optionCancel === FEStatusCancelWith &&
                      bookingDetail?.booking_payment && (
                        <Flex direction='column'>
                          {bookingDetail.booking_payment.map((i, index) => (
                            <Grid
                              templateColumns={{
                                lg: '1fr 6fr',
                              }}
                              alignItems='flex-start'
                              gap='40px'
                            >
                              <Flex gap='16px' mb={3}>
                                <Flex direction=''>
                                  <FormControl isRequired>
                                    <FormLabel
                                      ms='4px'
                                      fontSize='base'
                                      fontWeight='normal'
                                    >
                                      Amount
                                    </FormLabel>
                                    <Controller
                                      name={`amountCancel[${index}]`}
                                      control={controlCancelBooking}
                                      render={({ field }) => (
                                        <NumericFormat
                                          customInput={Input}
                                          thousandSeparator=','
                                          fontSize='base'
                                          ms='4px'
                                          placeholder='Amount'
                                          size='sm'
                                          {...field}
                                          className='amount'
                                          style={{ minWidth: '240px' }}
                                        />
                                      )}
                                    />
                                    <Text fontSize={'base'} mb='10px' ml='5px'>
                                      (Max: {i.amount.toLocaleString('en-US')})
                                    </Text>
                                    <Text
                                      color='red'
                                      fontSize={'base'}
                                      mb='10px'
                                      ml='5px'
                                    >
                                      {errorsCancelBooking.amount?.message}
                                    </Text>
                                  </FormControl>
                                </Flex>
                              </Flex>
                              <Flex gap='16px' mb={3}>
                                <Flex direction='column'>
                                  <FormControl isRequired>
                                    <FormLabel
                                      ms='4px'
                                      fontSize='base'
                                      fontWeight='normal'
                                    >
                                      Cancel Method
                                    </FormLabel>
                                    <Select
                                      ms='4px'
                                      type='text'
                                      placeholder=''
                                      size='sm'
                                      className='paymentMethodCancel'
                                      name={`paymentMethodCancel[${index}]`}
                                      {...registerCancelBooking(
                                        `paymentMethodCancel[${index}]`
                                      )}
                                      isInvalid={
                                        errorsCancelBooking.paymentMethod
                                      }
                                      control={controlCancelBooking}
                                      minWidth={'240px'}
                                    >
                                      {listPaymentMethod.map((item, index) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </Select>
                                    <Text
                                      color='red'
                                      fontSize={'base'}
                                      mb='10px'
                                      ml='5px'
                                    >
                                      {
                                        errorsCancelBooking.paymentMethod
                                          ?.message
                                      }
                                    </Text>
                                  </FormControl>
                                </Flex>
                              </Flex>
                            </Grid>
                          ))}
                        </Flex>
                      )}
                    {optionCancel === FEStatusCancelWith &&
                      bookingDetail?.payment_type === PaymentTypeLiability &&
                      !bookingDetail?.booking_payment && (
                        <Flex direction='column'>
                          <Grid
                            templateColumns={{
                              lg: '1fr 6fr',
                            }}
                            alignItems='flex-start'
                            gap='40px'
                          >
                            <Flex gap='16px' mb={3}>
                              <Flex direction=''>
                                <FormControl isRequired>
                                  <FormLabel
                                    ms='4px'
                                    fontSize='base'
                                    fontWeight='normal'
                                  >
                                    Amount
                                  </FormLabel>
                                  <Controller
                                    name={`amountCancel[${0}]`}
                                    control={controlCancelBooking}
                                    render={({ field }) => (
                                      <NumericFormat
                                        customInput={Input}
                                        thousandSeparator=','
                                        fontSize='base'
                                        ms='4px'
                                        placeholder='Amount'
                                        size='sm'
                                        {...field}
                                        className='amount'
                                        style={{ minWidth: '240px' }}
                                      />
                                    )}
                                  />
                                  <Text fontSize={'base'} mb='10px' ml='5px'>
                                    (Max:{' '}
                                    {bookingDetail?.total_amount.toLocaleString(
                                      'en-US'
                                    )}
                                    )
                                  </Text>
                                  <Text
                                    color='red'
                                    fontSize={'base'}
                                    mb='10px'
                                    ml='5px'
                                  >
                                    {errorsCancelBooking.amount?.message}
                                  </Text>
                                </FormControl>
                              </Flex>
                            </Flex>
                            <Flex gap='16px' mb={3}>
                              <Flex direction='column'>
                                <FormControl isRequired>
                                  <FormLabel
                                    ms='4px'
                                    fontSize='base'
                                    fontWeight='normal'
                                  >
                                    Cancel Method
                                  </FormLabel>
                                  <Select
                                    ms='4px'
                                    type='text'
                                    placeholder=''
                                    size='sm'
                                    className='paymentMethodCancel'
                                    name={`paymentMethodCancel[${0}]`}
                                    {...registerCancelBooking(
                                      `paymentMethodCancel[${0}]`
                                    )}
                                    isInvalid={
                                      errorsCancelBooking.paymentMethod
                                    }
                                    control={controlCancelBooking}
                                    minWidth={'240px'}
                                  >
                                    {listPaymentMethod.map((item, index) => (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </Select>
                                  <Text
                                    color='red'
                                    fontSize={'base'}
                                    mb='10px'
                                    ml='5px'
                                  >
                                    {errorsCancelBooking.paymentMethod?.message}
                                  </Text>
                                </FormControl>
                              </Flex>
                            </Flex>
                          </Grid>
                        </Flex>
                      )}
                  </Flex>
                </Grid>

                <Grid gap='16px' mb={3}>
                  <Flex direction='column'>
                    <FormControl isRequired>
                      <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                        Note
                      </FormLabel>
                      <Input
                        fontSize='base'
                        ms='4px'
                        type='text'
                        placeholder='Note'
                        mb={
                          errorsCancelBooking.noteCancel?.message
                            ? '5px'
                            : '24px'
                        }
                        size='md'
                        name='noteCancel'
                        {...registerCancelBooking('noteCancel')}
                        isInvalid={errorsCancelBooking.noteCancel}
                        errorBorderColor='red.300'
                      />
                      <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                        {errorsCancelBooking.noteCancel?.message}
                      </Text>
                    </FormControl>
                  </Flex>
                </Grid>
                <Text color='red' ml='5px'>
                  {errorsCancelBooking.apiError &&
                    errorsCancelBooking.apiError?.message}
                </Text>
              </ModalBody>
              <ModalFooter pt='0'>
                <Button
                  isLoading={isSubmittingCancelBooking}
                  disabled={isSubmittingCancelBooking}
                  colorScheme='blue'
                  mr={3}
                  type='submit'
                >
                  Cancel Booking
                </Button>
                <Button
                  onClick={() => {
                    onCloseCancelBookingModal();
                    resetCancelBooking();
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>

        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpenPublishInvoiceModal}
          onClose={() => {
            onClosePublishInvoiceModal();
            resetPublishInvoice();
          }}
          size='2xl'
        >
          <ModalOverlay />
          <form onSubmit={handleSubmitPublishInvoice(onSubmitPublishInvoice)}>
            <ModalContent>
              <ModalHeader color={'green'}>
                Publish invoice booking {bookingDetail?.code}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl isRequired>
                  <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                    Company Name
                  </FormLabel>
                  <Input
                    fontSize='base'
                    ms='4px'
                    type='text'
                    placeholder='Company Name'
                    maxLength={250}
                    size='md'
                    name='company_name'
                    {...registerPublishInvoice('company_name')}
                    isInvalid={errors.company_name}
                    errorBorderColor='red.300'
                  />
                  <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                    {errors.company_name?.message}
                  </Text>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                    Tax Code
                  </FormLabel>
                  <Input
                    fontSize='base'
                    ms='4px'
                    type='text'
                    placeholder='Tax Code'
                    maxLength={250}
                    size='md'
                    name='tax_code'
                    {...registerPublishInvoice('tax_code')}
                    isInvalid={errors.tax_code}
                    errorBorderColor='red.300'
                  />
                  <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                    {errors.tax_code?.message}
                  </Text>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                    Company Address
                  </FormLabel>
                  <Input
                    fontSize='base'
                    ms='4px'
                    type='text'
                    placeholder='Company Address'
                    maxLength={250}
                    size='md'
                    name='company_address'
                    {...registerPublishInvoice('company_address')}
                    isInvalid={errors.company_address}
                    errorBorderColor='red.300'
                  />
                  <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                    {errors.company_address?.message}
                  </Text>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                    Email
                  </FormLabel>
                  <Input
                    fontSize='base'
                    ms='4px'
                    type='text'
                    placeholder='Email'
                    maxLength={250}
                    size='md'
                    name='email'
                    {...registerPublishInvoice('email')}
                    isInvalid={errors.email}
                    errorBorderColor='red.300'
                  />
                  <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                    {errors.email?.message}
                  </Text>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                    Invoice issuer
                  </FormLabel>
                  <Select
                    ms='4px'
                    type='text'
                    defaultValue='0'
                    size='sm'
                    name='invoice_issuer'
                    {...registerPublishInvoice('invoice_issuer')}
                    width='120px'
                  >
                    <option value='0'>Default</option>
                    <option value='1'>SaiGon</option>
                    <option value='2'>Hoi An</option>
                  </Select>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme='blue'
                  mr={3}
                  type='submit'
                  isLoading={isSubmittingPublish}
                >
                  Publish
                </Button>
                <Button
                  onClick={() => {
                    onClosePublishInvoiceModal();
                    resetPublishInvoice();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>

        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpenSendETicketModal}
          onClose={() => {
            onCloseSendETicketModal();
            resetSendETicket();
          }}
          size='2xl'
        >
          <ModalOverlay />
          <form onSubmit={handleSubmitSendETicket(onSubmitSendETicket)}>
            <ModalContent>
              <ModalHeader color={'green'}>Send E-ticket</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl isRequired>
                  <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                    Email
                  </FormLabel>
                  <Input
                    defaultValue={bookerandguestInfo.booker_email}
                    fontSize='base'
                    ms='4px'
                    type='text'
                    placeholder='Email'
                    maxLength={250}
                    size='md'
                    name='email'
                    {...registerSendETicket('email')}
                    isInvalid={errors.email}
                    errorBorderColor='red.300'
                  />
                  <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                    {errors.email?.message}
                  </Text>
                </FormControl>
                <FormControl>
                  <FormLabel ms='4px' fontSize='base' fontWeight='normal'>
                    Subject
                  </FormLabel>
                  <Select
                    ms='4px'
                    type='text'
                    defaultValue='0'
                    size='sm'
                    name='subject'
                    {...registerSendETicket('subject')}
                  >
                    <option value={getSubject('vi')}>{getSubject('vi')}</option>
                    <option value={getSubject('en')}>{getSubject('en')}</option>
                  </Select>
                  <Text color='red' fontSize={'base'} mb='10px' ml='5px'>
                    {errors.subject?.message}
                  </Text>
                </FormControl>
                <FormControl mb={2}>
                  <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                    Type
                  </FormLabel>
                  <Select
                    ms='4px'
                    type='text'
                    defaultValue='0'
                    size='sm'
                    name='eTicketType'
                    {...registerSendETicket('eTicketType')}
                  >
                    {bookingDetail?.payment_type !== Unpaid && (
                      <>
                        <option value='1'>E-ticket B2C English</option>
                        <option value='2'>E-ticket B2C Tiếng Việt</option>
                        <option value='3'>E-ticket B2B English</option>
                        <option value='4'>E-ticket B2B Tiếng Việt</option>
                      </>
                    )}
                    <option value='5'>Booking information English</option>
                    <option value='6'>Booking information Tiếng Việt</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                    Content
                  </FormLabel>
                  <Editor name='content' control={controlSendETicket} />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme='blue'
                  mr={3}
                  type='submit'
                  isLoading={isSubmittingETicket}
                >
                  Send E Ticket
                </Button>
                <Button
                  onClick={() => {
                    onCloseSendETicketModal();
                    resetSendETicket();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      </CardHeader>
      <CardBody>
        <Box>
          <Box display={{ base: 'block', lg: 'inline-block' }}>
            <Grid
              templateColumns={{ base: '1fr', md: 'repeat(3, auto)' }}
              gap='16px'
            >
              <Flex direction='column'>
                <Text fontSize='lg' fontWeight='bold' mb='10px'>
                  Booking Details
                </Text>
                <Flex>
                  <Text fontSize='base' color='black.900'>
                    Booking No.:
                  </Text>
                  <Text
                    fontSize='base'
                    ml={2}
                    fontWeight='bold'
                    color='var(--chakra-colors-blue-500)'
                  >
                    {bookingDetail?.code}
                  </Text>
                </Flex>
                <Flex>
                  <Text fontSize='base' color='black.900'>
                    Booked Date:
                  </Text>
                  <Text
                    ml={2}
                    fontSize='base'
                    fontWeight='bold'
                    color='#2D3748'
                  >
                    {moment(bookingDetail?.created_at).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}
                  </Text>
                </Flex>
                <Flex>
                    <Text fontSize='base' color='black.900'>
                    by:
                  </Text>
                  <Text
                    ml={2}
                    fontSize='base'
                    fontWeight='bold'
                    color='#2D3748'
                  >
                    {bookingDetail?.created_by &&
                    bookingDetail?.created_by.length > 0
                      ? bookingDetail?.created_by
                      : 'guestbooking'}
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <Flex direction='column' align='center'>
                  <QRCode
                    value={bookingDetail?.code}
                    size={64}
                    level={'M'}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}
                  />
                  {bookingDetail?.status !== Cancel && (
                    <Select
                      textAlign='center'
                      onChange={handleLanguageChange}
                      textDecoration='underline'
                      fontWeight='bold'
                      fontSize='base'
                      color='var(--chakra-colors-blue-500)'
                      variant='unstyled'
                      placeholder='View E-Ticket'
                      style={{ 
                        paddingRight: 6,
                        'text-align-last': 'center',
                        '-moz-text-align-last': 'center',
                        'appearance': 'none',
                        '-webkit-appearance': 'none'
                       }}
                    >
                      {bookingDetail?.payment_type !== Unpaid && (
                        <>
                          <option value='en_B2C'>E-ticket B2C English</option>
                          <option value='vi_B2C'>
                            E-ticket B2C Tiếng Việt
                          </option>
                          <option value='en_B2B'>E-ticket B2B English</option>
                          <option value='vi_B2B'>
                            E-ticket B2B Tiếng Việt
                          </option>
                        </>
                      )}

                      <option value='en_Information'>
                        Booking information English
                      </option>
                      <option value='vi_Information'>
                        Booking information Tiếng Việt
                      </option>
                    </Select>
                  )}
                </Flex>
                {bookingDetail?.status == Cancel && (
                  <Flex>
                    <Image
                      style={{ height: '64px' }}
                      src={cancelLogo}
                      alt='logo'
                    />
                  </Flex>
                )}
              </Flex>
              <Flex ml={4}>
                <Stack direction='row' alignItems='center' spacing={4}>
                  {checkForbidden(45) &&
                    bookingDetail?.status !== Cancel &&
                    parseInt(bookingDetail?.payment_type) !== Unpaid && (
                      <Tooltip
                        hasArrow
                        label='Print Theater Pass'
                        bg='gray.400'
                      >
                        <Button
                          className='icon-btn-no-shadow'
                          size='m'
                          colorScheme='blue'
                          p='0px'
                          variant='no-effects'
                          onClick={() => {
                            goToPrintTheaterPass();
                          }}
                        >
                          <Flex
                            color={textColorIcon}
                            cursor='pointer'
                            align='center'
                            p='0px'
                          >
                            <Icon w={6} h={6} as={IoPrintOutline} me='0px' />
                          </Flex>
                        </Button>
                      </Tooltip>
                    )}
                  {checkForbidden(45) && bookingDetail?.status !== Cancel && (
                    <Tooltip hasArrow label='Send Email' bg='gray.400'>
                      <Button
                        className='icon-btn-no-shadow'
                        size='m'
                        colorScheme='blue'
                        p='0px'
                        variant='no-effects'
                        onClick={() => {
                          onOpenSendETicketModal();
                        }}
                      >
                        <Flex
                          color={textColorIcon}
                          cursor='pointer'
                          align='center'
                          p='0px'
                        >
                          <Icon w={6} h={6} as={IoMail} me='0px' />
                        </Flex>
                      </Button>
                    </Tooltip>
                  )}
                  {checkForbidden(8) &&
                    bookingDetail?.status != BookingStatusCancel && (
                      <Tooltip hasArrow label='Cancel Booking' bg='gray.400'>
                        <Button
                          className='icon-btn-no-shadow'
                          size='m'
                          colorScheme='blue'
                          p='0px'
                          variant='no-effects'
                          onClick={() => {
                            onOpenCancelBookingModal();
                          }}
                        >
                          <Flex
                            color={textColorIcon}
                            cursor='pointer'
                            align='center'
                            p='0px'
                          >
                            <Icon w={6} h={6} as={MdOutlineCancel} me='0px' />
                          </Flex>
                        </Button>
                      </Tooltip>
                    )}
                  {checkForbidden(7) && isDisplayCloneIcon && (
                    <Tooltip hasArrow label='Clone Booking' bg='gray.400'>
                      <Button
                        className='icon-btn-no-shadow'
                        size='m'
                        colorScheme='blue'
                        p='0px'
                        variant='no-effects'
                        onClick={() => {
                          cloneBooking();
                        }}
                      >
                        <Flex
                          color={textColorIcon}
                          cursor='pointer'
                          align='center'
                          p='0px'
                        >
                          <Icon w={6} h={6} as={FaRegClone} me='0px' />
                        </Flex>
                      </Button>
                    </Tooltip>
                  )}
                  {checkForbidden(18) &&
                    bookingDetail?.status !== Cancel &&
                    bookingDetail?.payment_type !== 3 &&
                    bookingDetail?.invoice_status === 0 && (
                      <Tooltip hasArrow label='Public Invoice' bg='gray.400'>
                        <Button
                          className='icon-btn-no-shadow'
                          size='m'
                          colorScheme='blue'
                          p='0px'
                          variant='no-effects'
                          onClick={() => {
                            onOpenPublishInvoiceModal();
                          }}
                        >
                          <Flex
                            color={textColorIcon}
                            cursor='pointer'
                            align='center'
                            p='0px'
                          >
                            <Icon w={6} h={6} as={MdPublish} me='0px' />
                          </Flex>
                        </Button>
                      </Tooltip>
                    )}
                  {isDisplayExtend && (
                    <Tooltip hasArrow label='Extend Booking' bg='gray.400'>
                      <Button
                        className='icon-btn-no-shadow'
                        size='m'
                        colorScheme='blue'
                        p='0px'
                        variant='no-effects'
                        onClick={() => {
                          onOpenAddExtendBookingModal();
                        }}
                      >
                        <Flex
                          color={textColorIcon}
                          cursor='pointer'
                          align='center'
                          p='0px'
                        >
                          <Icon
                            w={6}
                            h={6}
                            as={AiOutlineClockCircle}
                            me='0px'
                          />
                        </Flex>
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip hasArrow label='View History' bg='gray.400'>
                    <Button
                      className='icon-btn-no-shadow'
                      size='m'
                      colorScheme='blue'
                      p='0px'
                      variant='no-effects'
                      onClick={() => {
                        showViewHistory();
                      }}
                    >
                      <Flex
                        color={textColorIcon}
                        cursor='pointer'
                        align='center'
                        p='0px'
                      >
                        <Icon w={7} h={7} as={MdHistory} me='0px' />
                      </Flex>
                    </Button>
                  </Tooltip>
                </Stack>
              </Flex>
            </Grid>
          </Box>
          <HSeparator my={4} />
          <Box display={{ base: 'block', lg: 'inline-block' }}>
            <Grid
              templateColumns={{
                sm: '1fr',
                lg: 'repeat(2, auto)',
              }}
              columnGap={5}
            >
              <Flex align='center' gap={2} mb={2}>
                <Text
                  fontSize='base'
                  color='#718096'
                  minW={{ base: '100px', lg: '110px' }}
                >
                  Show Name:
                </Text>
                <Text fontWeight='bold' fontSize='base'>
                  {
                    bookingDetail?.show_schedule_detail?.show_schedule?.show
                      .name
                  }
                </Text>
              </Flex>
              <Flex align='center' gap={2} mb={2}>
                <Text
                  fontSize='base'
                  color='#718096'
                  minW={{ base: '100px', lg: '110px' }}
                >
                  Venue:
                </Text>
                <Text fontWeight='bold' fontSize='base'>
                  {
                    bookingDetail?.show_schedule_detail?.show_schedule?.venue
                      .name
                  }
                </Text>
              </Flex>
              <Flex align='center' gap={2} mb={2}>
                <Text
                  fontSize='base'
                  color='#718096'
                  minW={{ base: '100px', lg: '110px' }}
                >
                  Show Date:
                </Text>
                <Text fontWeight='bold' fontSize='base'>
                  {moment(bookingDetail?.show_schedule_detail?.date).format(
                    'ddd, DD MMM YYYY'
                  )}
                </Text>
              </Flex>
              <Flex align='center' gap={2} mb={2}>
                <Text
                  fontSize='base'
                  color='#718096'
                  minW={{ base: '100px', lg: '110px' }}
                >
                  Show Time:
                </Text>
                <Text fontWeight='bold' fontSize='base'>
                  {bookingDetail?.show_schedule_detail?.time.slice(0, -3)}
                </Text>
              </Flex>
              <Flex align='center' gap={2} mb={2}>
                <Text
                  fontSize='base'
                  color='#718096'
                  minW={{ base: '100px', lg: '110px' }}
                >
                  Address:
                </Text>
                <Text fontWeight='bold' fontSize='base'>
                  {
                    bookingDetail?.show_schedule_detail?.show_schedule?.venue
                      .address
                  }
                </Text>
              </Flex>
              <Flex></Flex>
              <Flex align='center' gap={2} mb={2}>
                <Text
                  fontSize='base'
                  color='#718096'
                  minW={{ base: '100px', lg: '110px' }}
                >
                  Adult:
                </Text>
                <Text fontWeight='bold' fontSize='base'>
                  {bookingDetail?.adult}
                </Text>
              </Flex>
              <Flex align='center' gap={2} mb={2}>
                <Text
                  fontSize='base'
                  color='#718096'
                  minW={{ base: '100px', lg: '110px' }}
                >
                  Child (5 - 12 ages):
                </Text>
                <Text fontWeight='bold' fontSize='base'>
                  {bookingDetail?.child}
                </Text>
              </Flex>
              <Flex align='center' gap={2} mb={2}>
                <Text
                  fontSize='base'
                  color='#718096'
                  minW={{ base: '100px', lg: '110px' }}
                >
                  Zone:
                </Text>
                <Text fontWeight='bold' fontSize='base'>
                  {bookingDetail?.zone?.name}
                </Text>
              </Flex>
              <Flex align='center' gap={2} mb={2}>
                <Text
                  fontSize='base'
                  color='#718096'
                  minW={{ base: '100px', lg: '110px' }}
                >
                  Floor:
                </Text>
                <Text fontWeight='bold' fontSize='base'>
                  {showTextFloor(bookingDetail?.floor)}
                </Text>
              </Flex>
              <Flex align='center' gap={2} mb={2}>
                <Text
                  fontSize='base'
                  color='#718096'
                  minW={{ base: '100px', lg: '110px' }}
                >
                  Seat No.:
                </Text>
                <Text
                  cursor={'pointer'}
                  maxW='500px'
                  fontWeight='bold'
                  ml={1}
                  fontSize='base'
                  color='var(--chakra-colors-blue-500)'
                >
                  {bookingDetail?.booking_detail
                    ?.map((i) => i.seat)
                    .join(', ') ?? ''}
                </Text>
              </Flex>
            </Grid>
          </Box>
          <HSeparator my={4} />

          <Box overflowX='auto'>
            <Table variant='simple' color={textColor} w='100%'>
              <Thead position='sticky' top={0} bg='white' zIndex={1}>
                <Tr my='.8rem' pl='0px' color='gray.400'>
                  <Th borderColor={borderColor} p='16px 12px'>
                    Channel
                  </Th>
                  <Th borderColor={borderColor} p='16px 12px'>
                    Unit
                  </Th>
                  <Th borderColor={borderColor} p='16px 12px'>
                    Program Applied
                  </Th>
                  <Th borderColor={borderColor} p='16px 12px'>
                    Voucher
                  </Th>
                  <Th borderColor={borderColor} p='16px 12px'>
                    Seat No
                  </Th>
                  <Th borderColor={borderColor} p='16px 12px'>
                    Floor
                  </Th>
                  {bookingDetail?.status !== Cancel &&
                    bookingDetail?.payment_type !== Unpaid && (
                      <Th borderColor={borderColor} p='16px 12px'>
                        Confirmation Code
                      </Th>
                    )}
                  <Th borderColor={borderColor} p='16px 12px'>
                    Official price
                  </Th>
                  <Th borderColor={borderColor} p='16px 12px'>
                    amount
                  </Th>
                  <Th borderColor={borderColor} p='16px 12px'>
                    Seat Status
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {bookingDetail?.booking_detail?.map((i, index) => (
                  <Tr key={index}>
                    <Td borderColor={borderColor} p='16px 12px'>
                      <Text fontSize='xs' color={textColor}>
                        {i?.channel_name}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} p='16px 12px'>
                      <Text fontSize='xs' color={textColor}>
                        {i?.unit_name}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} p='16px 12px'>
                      <Text fontSize='xs' color={textColor}>
                        {i.voucher_code_id > 0
                          ? i.voucher_code.voucher.name
                          : i.rateplan_config.rateplan.name}
                        {i.is_child === 0 ? ' - Adult' : ' - Child'}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} p='16px 12px'>
                      <Text fontSize='xs' color={textColor}>
                        {i.voucher_code_id > 0 ? i.voucher_code.code : ''}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} p='16px 12px'>
                      <Text fontSize='xs' color={textColor}>
                        {i.seat}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} p='16px 12px'>
                      <Text fontSize='xs' color={textColor}>
                        {showTextFloor(i.floor)}
                      </Text>
                    </Td>
                    {bookingDetail?.status !== Cancel &&
                      bookingDetail?.payment_type !== Unpaid && (
                        <Td borderColor={borderColor} p='16px 12px'>
                          <Text fontSize='xs' color={textColor}>
                            {seatStatus(i.status) !== 'Unpaid' && i.code}
                          </Text>
                        </Td>
                      )}
                    <Td borderColor={borderColor} p='16px 12px'>
                      <Text fontSize='xs' color={textColor}>
                        {i.official_price.toLocaleString('en-US')}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} p='16px 12px'>
                      <Text fontSize='xs' color={textColor}>
                        {i.amount.toLocaleString('en-US')}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} p='16px 12px'>
                      <Text fontSize='xs' color={textColor}>
                        {seatStatus(i.status)}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
        <Grid
          templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
          gap='20px'
          mt='20px'
        >
          <Flex direction='column'>
            <Flex style={{ display: 'flex' }}>
              <Text
                fontSize='base'
                fontWeight='bold'
                lineHeight='22px'
                mb='15px'
              >
                Payment Details
              </Text>
              {checkForbidden(46) &&
                isShowPayment &&
                bookingDetail.status !== 2 && (
                  <Button
                    onClick={() => {
                      onOpenAddPaymentModal();
                      setIsCreatePayment(true);
                    }}
                    leftIcon={<IoAdd />}
                    size='xs'
                    colorScheme='blue'
                    variant='outline'
                    style={{ width: '120px', marginLeft: '20px' }}
                  >
                    Add Payment
                  </Button>
                )}
              {bookingDetail?.booking_payment?.length > 4 && (
                <>
                  {showAll ? (
                    <Button
                      onClick={() => {
                        setItemsBookingPayment(bookingDetail.booking_payment);
                        setShowAll(false);
                      }}
                      leftIcon={<ShowAllIcon />}
                      size='xs'
                      colorScheme='blue'
                      variant='ghost'
                      style={{ width: '120px', marginLeft: '20px' }}
                    >
                      Show all
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        const bookingPaymentSlice = bookingDetail?.booking_payment.slice(
                          0,
                          4
                        );
                        setItemsBookingPayment(bookingPaymentSlice);
                        setShowAll(true);
                      }}
                      leftIcon={<ShowAllIcon />}
                      size='xs'
                      colorScheme='blue'
                      variant='ghost'
                      style={{ width: '120px', marginLeft: '20px' }}
                    >
                      Show less
                    </Button>
                  )}
                </>
              )}
            </Flex>

            <Flex border='1px solid #D9D9D9' padding='11px 18px'>
              {bookingDetail?.booking_payment ? (
                <Grid templateColumns='repeat(2, auto)' gap='20px' w='100%'>
                  {itemsBookingPayment &&
                    itemsBookingPayment.map((i, index) => (
                      <Flex
                        direction='column'
                        key={index}
                        gap='20px'
                        className={'booking_payment' + index}
                      >
                        <Flex justify='space-between'>
                          <Text color='black.900' fontWeight={'bold'}>
                            #{index + 1} Amount:
                          </Text>
                          <Text fontSize='base' style={{ fontWeight: 'bold' }}>
                            {i.amount.toLocaleString('en-US')} VND
                          </Text>
                        </Flex>
                        <Flex justify='space-between'>
                          <Text color='black.900' fontSize={'base'}>
                            Status:
                          </Text>
                          <Text fontSize='base' fontWeight={'bold'}>
                            Paid
                          </Text>
                        </Flex>
                        <Flex justify='space-between'>
                          <Text color='black.900' fontSize={'base'}>
                            Method:
                          </Text>
                          <Text fontSize='base' fontWeight={'bold'}>
                            {paymentMethodText(i.payment_method)}
                          </Text>
                        </Flex>
                        <Flex justify='space-between'>
                          <Text color='black.900' fontSize={'base'}>
                            Created by:
                          </Text>
                          <Text fontSize='base' fontWeight={'bold'}>
                            {i.created_by.length === 0
                              ? 'guestbooking'
                              : i.created_by}
                          </Text>
                        </Flex>
                        <Flex justify='space-between'>
                          <Text color='black.900' fontSize={'base'}>
                            Created at:
                          </Text>
                          <Text fontSize='base' fontWeight={'bold'}>
                            {moment(i?.created_at).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                </Grid>
              ) : (
                <Text
                  textAlign='center'
                  color='red'
                  fontSize='base'
                  fontWeight='bold'
                >
                  No Payment
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex direction='column'>
            <Text fontSize='base' fontWeight='bold' lineHeight='22px' mb='15px'>
              Summary
            </Text>
            <Flex border='1px solid #D9D9D9' padding='11px 18px'>
              <Flex direction='column' gap='15px' w='100%'>
                <Flex justify='space-between'>
                  <Text color='black.900'>Total Amount:</Text>
                  <Text fontSize='base' fontWeight='700'>
                    {bookingDetail?.total_amount.toLocaleString('en-US')} VND
                  </Text>
                </Flex>
                {bookingDetail?.payment_type == Unpaid &&
                  bookingDetail?.status == 1 && (
                    <Flex justify='space-between'>
                      <Text color='black.900'>Validity:</Text>
                      <Text fontSize='base' fontWeight='700'>
                        {moment(bookingDetail?.expire_at).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </Text>
                    </Flex>
                  )}

                <Flex justify='space-between'>
                  <Text color='black.900'>Booking Status:</Text>
                  <Text
                    fontSize='base'
                    fontWeight='700'
                    textTransform='uppercase'
                  >
                    {bookingStatus(bookingDetail?.status)}
                  </Text>
                </Flex>
                {bookingDetail?.payment_type === Unpaid && (
                  <>
                    <Flex justify='space-between'>
                      <Text color='black.900'>Paid:</Text>
                      <Text
                        fontSize='base'
                        fontWeight='700'
                        textTransform='uppercase'
                      >
                        {amountPayment?.toLocaleString('en-US')} VND
                      </Text>
                    </Flex>
                    <Flex justify='space-between'>
                      <Text color='black.900'>Remaining:</Text>
                      <Text
                        fontSize='base'
                        fontWeight='700'
                        textTransform='uppercase'
                      >
                        {remainingAmount?.toLocaleString('en-US')} VND
                      </Text>
                    </Flex>
                  </>
                )}
                <Flex justify='space-between'>
                  <Text color='black.900'>Payment Status:</Text>
                  <Text
                    fontSize='base'
                    fontWeight='700'
                    textTransform='uppercase'
                  >
                    {paymentType(bookingDetail?.payment_type)}
                  </Text>
                </Flex>

                <Flex>
                  <Text color='black.900'>Special Request: </Text>
                  <Flex ml={3}>
                    {checkForbidden(47) && (
                      <Flex style={{ display: 'block', textAlign: 'right' }}>
                        <Button
                          onClick={() => {
                            onOpen();
                            setIsCreateSpecialRequest(true);
                          }}
                          leftIcon={<IoAdd />}
                          size='xs'
                          colorScheme='blue'
                          variant='outline'
                        >
                          Add Special Request
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                {bookingDetail?.notes && (
                  <div
                    style={{
                      textAlign: 'left',
                      maxHeight: '400px',
                      overflowY: 'auto',
                      fontWeight: 'bold',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: bookingDetail?.notes,
                    }}
                  ></div>
                )}

                <Flex justify='space-between'>
                  <Text size='sm'>Invoice:</Text>
                  <Text>{bookingDetail?.invoice_response}</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          {bookingDetail?.booking_refund && (
            <Flex direction='column'>
              <Flex style={{ display: 'flex' }}>
                <Text
                  fontSize='base'
                  fontWeight='bold'
                  lineHeight='22px'
                  mb='15px'
                >
                  Cancel Payment Details
                </Text>
                {bookingDetail?.booking_refund.length > 4 && (
                  <>
                    {showAllRefund ? (
                      <Button
                        onClick={() => {
                          setItemsBookingPaymentRefund(
                            bookingDetail.booking_refund
                          );
                          setShowAllRefund(false);
                        }}
                        leftIcon={<ShowAllIcon />}
                        size='xs'
                        colorScheme='blue'
                        variant='ghost'
                        style={{ width: '120px', marginLeft: '20px' }}
                      >
                        Show all
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          const bookingPaymentSlice = bookingDetail?.booking_refund.slice(
                            0,
                            4
                          );
                          setItemsBookingPaymentRefund(bookingPaymentSlice);
                          setShowAllRefund(true);
                        }}
                        leftIcon={<ShowAllIcon />}
                        size='xs'
                        colorScheme='blue'
                        variant='ghost'
                        style={{ width: '120px', marginLeft: '20px' }}
                      >
                        Show less
                      </Button>
                    )}
                  </>
                )}
              </Flex>
              <Flex border='1px solid #D9D9D9' padding='11px 18px'>
                <Grid templateColumns='repeat(2, auto)' gap='20px' w='100%'>
                  {itemsBookingPaymentRefund &&
                    itemsBookingPaymentRefund.map((i, index) => (
                      <Flex direction='column' key={index} gap='20px'>
                        <Flex justify='space-between'>
                          <Text color='black.900'>Cancelled Type:</Text>
                          <Text fontSize='base' style={{ fontWeight: 'bold' }}>
                            {cancelledTypeText(i?.type)}
                          </Text>
                        </Flex>
                        <Flex justify='space-between'>
                          <Text color='black.900' fontSize={'base'}>
                            Refund/Charge Amount:
                          </Text>
                          <Text fontSize='base' fontWeight={'bold'}>
                            {i?.amount.toLocaleString('en-US')} VND
                          </Text>
                        </Flex>
                        <Flex justify='space-between'>
                          <Text color='black.900' fontSize={'base'}>
                            Cancelled Payment Method:
                          </Text>
                          <Text fontSize='base' fontWeight={'bold'}>
                            {paymentMethodText(i?.refund_method)}
                          </Text>
                        </Flex>
                        <Flex justify='space-between'>
                          <Text color='black.900' fontSize={'base'}>
                            Cancelled Note:
                          </Text>
                          <Text fontSize='base' fontWeight={'bold'}>
                            {i?.notes}
                          </Text>
                        </Flex>
                        <Flex justify='space-between'>
                          <Text color='black.900' fontSize={'base'}>
                            Cancelled by:
                          </Text>
                          <Text fontSize='base' fontWeight={'bold'}>
                            {i?.created_by}
                          </Text>
                        </Flex>
                        <Flex justify='space-between'>
                          <Text color='black.900' fontSize={'base'}>
                            Cancelled date:
                          </Text>
                          <Text fontSize='base' fontWeight={'bold'}>
                            {moment(i?.created_at).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                </Grid>
              </Flex>
            </Flex>
          )}
          <Flex direction='column'>
            <Flex align='center' mb='15px'>
              <Text fontSize='base' fontWeight='bold' lineHeight='22px'>
                Booker Information
              </Text>
              {checkForbidden(9) && (
                <Flex
                  align='center'
                  ml='20px'
                  onClick={() => setEditBooker(!editBooker)}
                  cursor='pointer'
                >
                  <EditIcon />
                  <Text ml={1} fontWeight='bold'>
                    EDIT
                  </Text>
                </Flex>
              )}
            </Flex>
            <Flex
              p='10px 20px'
              bg='#F8F9FA'
              borderRadius='12px'
              direction='column'
              gap='15px'
            >
              {editBooker ? (
                <>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Full Name:</Text>
                    <Input
                      bg={'white'}
                      size='sm'
                      maxW={280}
                      fontWeight={'bold'}
                      value={bookerandguestInfo.booker_name}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          booker_name: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Phone:</Text>
                    <Input
                      fontWeight={'bold'}
                      bg={'white'}
                      size='sm'
                      maxW={280}
                      value={bookerandguestInfo.booker_phone}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          booker_phone: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Email:</Text>
                    <FormControl maxW={280} isInvalid={errorMessage}>
                      <Input
                        fontWeight={'bold'}
                        bg={'white'}
                        size='sm'
                        value={bookerandguestInfo.booker_email}
                        onChange={(val) => {
                          if (!validateEmail(val.target.value)) {
                            setErrorMessage('Invalid email address.');
                          } else {
                            setErrorMessage('');
                          }
                          setBookerandguestInfo((preState) => ({
                            ...preState,
                            booker_email: val.target.value,
                          }));
                        }}
                      />
                      <FormErrorMessage>{errorMessage}</FormErrorMessage>
                    </FormControl>
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Address 1:</Text>
                    <Input
                      fontWeight={'bold'}
                      bg={'white'}
                      size='sm'
                      maxW={280}
                      value={bookerandguestInfo.booker_address}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          booker_address: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Address 2:</Text>
                    <Input
                      bg={'white'}
                      fontWeight={'bold'}
                      size='sm'
                      maxW={280}
                      value={bookerandguestInfo.booker_address2}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          booker_address2: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>City/State:</Text>
                    <Input
                      bg={'white'}
                      fontWeight={'bold'}
                      size='sm'
                      maxW={280}
                      value={bookerandguestInfo.booker_city}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          booker_city: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Post Code:</Text>
                    <Input
                      bg={'white'}
                      fontWeight={'bold'}
                      size='sm'
                      maxW={280}
                      value={bookerandguestInfo.booker_code}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          booker_code: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Country:</Text>
                    <ReactSelect
                      options={countries}
                      getOptionLabel={(i) => i.name}
                      getOptionValue={(option) => option.name}
                      chakraStyles={chakraStyles}
                      size='sm'
                      closeMenuOnSelect={true}
                      focusBorderColor='blue.500'
                      className='chakra-select__input'
                      value={{
                        id: bookerandguestInfo.booker_country,
                        name: bookerandguestInfo.booker_country,
                      }}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          booker_country: val.name,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Company:</Text>
                    <Input
                      bg={'white'}
                      fontWeight={'bold'}
                      size='sm'
                      maxW={280}
                      value={bookerandguestInfo.booker_company}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          booker_company: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <Flex
                    align='center'
                    justify='center'
                    onClick={() => updateBookingInfoBookerandGuest('booker')}
                  >
                    <Button colorScheme='blue'>Update</Button>
                  </Flex>
                </>
              ) : (
                <>
                  <Text>
                    Full Name:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.booker_name}
                    </span>
                  </Text>
                  <Text>
                    Phone:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.booker_phone}
                    </span>
                  </Text>
                  <Text>
                    Email:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.booker_email}
                    </span>
                  </Text>
                  <Text>
                    Address 1:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.booker_address}
                    </span>
                  </Text>
                  <Text>
                    Address 2:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.booker_address2}
                    </span>
                  </Text>
                  <Text>
                    City/State:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.booker_city}
                    </span>
                  </Text>
                  <Text>
                    Post Code:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.booker_code}
                    </span>
                  </Text>
                  <Text>
                    Country:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.booker_country}
                    </span>
                  </Text>
                  <Text>
                    Company:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.booker_company}
                    </span>
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
          <Flex direction='column'>
            <Flex align='center' mb='15px'>
              <Text fontSize='base' fontWeight='bold' lineHeight='22px'>
                Audience Information
              </Text>

              <Flex
                align='center'
                ml='20px'
                onClick={() => setEditGuest(!editGuest)}
                cursor='pointer'
              >
                <EditIcon />
                <Text ml={1} fontWeight='bold'>
                  EDIT
                </Text>
              </Flex>
            </Flex>
            <Flex
              p='10px 20px'
              bg='#F8F9FA'
              borderRadius='12px'
              direction='column'
              gap={'15px'}
            >
              {editGuest ? (
                <>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Full Name:</Text>
                    <Input
                      bg={'white'}
                      size='sm'
                      fontWeight={'bold'}
                      maxW={280}
                      value={bookerandguestInfo.guest_name}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          guest_name: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Phone:</Text>
                    <Input
                      bg={'white'}
                      fontWeight={'bold'}
                      size='sm'
                      maxW={280}
                      value={bookerandguestInfo.guest_phone}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          guest_phone: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Email:</Text>
                    <Input
                      fontWeight={'bold'}
                      bg={'white'}
                      size='sm'
                      maxW={280}
                      value={bookerandguestInfo.guest_email}
                      onChange={(val) => {
                        if (!validateEmail(val.target.value)) {
                          setErrorMessage('Invalid email address.');
                        } else {
                          setErrorMessage('');
                        }
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          guest_email: val.target.value,
                        }));
                      }}
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Address 1:</Text>
                    <Input
                      bg={'white'}
                      size='sm'
                      fontWeight={'bold'}
                      maxW={280}
                      value={bookerandguestInfo.guest_address}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          guest_address: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Address 2:</Text>
                    <Input
                      bg={'white'}
                      size='sm'
                      fontWeight={'bold'}
                      maxW={280}
                      value={bookerandguestInfo.guest_address2}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          guest_address2: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>City/State:</Text>
                    <Input
                      bg={'white'}
                      fontWeight={'bold'}
                      size='sm'
                      maxW={280}
                      value={bookerandguestInfo.guest_city}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          guest_city: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Post Code:</Text>
                    <Input
                      bg={'white'}
                      size='sm'
                      fontWeight={'bold'}
                      maxW={280}
                      value={bookerandguestInfo.guest_code}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          guest_code: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Country:</Text>
                    <ReactSelect
                      options={countries}
                      getOptionLabel={(i) => i.name}
                      getOptionValue={(option) => option.name}
                      chakraStyles={chakraStyles}
                      size='sm'
                      closeMenuOnSelect={true}
                      focusBorderColor='blue.500'
                      className='chakra-select__input'
                      value={{
                        id: bookerandguestInfo.guest_country,
                        name: bookerandguestInfo.guest_country,
                      }}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          guest_country: val.name,
                        }))
                      }
                    />
                  </HStack>
                  <HStack spacing='24px'>
                    <Text w={'80px'}>Note:</Text>
                    <Input
                      fontWeight={'bold'}
                      bg={'white'}
                      size='sm'
                      maxW={280}
                      value={bookerandguestInfo.guest_note}
                      onChange={(val) =>
                        setBookerandguestInfo((preState) => ({
                          ...preState,
                          guest_note: val.target.value,
                        }))
                      }
                    />
                  </HStack>
                  <Flex
                    align='center'
                    justify='center'
                    onClick={() => updateBookingInfoBookerandGuest('guest')}
                  >
                    <Button colorScheme='blue'>Update</Button>
                  </Flex>
                </>
              ) : (
                <>
                  <Text>
                    Full Name:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.guest_name}
                    </span>
                  </Text>
                  <Text>
                    Phone:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.guest_phone}
                    </span>
                  </Text>
                  <Text>
                    Email:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.guest_email}
                    </span>
                  </Text>
                  <Text>
                    Address 1:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.guest_address}
                    </span>
                  </Text>
                  <Text>
                    Address 2:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.guest_address2}
                    </span>
                  </Text>
                  <Text>
                    City/State:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.guest_city}
                    </span>
                  </Text>
                  <Text>
                    Post Code:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.guest_code}
                    </span>
                  </Text>
                  <Text>
                    Country:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.guest_country}
                    </span>
                  </Text>
                  <Text>
                    Note:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {bookingDetail?.guest_note}
                    </span>
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
        </Grid>
      </CardBody>
    </Card>
  );
}
