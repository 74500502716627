import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchTable2 from 'components/Tables/SearchTable2';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { totalRow } from 'state/roles-permissions';
import { AddIcon } from '@chakra-ui/icons';
import { checkForbidden } from 'helpers/checkForbidden';
import { useCMSActions } from 'hooks/useCMS';
import { bannerAtom } from 'state/cms';
import { columnsDataBanner } from 'variables/columnsData';
import { useHistory } from 'react-router-dom';

function DataTableBanner() {
  const history = useHistory();

  const textColor = useColorModeValue('gray.700', 'white');
  const listLocationWeb = useRecoilValue(bannerAtom);
  const totalRows = useRecoilValue(totalRow);
  const cmsActions = useCMSActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentVenueWeb, setCurrentVenueWeb] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newVenueWeb, setNewVenueWeb] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );

  const { errors, isSubmitting } = formState;
  const toast = useToast();
  useEffect(() => {
    if (listLocationWeb) {
      const toolTypes = listLocationWeb.map((i) => ({
        ...i,
        typeSwitch: 'banner',
      }));
      setNewVenueWeb(toolTypes);
    }
  }, [listLocationWeb]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListBanner(
      {},
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({ name, key, id, isActive }) {
    const params = {
      name,
      id,
      key,
      status: isActive ? 1 : 2,
    };
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createBanner(params);
      } else {
        res = await cmsActions.updateBanner(params, currentVenueWeb);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? 'Create Banner success'
          : 'Update Banner success';
        toast({
          description: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        await cmsActions.getListBanner(
          {
            name: '',
          },
          0,
          localStorage && localStorage.getItem('currentPageSizeStore')
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }

  async function editNews(newsID) {
    const newsData = await cmsActions.getBannerDetail(newsID);
    if (newsData) {
      const { name, status, id, key } = newsData.data.data;
      setValue('name', name);
      setValue('key', key);
      setValue('isActive', status === 1 ? true : false);
      setCurrentVenueWeb(id);
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Banner Management
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(42) && (
              <Button
                size='sm'
                onClick={() => {
                  onOpen();
                  setIsCreate(true);
                }}
                leftIcon={<AddIcon />}
                colorScheme='blue'
                variant='outline'
              >
                Add Banner Group
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              reset();
            }}
            size='xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader>
                  {isCreate ? 'Add Banner Group' : 'Edit Banner Group'}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <FormControl isRequired mb={2}>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Name
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Name'
                      maxLength={250}
                      size='md'
                      name='name'
                      {...register('name')}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Key
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Key'
                      maxLength={250}
                      size='md'
                      name='key'
                      {...register('key')}
                    />
                  </FormControl>
                  <FormControl>
                    <Switch
                      id='isChecked'
                      defaultChecked={isCreate && true}
                      name='isActive'
                      {...register('isActive')}
                      size='sm'
                      ms='4px'
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    {isCreate ? 'Create' : 'Update'}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <SearchTable2
          tableData={newVenueWeb}
          columnsData={columnsDataBanner}
          totalRow={totalRows.banner}
          edit={editNews}
          actionsActive={['edit', 'detail']}
          showDetail={(id) =>
            history.push(`/admin/web-management/banner-detail/${id}`)
          }
          listPermissions={[43]}
          isFetching={isFetching}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableBanner;
