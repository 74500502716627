import {
  DocumentIcon,
  PersonIcon,
  StatsIcon,
  CartIcon,
  SupportIcon
} from "components/Icons/Icons";
import { CalendarIcon, SettingsIcon } from "@chakra-ui/icons";
import Calendar from "views/Applications/Calendar";
import DataTablesUser from "views/Applications/DataTableUser";
import DataTableIssueVoucher from "views/Applications/DataTableIssueVoucher";
import DataTableRole from "views/Applications/DataTableRole";
import DataTableRatePlan from "views/Applications/DataTableRatePlan";
import DataTableVoucherCode from "views/Applications/DataTableVoucherCode";
import SignInCover from "views/Authentication/SignIn/SignInCover.js";
import ResetCover from "views/Authentication/Reset/ResetCover.js";
import Settings from "views/Pages/Account/Settings.js";
import UserDetail from "views/Pages/Account/UserDetail.js";
import BookingInformation from "views/Pages/Profile/BookingInformation.js";
import BookingPayment from "views/Pages/Profile/BookingPayment.js";
import BookingDetail from "views/Pages/Profile/BookingDetail";
import BookingTheaterPass from "views/Pages/Profile/BookingTheaterPass";
import DataTableBooking from "views/Applications/DataTableBooking";
import NotFound from "layouts/NotFound";
import { useRecoilValue } from "recoil";
import { authAtom } from "state/auth";
import DataTableUnits from "views/Applications/DataTableUnits";
import DataTableChannels from "views/Applications/DataTableChannels";
import DataTableShowTime from "views/Applications/DataTableShowTime";
import DataTableToolType from "views/Applications/DataTableToolType";
import DataTablePaymentMethod from "views/Applications/DataTablePaymentMethod";
import DataTablePaymentStatus from "views/Applications/DataTablePaymentStatus";
import DataTableCurrency from "views/Applications/DataTableCurrency";
import DataTableTimeToRelease from "views/Applications/DataTableTimeToRelease";
import ReleaseTime from "views/Applications/ReleaseTime";
import DataTableZones from "views/Applications/DataTableZones";
import DataTableTicketPrice from "views/Applications/DataTableTicketPrice";
import DataTableVenue from "views/Applications/DataTableVenue";
import DataTableShow from "views/Applications/DataTableShow";
import DataTableLocation from "views/Applications/DataTableLocation";
import DataTableShowSchedule from "views/Applications/DataTableShowSchedule";
import DataTableSubAccounts from "views/Applications/DataTableSubAccounts";
import RecoveryPassword from "views/Authentication/RecoveryPassword/RecoveryPassword";
import ScanOPD from "views/Pages/Profile/ScanOPD";
import DataTableSeatingPlan from "views/Applications/DataTableSeatingPlan";
import DataTableAssignSeatingPlan from "views/Applications/DataTableAssignSeatingPlan";
import DataTableShowScheduleDetail from "views/Applications/DataTableShowScheduleDetail";
import DataTableAssignSeatingPlanShowSchedule from "views/Applications/DataTableAssignSeatingPlanShowSchedule";
import DataTableInvoice from "views/Applications/DataTableInvoice";
import DataTableTheaterPassReport from "views/Applications/DataTableTheaterPassReport";
import DataTableTicketboxReport from "views/Applications/DataTableTicketboxReport";
import DataTableShowReport from "views/Applications/DataTableShowReport";
import DataTableVoucherTrackingReport from "views/Applications/DataTableVoucherTrackingReport";
import DataTableCommissionExtraCostReport from "views/Applications/DataTableCommissionExtraCostReport";
import DataTableCheckPaymentReport from "views/Applications/DataTableCheckPaymentReport";
import DataTableScanTheaterPassReport from "views/Applications/DataTableScanTheaterPassReport";
import DataTableTemplateVoucher from "views/Applications/DataTableTemplateVoucher";

import { TbReportSearch } from "react-icons/tb";
import DataTableNews from "views/CMS/DataTableNews";
import DataTableNewsGroup from "views/CMS/DataTableNewsGroup";
import DataTablePartner from "views/CMS/DataTablePartner";
import DataTableOffers from "views/CMS/DataTableOffers";
import DataTableVenueWeb from "views/CMS/DataTableVenueWeb";
import DataTableShowWeb from "views/CMS/DataTableShowWeb";
import DataTableLocationWeb from "views/CMS/DataTableLocationWeb";
import DataTableOffersGroup from "views/CMS/DataTableOffersGroup";
import DataTableMenu from "views/CMS/DataTableMenu";
import DataTableRecruitment from "views/CMS/DataTableRecruitments";
import DataTableFAQ from "views/CMS/DataTableFAQ";
import DataTableFAQGroup from "views/CMS/DataTableFAQGroup";
import DataTableBanner from "views/CMS/DataTableBanner";
import DataTableBannerImages from "views/CMS/DataTableBannerImages";
import DataTableShowReview from "views/CMS/DataTableShowReview";
import DataTableShowKeyFeature from "views/CMS/DataTableShowKeyFeature";
import DataTableTarget from "views/Applications/DataTableTarget";

function routesArray() {
  const auth = useRecoilValue(authAtom);
  const permissions = auth?.permission;
  return [
    {
      name: "Booking",
      path: "/booking",
      icon: <CalendarIcon color="inherit" />,
      collapse: true,
      notShowNavBar: !permissions?.includes(6),
      items: [
        {
          name: "Create New Booking",
          component: Calendar,
          authIcon: <DocumentIcon color="inherit" />,
          path: "/booking/create",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(7),
          detail: "create"
        },
        {
          name: "Booking Information",
          path: "/booking/create/information",
          component: BookingInformation,
          layout: "/admin",
          notShowNavBar: true,
          detail: "information"
        },
        {
          name: "Booking Payment",
          path: "/booking/create/payment",
          component: BookingPayment,
          layout: "/admin",
          notShowNavBar: true,
          detail: "payment"
        },
        {
          name: "Booking Detail",
          path: "/booking/create/detail/:booking_id",
          component: BookingDetail,
          layout: "/admin",
          notShowNavBar: true,
          detail: "detail"
        },
        {
          name: "Booking Code",
          path: "/booking/create/print-theater-pass/:booking_id",
          component: BookingTheaterPass,
          layout: "/admin",
          notShowNavBar: true,
          detail: "print-theater-pass"
        },
        {
          name: "Booking List",
          component: DataTableBooking,
          path: "/booking/listbooking",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(45),
          detail: "listbooking"
        },
        {
          name: "Scan Theater Pass",
          path: "/booking/bookingscan",
          component: ScanOPD,
          layout: "/admin",
          notShowNavBar: !permissions?.includes(45),
          detail: "bookingscan"
        },
        {
          name: "Theater Pass Report",
          path: "/theaterpass/monthly",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTablesUser,
          layout: "/admin",
          notShowNavBar: true,
          detail: "monthly"
        },
      ],
    },
    {
      name: "Report",
      path: "/report",
      icon: <TbReportSearch color="inherit" />,
      collapse: true,
      items: [
        {
          name: "Invoice Report",
          path: "/report/invoice-report",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableInvoice,
          layout: "/admin",
        },
        {
          name: "Theater Pass Report",
          path: "/report/theater-pass-report",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableTheaterPassReport,
          layout: "/admin",
        },
        {
          name: "Daily Ticketbox Report",
          path: "/report/ticketbox-report",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableTicketboxReport,
          layout: "/admin",
        },
        {
          name: "Show Report",
          path: "/report/show",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableShowReport,
          layout: "/admin",
        },
        {
          name: "Voucher Tracking Report",
          path: "/report/voucher-tracking-report",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableVoucherTrackingReport,
          layout: "/admin",
        },
        {
          name: "Commission - Extra Cost Report",
          path: "/report/commission-extra-cost-report",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableCommissionExtraCostReport,
          layout: "/admin",
        },
        {
          name: "Check Payment Report",
          path: "/report/check-payment-report",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableCheckPaymentReport,
          layout: "/admin",
        },
        {
          name: "Scan Theater Pass Report",
          path: "/report/scan-theater-pass-report",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableScanTheaterPassReport,
          layout: "/admin",
        }
      ],
    },
    {
      name: "Management",
      path: "/management",
      icon: <StatsIcon color="inherit" />,
      collapse: true,
      notShowNavBar: !permissions?.includes(10),
      items: [
        {
          name: "Rate Plan",
          component: DataTableRatePlan,
          authIcon: <DocumentIcon color="inherit" />,
          path: "/management/rate-plan",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(31),
        },
        {
          name: "Units",
          component: DataTableUnits,
          authIcon: <CartIcon color="inherit" />,
          path: "/management/units",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(110),
        },
        {
          name: "Channels",
          component: DataTableChannels,
          authIcon: <CartIcon color="inherit" />,
          path: "/management/channels",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(49),
        },
        {
          name: "Zones",
          path: "/management/zones",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableZones,
          layout: "/admin",
          notShowNavBar: !permissions?.includes(122),
        },
        {
          name: "Ticket Price",
          component: DataTableTicketPrice,
          authIcon: <StatsIcon color="inherit" />,
          path: "/management/ticket-price",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(102),
        },
        {
          name: "Venue",
          component: DataTableVenue,
          authIcon: <CartIcon color="inherit" />,
          path: "/management/venue",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(114),
        },
        {
          name: "Show",
          path: "/management/show",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableShow,
          layout: "/admin",
          notShowNavBar: !permissions?.includes(86),
        },
        {
          name: "Location",
          component: DataTableLocation,
          authIcon: <StatsIcon color="inherit" />,
          path: "/management/location",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(65),
        },
        {
          name: "Show Schedule",
          component: DataTableShowSchedule,
          authIcon: <StatsIcon color="inherit" />,
          path: "/management/schedule-show",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(90),
        },
        {
          name: "Show Schedule Detail",
          component: DataTableShowScheduleDetail,
          authIcon: <StatsIcon color="inherit" />,
          path: "/management/schedule-show/detail/:schedule_id",
          layout: "/admin",
          notShowNavBar: true,
        },
        {
          name: "Assign Seating Plan Show Schedule",
          component: DataTableAssignSeatingPlanShowSchedule,
          authIcon: <StatsIcon color="inherit" />,
          path: "/management/schedule-show/assign/:seatingPlan_id",
          layout: "/admin",
          notShowNavBar: true,
        },
        {
          name: "Sub Account",
          component: DataTableSubAccounts,
          authIcon: <StatsIcon color="inherit" />,
          path: "/management/sub-account",
          layout: "/admin",
          notShowNavBar: true,
        },
        {
          name: "Voucher",
          component: DataTableIssueVoucher,
          authIcon: <StatsIcon color="inherit" />,
          path: "/management/voucher",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(30),
        },
        {
          name: "Template Voucher",
          component: DataTableTemplateVoucher,
          authIcon: <StatsIcon color="inherit" />,
          path: "/management/template-voucher",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(130),
        },
        {
          name: "Issue Voucher Detail",
          component: DataTableVoucherCode,
          authIcon: <StatsIcon color="inherit" />,
          path: "/management/voucher/voucher-detail/:voucher_id",
          layout: "/admin",
          notShowNavBar: true,
        },
        {
          name: "Target",
          component: DataTableTarget,
          authIcon: <StatsIcon color="inherit" />,
          path: "/management/target",
          layout: "/admin",
        },
      ],
    },
    {
      name: "Web Management",
      path: "/web-management",
      icon: <SupportIcon color="inherit" />,
      collapse: true,
      notShowNavBar: !permissions?.includes(10),
      items: [
        {
          name: "News",
          path: "/web-management/news",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableNews,
          layout: "/admin",
        },
        {
          name: "News Group",
          component: DataTableNewsGroup,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/new-groups",
          layout: "/admin",
        },
        {
          name: "Menus",
          component: DataTableMenu,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/menus",
          layout: "/admin",
        },
        {
          name: "Shows",
          component: DataTableShowWeb,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/shows",
          layout: "/admin",
        },
        {
          name: "Shows Review",
          component: DataTableShowReview,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/reviews",
          layout: "/admin",
        },
        {
          name: "Shows Key Feature",
          component: DataTableShowKeyFeature,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/key-feature",
          layout: "/admin",
        },
        {
          name: "Recruitment",
          component: DataTableRecruitment,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/recruitment",
          layout: "/admin",
        },
        {
          name: "Partners",
          component: DataTablePartner,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/partners",
          layout: "/admin",
        },
        {
          name: "Venues",
          component: DataTableVenueWeb,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/venues",
          layout: "/admin",
        },
        {
          name: "Offers",
          component: DataTableOffers,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/offers",
          layout: "/admin",
        },
        {
          name: "Locations",
          component: DataTableLocationWeb,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/locations",
          layout: "/admin",
        },
        {
          name: "Offers Group",
          component: DataTableOffersGroup,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/group-offers",
          layout: "/admin",
        },
        {
          name: "FAQ",
          component: DataTableFAQ,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/faq",
          layout: "/admin",
        },
        {
          name: "FAQ Group",
          component: DataTableFAQGroup,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/groups-faq",
          layout: "/admin",
        },
        {
          name: "Banner",
          component: DataTableBanner,
          authIcon: <StatsIcon color="inherit" />,
          path: "/web-management/banner",
          layout: "/admin",
        },
        {
          name: "Banner Detail",
          component: DataTableBannerImages,
          path: "/web-management/banner-detail/:banner_id",
          layout: "/admin",
          notShowNavBar: true,
        }
      ],
    },
    {
      name: "Setting",
      path: "/setting",
      icon: <SettingsIcon color="inherit" />,
      collapse: true,
      items: [
        {
          name: "Profile",
          component: Settings,
          authIcon: <DocumentIcon color="inherit" />,
          path: "/setting/profile",
          layout: "/admin",
        },
        {
          name: "User",
          component: DataTablesUser,
          authIcon: <DocumentIcon color="inherit" />,
          path: "/setting/user",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(37),
        },
        {
          name: "User Detail",
          component: UserDetail,
          path: "/setting/user-detail/:user_id",
          layout: "/admin",
          notShowNavBar: true,
        },
        {
          name: "Seating Plan",
          component: DataTableSeatingPlan,
          authIcon: <StatsIcon color="inherit" />,
          path: "/setting/seatingplan",
          layout: "/admin",
        },
        {
          name: "Assign seat quota for unit",
          component: DataTableAssignSeatingPlan,
          authIcon: <StatsIcon color="inherit" />,
          path: "/setting/seatingplan/assign/:seatingPlan_id",
          layout: "/admin",
          notShowNavBar: true,
        },
        {
          name: "Role/Permission",
          component: DataTableRole,
          authIcon: <DocumentIcon color="inherit" />,
          path: "/setting/role",
          layout: "/admin",
          notShowNavBar: !permissions?.includes(41),
        },
        {
          name: "Forgot Password",
          component: ResetCover,
          path: "/authentication/forgot-password",
          layout: "/auth",
          notShowNavBar: true,
        },
        {
          name: "Forgot Password",
          component: RecoveryPassword,
          path: "/authentication/change-password",
          layout: "/auth",
          notShowNavBar: true,
        },
        {
          name: "Show Time",
          component: DataTableShowTime,
          authIcon: <StatsIcon color="inherit" />,
          path: "/setting/show-time",
          layout: "/admin",
          notShowNavBar: false,
        },
        {
          name: "Tool Type",
          component: DataTableToolType,
          authIcon: <CartIcon color="inherit" />,
          path: "/setting/tool-type",
          layout: "/admin",
          notShowNavBar: false,
        },
        {
          name: "Payment Method",
          path: "/setting/payment-method",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTablePaymentMethod,
          layout: "/admin",
          notShowNavBar: false,
        },
        {
          name: "Payment Status",
          component: DataTablePaymentStatus,
          authIcon: <StatsIcon color="inherit" />,
          path: "/setting/payment-status",
          layout: "/admin",
          notShowNavBar: false,
        },
        {
          name: "Currency",
          path: "/setting/currency",
          authIcon: <PersonIcon color="inherit" />,
          component: DataTableCurrency,
          layout: "/admin",
          notShowNavBar: false,
        },
        {
          name: "Time to Release",
          component: DataTableTimeToRelease,
          authIcon: <StatsIcon color="inherit" />,
          path: "/setting/time-to-release",
          layout: "/admin",
          notShowNavBar: true,
        },
        {
          name: "Release Time",
          component: ReleaseTime,
          authIcon: <StatsIcon color="inherit" />,
          path: "/setting/release-time",
          layout: "/admin",
          notShowNavBar: true,
        },
      ],
    },
    {
      name: "Unauthorized",
      path: "/unauthorized",
      collapse: true,
      icon: <CalendarIcon color="inherit" />,
      notShowNavBar: true,
      items: [
        {
          name: "Unauthorized",
          component: NotFound,
          path: "/unauthorized/403",
          layout: "/auth",
          notShowNavBar: true,
        },
      ],
    },
    {
      name: "Authentication",
      path: "/authentication",
      icon: <PersonIcon color="inherit" />,
      collapse: true,
      notShowNavBar: true,
      items: [
        {
          name: "Sign In",
          path: "/authentication/sign-in",
          collapse: true,
          authIcon: <DocumentIcon color="inherit" />,
          items: [
            {
              name: "Cover",
              component: SignInCover,
              path: "/authentication/sign-in",
              layout: "/auth",
            },
          ],
        },
      ],
    },
  ];
}

export default routesArray;
