import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Grid,
  Image,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchTable2 from 'components/Tables/SearchTable2';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { totalRow } from 'state/roles-permissions';
import { AddIcon } from '@chakra-ui/icons';
import { checkForbidden } from 'helpers/checkForbidden';
import { useCMSActions } from 'hooks/useCMS';
import Editor from 'components/Editor/Editor2';
import { useRatePlanActions } from 'hooks/useRatePlanActions';
import { offersGroupAtom } from 'state/cms';
import { columnsDataOffersGroup } from 'variables/columnsData';

function DataTableOffersGroup() {
  const textColor = useColorModeValue('gray.700', 'white');
  const listPartner = useRecoilValue(offersGroupAtom);
  const totalRows = useRecoilValue(totalRow);
  const cmsActions = useCMSActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentNewsGroupID, setCurrentNewsGroupID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListPartner, setNewListPartner] = useState([]);
  const [previewLogo, setPreviewLogo] = useState('');
  const ratePlanActions = useRatePlanActions();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const thumbnailURL = watch('thumbnail_url');

  const toast = useToast();
  useEffect(async () => {
    if (thumbnailURL && thumbnailURL?.length > 0) {
      const formData = new FormData();
      formData.append('file', thumbnailURL[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewLogo(data.url);
    }
  }, [thumbnailURL]);
  useEffect(() => {
    if (listPartner) {
      const partners = listPartner.map((i) => ({
        ...i,
        filters: { name: '', status: 1 },
        typeSwitch: 'offersGroup',
      }));
      setNewListPartner(partners);
    }
  }, [listPartner]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListOfferGroups(
      {
        name: '',
        status: 1,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({ name, description, id, status, isActive }) {
    const params = {
      name,
      thumbnail_url: previewLogo,
      description,
      id,
      status,
      status: isActive ? 1 : 2,
    };
    const listKeys = ['name'];
    const languages = listKeys
      .map((i) => {
        if (watch(`${i}_vn`)) {
          return {
            code: 'vi',
            locale_key: i,
            locale_value: watch(`${i}_vn`),
          };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    params.languages = languages;
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createOfferGroups(params);
      } else {
        res = await cmsActions.updateOfferGroups(params, currentNewsGroupID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? 'Create Offers Group success'
          : 'Update Offers Group success';
        toast({
          description: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        await cmsActions.getListOfferGroups(
          {
            name: '',
            status: 1,
          },
          0,
          localStorage && localStorage.getItem('currentPageSizeStore')
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }

  async function editNews(partnerID) {
    const partnerData = await cmsActions.getDetailOfferGroups(partnerID);
    if (partnerData) {
      const {
        name,
        thumbnail_url,
        description,
        id,
        status,
        languages,
      } = partnerData.data.data;
      setValue('name', name);
      setPreviewLogo(thumbnail_url);
      setValue('description', description);
      setValue('isActive', status === 1 ? true : false);
      setCurrentNewsGroupID(id);
      if (languages) {
        languages.forEach((e) => {
          setValue(`${e.locale_key}_vn`, e.locale_value);
        });
      }
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Offer Group Management
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(42) && (
              <Button
                size='sm'
                onClick={() => {
                  onOpen();
                  setIsCreate(true);
                }}
                leftIcon={<AddIcon />}
                colorScheme='blue'
                variant='outline'
              >
                Add Offer Group
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              reset();
            }}
            size='4xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader>
                  {isCreate ? 'Add Offer Group' : 'Edit Offer Group'}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Tabs isFitted variant='enclosed'>
                    <TabList>
                      <Tab
                        _focus={{ outline: 'none' }}
                        _selected={{ color: 'white', bg: 'blue.500' }}
                        fontWeight={'bold'}
                        fontSize={'18px'}
                      >
                        English
                      </Tab>
                      <Tab
                        _focus={{ outline: 'none' }}
                        _selected={{ color: 'white', bg: 'blue.500' }}
                        fontWeight={'bold'}
                        fontSize={'18px'}
                      >
                        Viet Nam
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <FormControl isRequired>
                          <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                            Name
                          </FormLabel>
                          <Input
                            fontSize='sm'
                            ms='4px'
                            type='text'
                            placeholder='Name'
                            maxLength={250}
                            size='md'
                            name='name'
                            {...register('name')}
                          />
                        </FormControl>
                        <FormControl mt={3}>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Description
                          </FormLabel>
                          <Editor name='description' control={control} />
                        </FormControl>
                        <Grid
                          templateColumns={{
                            sm: '1fr 1fr',
                            lg: 'repeat(2, 1fr)',
                          }}
                          gap='30px'
                          mt={2}
                        >
                          <FormControl>
                            <FormLabel
                              color={textColor}
                              fontWeight='bold'
                              fontSize='xs'
                            >
                              Thumbnail
                            </FormLabel>
                            <Input
                              fontSize='sm'
                              ms='4px'
                              type='file'
                              placeholder='Thumbnail'
                              size='sm'
                              name='thumbnail_url'
                              {...register('thumbnail_url')}
                            />
                            {previewLogo && <Image src={previewLogo} />}
                          </FormControl>
                        </Grid>
                      </TabPanel>
                      <TabPanel>
                        <FormControl>
                          <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                            Name
                          </FormLabel>
                          <Input
                            fontSize='sm'
                            ms='4px'
                            type='text'
                            placeholder='Name'
                            maxLength={250}
                            size='md'
                            name='name_vn'
                            {...register('name_vn')}
                          />
                        </FormControl>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  <FormControl>
                    <Switch
                      id='isChecked'
                      defaultChecked={isCreate && true}
                      name='isActive'
                      {...register('isActive')}
                      size='sm'
                      ms='4px'
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    {isCreate ? 'Create' : 'Update'}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <SearchTable2
          tableData={newListPartner}
          columnsData={columnsDataOffersGroup}
          totalRow={totalRows.offersGroup}
          edit={editNews}
          actionsActive={['edit']}
          listPermissions={[43]}
          isFetching={isFetching}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableOffersGroup;
