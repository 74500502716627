import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Grid,
  Select,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchTable2 from 'components/Tables/SearchTable2';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { totalRow } from 'state/roles-permissions';
import { AddIcon } from '@chakra-ui/icons';
import { checkForbidden } from 'helpers/checkForbidden';
import { useCMSActions } from 'hooks/useCMS';
import { menuAtom } from 'state/cms';
import { columnsDataMenu } from 'variables/columnsData';

function DataTableMenu() {
  const textColor = useColorModeValue('gray.700', 'white');
  const listLocationWeb = useRecoilValue(menuAtom);
  const totalRows = useRecoilValue(totalRow);
  const cmsActions = useCMSActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentVenueWeb, setCurrentVenueWeb] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newVenueWeb, setNewVenueWeb] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset, setValue, watch } = useForm(
    formOptions
  );
  const { errors, isSubmitting } = formState;
  const toast = useToast();

  useEffect(() => {
    if (listLocationWeb) {
      const toolTypes = listLocationWeb.map((i) => ({
        ...i,
        filters: {},
        typeSwitch: 'menu',
      }));
      setNewVenueWeb(toolTypes);
    }
  }, [listLocationWeb]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListMenu(
      {
        name: '',
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };

  async function onSubmit({ name, slug, type, position, isActive, parent_id }) {
    const params = {
      name,
      type,
      position,
      parent_id: parseInt(parent_id),
      status: isActive ? 1 : 2,
      slug,
    };
    const listKeys = ['name'];
    const languages = listKeys
      .map((i) => {
        if (watch(`${i}_vn`)) {
          return {
            code: 'vi',
            locale_key: i,
            locale_value: watch(`${i}_vn`),
          };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    params.languages = languages;
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createMenu(params);
      } else {
        res = await cmsActions.updateMenu(params, currentVenueWeb);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? 'Create Menu success'
          : 'Update Menu success';
        toast({
          description: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        await cmsActions.getListMenu(
          {
            name: '',
          },
          0,
          localStorage && localStorage.getItem('currentPageSizeStore')
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }

  async function editNews(newsID) {
    const newsData = await cmsActions.getDetailMenu(newsID);
    if (newsData) {
      const {
        name,
        slug,
        type,
        position,
        parent_id,
        status,
        id,
        languages,
      } = newsData.data.data;
      setValue('name', name);
      setValue('slug', slug);
      setValue('type', type);
      setValue('position', position);
      setValue('parent_id', parent_id);
      setValue('isActive', status === 1 ? true : false);
      setCurrentVenueWeb(id);
      if (languages) {
        languages.forEach((e) => {
          setValue(`${e.locale_key}_vn`, e.locale_value);
        });
      }
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Menu Management
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(42) && (
              <Button
                size='sm'
                onClick={() => {
                  onOpen();
                  setIsCreate(true);
                }}
                leftIcon={<AddIcon />}
                colorScheme='blue'
                variant='outline'
              >
                Add Menu
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              reset();
            }}
            size='4xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader>{isCreate ? 'Add Menu' : 'Edit Menu'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Tabs isFitted variant='enclosed'>
                    <TabList>
                      <Tab
                        _focus={{ outline: 'none' }}
                        _selected={{ color: 'white', bg: 'blue.500' }}
                        fontWeight={'bold'}
                        fontSize={'18px'}
                      >
                        English
                      </Tab>
                      <Tab
                        _focus={{ outline: 'none' }}
                        _selected={{ color: 'white', bg: 'blue.500' }}
                        fontWeight={'bold'}
                        fontSize={'18px'}
                      >
                        Viet Nam
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <Grid
                          templateColumns={{
                            sm: '1fr 1fr',
                            lg: 'repeat(2, 1fr)',
                          }}
                          gap='30px'
                          mt={2}
                        >
                          <FormControl isRequired>
                            <FormLabel fontSize='sm' fontWeight='normal'>
                              Name
                            </FormLabel>
                            <Input
                              fontSize='sm'
                              ms='4px'
                              type='text'
                              placeholder='Name'
                              maxLength={250}
                              size='md'
                              name='name'
                              {...register('name')}
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel
                              color={textColor}
                              fontWeight='bold'
                              fontSize='xs'
                            >
                              Slug
                            </FormLabel>
                            <Input
                              fontSize='sm'
                              ms='4px'
                              type='text'
                              placeholder='Slug'
                              maxLength={250}
                              size='md'
                              name='slug'
                              {...register('slug')}
                              disabled={!isCreate}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          templateColumns={{
                            sm: '1fr 1fr 1fr',
                            lg: 'repeat(3, 1fr)',
                          }}
                          gap='20px'
                          mt={2}
                        >
                          <FormControl>
                            <FormLabel
                              fontWeight='semibold'
                              fontSize='xs'
                              ms='4px'
                            >
                              Parent
                            </FormLabel>
                            <Select
                              ms='4px'
                              type='text'
                              defaultValue='0'
                              size='sm'
                              name='parent_id'
                              {...register('parent_id')}
                              width='200px'
                            >
                              <option key={0} value={0}>
                                Parent
                              </option>
                              {listLocationWeb
                                .filter((i) => i.parent_id === 0)
                                .map((i) => (
                                  <option key={i.id} value={i.id}>
                                    {i.name}
                                  </option>
                                ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <FormLabel
                              fontWeight='semibold'
                              fontSize='xs'
                              ms='4px'
                            >
                              Type
                            </FormLabel>
                            <Select
                              ms='4px'
                              type='text'
                              defaultValue='0'
                              size='sm'
                              name='type'
                              {...register('type')}
                              width='200px'
                            >
                              {['Parent', 'Show'].map((i) => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <FormLabel
                              fontWeight='semibold'
                              fontSize='xs'
                              ms='4px'
                            >
                              Position
                            </FormLabel>
                            <Select
                              ms='4px'
                              type='text'
                              defaultValue='0'
                              size='sm'
                              name='position'
                              {...register('position')}
                              width='200px'
                            >
                              {['Top', 'Bottom'].map((i) => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </TabPanel>
                      <TabPanel>
                        <Grid
                          templateColumns={{
                            sm: '1fr 1fr',
                            lg: 'repeat(2, 1fr)',
                          }}
                          gap='30px'
                          mt={2}
                        >
                          <FormControl>
                            <FormLabel
                              ms='4px'
                              fontSize='sm'
                              fontWeight='normal'
                            >
                              Name
                            </FormLabel>
                            <Input
                              fontSize='sm'
                              ms='4px'
                              type='text'
                              placeholder='Name'
                              maxLength={250}
                              size='md'
                              name='name_vn'
                              {...register('name_vn')}
                            />
                          </FormControl>
                        </Grid>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                  <FormControl>
                    <Switch
                      id='isChecked'
                      defaultChecked={isCreate && true}
                      name='isActive'
                      {...register('isActive')}
                      size='sm'
                      ms='4px'
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    {isCreate ? 'Create' : 'Update'}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <SearchTable2
          tableData={newVenueWeb}
          columnsData={columnsDataMenu}
          totalRow={totalRows.menu}
          edit={editNews}
          actionsActive={['edit']}
          listPermissions={[43]}
          isFetching={isFetching}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableMenu;
