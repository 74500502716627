import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "state/recoil-root";
import instance from "helpers/instance";
import { totalRow, totalRowTheaterPass } from "state/roles-permissions";
import {
  listShowAtom,
  venueListAtom,
  showsScheduleAtom,
  listUnitAtom,
  listUnitForAddingAtom,
  listChannelAtom,
  selectedShowAtom,
  toolTypesAtom,
  zoneListAtom,
  locationListAtom,
  showsScheduleListAtom,
  showTimeListAtom,
  paymentMethodListAtom,
  currencyListAtom,
  paymentStatusListAtom,
  timeToReleaseListAtom,
  seatingPlanListAtom,
  assignSeatingPlanListAtom,
  showsScheduleDetailAtom,
  showsScheduleBookingDetailAtom,
  listInvoiceAtom,
  ticketPriceListAtom,
  ticketPriceListByShowVenueAtom,
  listTargetAtom,
  listTheaterPassAtom,
} from "state/show";

const useBookingActions = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [totalRows, setTotalRows] = useRecoilState(totalRow);
  const [totalTheaterPassRows, setTotalTheaterPassRows] = useRecoilState(totalRowTheaterPass);
  const [showList, setShowList] = useRecoilState(listShowAtom);
  const [showTheaterList, setShowTheaterList] = useRecoilState(listTheaterPassAtom);
  const [venueList, setVenueList] = useRecoilState(venueListAtom);
  const [zoneList, setZoneList] = useRecoilState(zoneListAtom);
  const [locationList, setLocationList] = useRecoilState(locationListAtom);
  const [showsSchedule, setShowsSchedule] = useRecoilState(showsScheduleAtom);
  const [showsScheduleList, setShowScheduleList] = useRecoilState(
    showsScheduleListAtom
  );
  const [channelList, setChannelList] = useRecoilState(listChannelAtom);
  const [toolTypes, setToolTypes] = useRecoilState(toolTypesAtom);
  const [showTimeList, setShowTimeList] = useRecoilState(showTimeListAtom);
  const [paymentMethodList, setPaymentMethodList] = useRecoilState(
    paymentMethodListAtom
  );
  const [currencyList, setCurrencyList] = useRecoilState(currencyListAtom);
  const [paymentStatusList, setPaymentStatusList] = useRecoilState(
    paymentStatusListAtom
  );
  const [timeToReleaseList, setTimeToReleaseList] = useRecoilState(
    timeToReleaseListAtom
  );
  const [unitList, setUnitList] = useRecoilState(listUnitAtom);
  const [unitListForAdding, setUnitListForAdding] = useRecoilState(
    listUnitForAddingAtom
  );
  const [seatingPlanList, setSeatingPlanList] = useRecoilState(
    seatingPlanListAtom
  );
  const [assignSeatingPlanList, setAssignSeatingPlanList] = useRecoilState(
    assignSeatingPlanListAtom
  );
  const [showsScheduleDetailList, setShowScheduleDetailList] = useRecoilState(
    showsScheduleDetailAtom
  );
  const [
    showsScheduleBookingDetail,
    setShowsScheduleBookingDetail,
  ] = useRecoilState(showsScheduleBookingDetailAtom);
  const [invoiceList, setInvoiceList] = useRecoilState(listInvoiceAtom);

  const [ticketPriceList, setTicketPriceList] = useRecoilState(
    ticketPriceListAtom
  );
  const [
    ticketPriceListByShowVenue,
    setTicketPriceListByShowVenue,
  ] = useRecoilState(ticketPriceListByShowVenueAtom);
  const [
    targetList,
    setTargetList,
  ] = useRecoilState(listTargetAtom);
  const selectedShow = useRecoilValue(selectedShowAtom);
  const headers = {
    Authorization: `Bearer ${auth?.access_token}`,
  };
  return {
    getShowsSchedule,
    getListShow,
    getListVenue,
    getListChannel,
    getListUnits,
    getListUnitsNotIncludingUnitOfUser,
    getBookingInfo,
    selectShow,
    removeSeatBooking,
    keepSeatBooking,
    getSeatingLock,
    getSeatingPlan,
    updateBookingInfo,
    getSeatingWaitingPayment,
    cancelBooking,
    getSeatingBooked,
    keepComboBooking,
    getListToolTypes,
    selectUnit,
    selectRatePlan,
    selectCombo,
    getListZones,
    getListPaymentMethod,
    getPaymentMethodDetail,
    createPaymentMethod,
    updatePaymentMethod,
    updatePaymentMethodStatus,
    getListExtendTimes,
    getListPaymentStatus,
    getPaymentStatusDetail,
    createPaymentStatus,
    updatePaymentStatus,
    updateStatusOfPaymentStatus,
    selectVoucher,
    createBooking,
    getBookingDetail,
    quickSearchBooking,
    getBookingList,
    exportBookingList,
    exportBookingExcel,
    updateBookingInfoGuestandBooker,
    addPayment,
    getETicketPDF,
    getBookingDetailCodePDF,
    getBookingDetailCodePDFV2,
    confirmPrintBookingDetailCode,
    getTicketPrices,
    addSpecialRequest,
    getHistoriesBooking,
    cancelBookingAdminTool,
    addExtendBooking,
    getCountries,
    getShowDetail,
    createShow,
    updateShow,
    getChannelDetail,
    createChannel,
    updateChannel,
    deleteChannel,
    getZoneDetail,
    createZone,
    updateZone,
    deleteZone,
    updateZoneStatus,
    updateChannelStatus,
    updateShowStatus,
    updateVenueStatus,
    updateLocation,
    createLocation,
    getLocationDetail,
    getListLocations,
    updateLocationStatus,
    getListShowSchedule,
    getListShowTime,
    getShowTimeDetail,
    createShowTime,
    updateShowTime,
    updateShowTimeStatus,
    getListCurrency,
    getCurrencyDetail,
    updateCurrency,
    getListTimeToRelease,
    getTimeToReleaseDetail,
    createTimeToRelease,
    updateTimeToRelease,
    updateTimeToReleaseStatus,
    getToolTypeDetail,
    createToolType,
    updateToolType,
    updateToolTypeStatus,
    getListReleaseTime,
    updateReleaseTime,
    getVenueDetail,
    createVenue,
    updateVenue,
    scanTheaterPass,
    getShowsCurrentDate,
    createUnit,
    updateUnit,
    getUnitDetail,
    updateUnitStatus,
    updateSeatingPlanStatus,
    createSeatingPlan,
    getListSeatingPlan,
    getAssignSeatBySeating,
    updateAssignSeatingPlan,
    getListShowScheduleDetail,
    updateAssignSeatByShowScheduleDetail,
    getAssignSeatByShowScheduleDetail,
    createShowSchedule,
    createShowTimeSchedule,
    updateShowTimeSchedule,
    getListBookingDetails,
    getShowScheduleDetail,
    getListInvoice,
    getListTicketPrice,
    getListHistoryTicketPrice,
    createTicketPrice,
    updateTicketPrice,
    getTicketPriceDetail,
    publishInvoice,
    publishBulkInvoice,
    validateShowTime,
    sendETicket,
    updateShowScheduleStatus,
    checkUnitName,
    createTarget,
    updateTarget,
    updateTargetStatus,
    getTargetDetail,
    getListTarget,
    updateCopyAssignSeatMaster,
    getListTheaterPassShow
  };

  function getShowsSchedule(
    params = { from_date, to_date, venue_id: "", show_id: "" }
  ) {
    if (params.status) {
      switch (params.status) {
        case "Active":
          params.status = 1;
          break;
        case "InActive":
          params.status = 2;
          break;
        case "Cancel":
          params.status = 3;
          break;
        default:
          params.status = 0;
          break;
      }
    }
    return instance({
      url: "show-schedule/v1/show-schedule-by-range",
      method: "GET",
      params,
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          setShowsSchedule(res.data.data);
          return res.data.data
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getListShow(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/shows",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setShowList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            shows: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getListTheaterPassShow(filter, page = 0, pageSize = 10) {
    console.log('getListTheaterPassShow', filter);
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "/booking/v1/reports/scan-threater-pass",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setShowTheaterList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            totalRowTheaterPass: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getListVenue(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/venues",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setVenueList(res.data.data || []);
          setTotalRows((preState) => ({
            ...preState,
            venues: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getListZones(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/zones",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setZoneList(res.data.data || []);
          setTotalRows((preState) => ({
            ...preState,
            zones: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getListChannel(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/channels",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setChannelList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            channels: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getListUnits(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    const url = filter?.omit_ids
      ? `management/v1/units?${filter?.omit_ids}`
      : "management/v1/units";
    delete params?.omit_ids;
    return instance({
      url,
      method: "GET",
      params,
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          setUnitList(res.data.data || []);
          setTotalRows((preState) => ({
            ...preState,
            units: res.data.meta.total,
          }));
        }
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getListUnitsNotIncludingUnitOfUser(
    filter,
    userId,
    page = 0,
    pageSize = 10
  ) {
    const offset = page * pageSize;
    let params = {
      offset,
      limit: pageSize,
      ...filter,
    };

    return instance({
      url: `management/v1/units/${userId}`,
      method: "GET",
      params,
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          setTotalRows((preState) => ({
            ...preState,
            unitListForAdding: res.data.meta.total,
          }));
          setUnitListForAdding(res.data.data || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getBookingInfo(params) {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: "booking/v1/create-booking/info",
      method: "GET",
      params,
      headers,
    });
  }

  function selectShow(data) {
    return instance({
      url: `booking/v1/create-booking/select-show`,
      method: "POST",
      data,
      headers,
    });
  }

  function getListToolTypes(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/tooltypes",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setToolTypes(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            tooltypes: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getToolTypeDetail(toolTypeID) {
    return instance({
      url: `management/v1/tooltypes/${toolTypeID}`,
      method: "GET",
      headers,
    });
  }

  function createToolType(data) {
    return instance({
      url: "management/v1/tooltypes",
      method: "POST",
      data,
      headers,
    });
  }

  function updateToolType(data, toolTypeID) {
    return instance({
      url: `management/v1/tooltypes/${toolTypeID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function updateToolTypeStatus(data, toolTypeID) {
    return instance({
      url: `management/v1/tooltypes/${toolTypeID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }
  function keepSeatBooking(data) {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: `booking/v1/create-booking/keep-seat`,
      method: "POST",
      data,
      headers,
    });
  }

  function removeSeatBooking(data) {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: `booking/v1/create-booking/remove-seat`,
      method: "POST",
      data,
      headers,
    });
  }

  function updateBookingInfo(data) {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: `booking/v1/create-booking/update-info`,
      method: "POST",
      data,
      headers,
    });
  }

  function keepComboBooking(data) {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: `booking/v1/create-booking/keep-combo`,
      method: "POST",
      data,
      headers,
    });
  }

  function getSeatingLock() {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: "booking/v1/create-booking/seating-lock",
      method: "GET",
      headers,
    });
  }

  function getSeatingWaitingPayment() {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: "booking/v1/create-booking/seating-wait-payment",
      method: "GET",
      headers,
    });
  }

  function getSeatingBooked() {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: "booking/v1/create-booking/seating-booked",
      method: "GET",
      headers,
    });
  }

  function getSeatingPlan(param) {
    return instance({
      url: `management/v1/seatingplan/${param}`,
      method: "GET",
      headers,
    });
  }

  function getListExtendTimes(params) {
    return instance({
      url: `management/v1/extendtimes`,
      method: "GET",
      params,
      headers,
    });
  }
  function cancelBooking() {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: "booking/v1/create-booking/cancel",
      method: "DELETE",
      headers,
    });
  }

  function selectUnit(data) {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: `booking/v1/create-booking/select-unit`,
      method: "POST",
      data,
      headers,
    });
  }

  function selectCombo(data) {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: `booking/v1/create-booking/select-combo`,
      method: "POST",
      data,
      headers,
    });
  }

  function selectRatePlan(data) {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: `booking/v1/create-booking/select-rateplan`,
      method: "POST",
      data,
      headers,
    });
  }

  function selectVoucher(data) {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: `booking/v1/create-booking/select-voucher`,
      method: "POST",
      data,
      headers,
    });
  }

  function createBooking(data) {
    headers["X-Booking-Session-ID"] = selectedShow.booking_session_id;
    return instance({
      url: `booking/v1/create-booking`,
      method: "POST",
      data,
      headers,
    });
  }

  function getBookingDetail(bookingID) {
    return instance({
      url: `booking/v1/bookings/${bookingID}`,
      method: "GET",
      headers,
    });
  }

  function getCountries() {
    return instance({
      url: "booking/v1/countries",
      method: "GET",
      headers,
    });
  }

  function quickSearchBooking(keyword, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      offset,
      limit: pageSize,
      keyword,
    };
    return instance({
      url: `booking/v1/bookings/quick-search`,
      method: "GET",
      params,
      headers,
    });
  }

  function getBookingList(filter) {
    const params = {
      sort_type: "desc",
      sort_field: "created_at",
      ...filter,
    };
    return instance({
      url: `booking/v1/bookings`,
      method: "GET",
      params,
      headers,
    });
  }

  function exportBookingList(filter) {
    const params = {
      sort_type: "desc",
      sort_field: "created_at",
      ...filter,
    };
    headers.responseType = "blob";
    return instance({
      url: `booking/v1/bookings/export`,
      method: "GET",
      params,
      responseType: "blob",
      headers,
    });
  }
  function exportBookingExcel(filter) {
    const params = {
      sort_type: "desc",
      sort_field: "created_at",
      ...filter,
    };
    headers.responseType = "blob";
    return instance({
      url: `/booking/v1/bookings/e-invoice/export`,
      method: "GET",
      params,
      responseType: "blob",
      headers,
    });
  }

  function updateBookingInfoGuestandBooker(data, bookingID) {
    return instance({
      url: `booking/v1/bookings/${bookingID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function addPayment(data, bookingID) {
    return instance({
      url: `booking/v1/bookings/${bookingID}/add-payment`,
      method: "PUT",
      headers,
      data,
    });
  }

  function getETicketPDF(params, bookingID) {
    headers.responseType = "blob";
    return instance({
      url: `booking/v1/bookings/${bookingID}/e-ticket`,
      method: "GET",
      params,
      responseType: "blob",
      headers,
    });
  }

  function getTicketPrices(params) {
    return instance({
      url: "management/v1/ticket-prices/get-by-show-venue",
      method: "GET",
      params,
      headers,
    });
  }

  function addSpecialRequest(data, bookingID) {
    return instance({
      url: `booking/v1/bookings/${bookingID}/special-request`,
      method: "POST",
      data,
      headers,
    });
  }

  function getHistoriesBooking(bookingID) {
    return instance({
      url: `management/v1/histories/Booking?object_id=${bookingID}&limit=200&offset=0`,
      method: "GET",
      headers,
    });
  }

  function cancelBookingAdminTool(data, bookingID) {
    return instance({
      url: `booking/v1/bookings/${bookingID}/cancel`,
      method: "POST",
      data,
      headers,
    });
  }

  function addExtendBooking(data, bookingID) {
    return instance({
      url: `booking/v1/bookings/${bookingID}/extend`,
      method: "PUT",
      data,
      headers,
    });
  }

  function getBookingDetailCodePDF(bookingID, bookingDetailIDs) {
    headers.responseType = "blob";
    return instance({
      url: `booking/v1/bookings/${bookingID}/threat-pass?ids=${bookingDetailIDs}`,
      method: "GET",
      responseType: "blob",
      headers,
    });
  }

  function getBookingDetailCodePDFV2(bookingID, bookingDetailIDs) {
    headers.responseType = "blob";
    return instance({
      url: `booking/v1/bookings/${bookingID}/threat-pass-v2?ids=${bookingDetailIDs}`,
      method: "GET",
      responseType: "blob",
      headers,
    });
  }

  function confirmPrintBookingDetailCode(bookingID, bookingDetailIDs) {
    return instance({
      url: `booking/v1/bookings/${bookingID}/confirm-printed-threat-pass?ids=${bookingDetailIDs}`,
      method: "POST",
      headers,
    });
  }

  function getShowDetail(showID) {
    return instance({
      url: `management/v1/shows/${showID}`,
      method: "GET",
      headers,
    });
  }

  function createShow(data) {
    return instance({
      url: "management/v1/shows",
      method: "POST",
      data,
      headers,
    });
  }

  function updateShow(data, showID) {
    return instance({
      url: `management/v1/shows/${showID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function getChannelDetail(channelID) {
    return instance({
      url: `management/v1/channels/${channelID}`,
      method: "GET",
      headers,
    });
  }

  function createChannel(data) {
    return instance({
      url: "management/v1/channels",
      method: "POST",
      data,
      headers,
    });
  }

  function updateChannel(data, channelID) {
    return instance({
      url: `management/v1/channels/${channelID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function deleteChannel(channelID) {
    return instance({
      url: `rateplan/v1/channels/${channelID}`,
      method: "DELETE",
      headers,
    });
  }

  function getZoneDetail(channelID) {
    return instance({
      url: `management/v1/zones/${channelID}`,
      method: "GET",
      headers,
    });
  }

  function createZone(data) {
    return instance({
      url: "management/v1/zones",
      method: "POST",
      data,
      headers,
    });
  }

  function updateZone(data, channelID) {
    return instance({
      url: `management/v1/zones/${channelID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function deleteZone(channelID) {
    return instance({
      url: `rateplan/v1/zones/${channelID}`,
      method: "DELETE",
      headers,
    });
  }

  function updateZoneStatus(data, zoneID) {
    return instance({
      url: `management/v1/zones/${zoneID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function updateChannelStatus(data, channelID) {
    return instance({
      url: `management/v1/channels/${channelID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function updateShowStatus(data, showID) {
    return instance({
      url: `management/v1/shows/${showID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function updateVenueStatus(data, venueID) {
    return instance({
      url: `management/v1/venues/${venueID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function updateLocationStatus(data, venueID) {
    return instance({
      url: `management/v1/locations/${venueID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function getListLocations(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/locations",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setLocationList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            locations: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getLocationDetail(locationID) {
    return instance({
      url: `management/v1/locations/${locationID}`,
      method: "GET",
      headers,
    });
  }

  function createLocation(data) {
    return instance({
      url: "management/v1/locations",
      method: "POST",
      data,
      headers,
    });
  }

  function updateLocation(data, locationID) {
    return instance({
      url: `management/v1/locations/${locationID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function getListShowSchedule(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "show-schedule/v1/show-schedules",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setShowScheduleList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            showsSchedule: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getListShowTime(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/showtimes",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setShowTimeList(res.data.data ?? []);
          setTotalRows((preState) => ({
            ...preState,
            showtimes: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getShowTimeDetail(showTimeStatusID) {
    return instance({
      url: `management/v1/showtimes/${showTimeStatusID}`,
      method: "GET",
      headers,
    });
  }

  function createShowTime(data) {
    return instance({
      url: "management/v1/showtimes",
      method: "POST",
      data,
      headers,
    });
  }

  function updateShowTime(data, showTimeStatusID) {
    return instance({
      url: `management/v1/showtimes/${showTimeStatusID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function updateShowTimeStatus(data, showTimeStatusID) {
    return instance({
      url: `management/v1/showtimes/${showTimeStatusID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function getListPaymentMethod(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/paymentmethods",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setPaymentMethodList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            paymentmethods: res.data.meta.total,
          }));
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getPaymentMethodDetail(paymentMethodID) {
    return instance({
      url: `management/v1/paymentmethods/${paymentMethodID}`,
      method: "GET",
      headers,
    });
  }

  function createPaymentMethod(data) {
    return instance({
      url: "management/v1/paymentmethods",
      method: "POST",
      data,
      headers,
    });
  }

  function updatePaymentMethod(data, paymentMethodID) {
    return instance({
      url: `management/v1/paymentmethods/${paymentMethodID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function updatePaymentMethodStatus(data, paymentMethodID) {
    return instance({
      url: `management/v1/paymentmethods/${paymentMethodID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function getListCurrency() {
    return instance({
      url: "management/v1/currencies",
      method: "GET",
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          setCurrencyList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            currencies: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getCurrencyDetail(currencyID) {
    return instance({
      url: `management/v1/currencies/${currencyID}`,
      method: "GET",
      headers,
    });
  }

  function updateCurrency(data, currencyID) {
    return instance({
      url: `management/v1/currencies/${currencyID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function getListTimeToRelease(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/extendtimes",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setTimeToReleaseList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            timetoreleases: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getTimeToReleaseDetail(extendTimeStatusID) {
    return instance({
      url: `management/v1/extendtimes/${extendTimeStatusID}`,
      method: "GET",
      headers,
    });
  }
  function createTimeToRelease(data) {
    return instance({
      url: "management/v1/extendtimes",
      method: "POST",
      data,
      headers,
    });
  }
  function updateTimeToRelease(data, extendTimeStatusID) {
    return instance({
      url: `management/v1/extendtimes/${extendTimeStatusID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function updateTimeToReleaseStatus(data, extendTimeStatusID) {
    return instance({
      url: `management/v1/extendtimes/${extendTimeStatusID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function getListPaymentStatus(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/paymentstatuses",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setPaymentStatusList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            paymentstatuses: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getPaymentStatusDetail(paymentStatusID) {
    return instance({
      url: `management/v1/paymentstatuses/${paymentStatusID}`,
      method: "GET",
      headers,
    });
  }
  function createPaymentStatus(data) {
    return instance({
      url: "management/v1/paymentstatuses",
      method: "POST",
      data,
      headers,
    });
  }
  function updatePaymentStatus(data, paymentStatusID) {
    return instance({
      url: `management/v1/paymentstatuses/${paymentStatusID}`,
      method: "PUT",
      headers,
      data,
    });
  }
  function updateStatusOfPaymentStatus(data, paymentStatusID) {
    return instance({
      url: `management/v1/paymentstatuses/${paymentStatusID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }
  function getListReleaseTime() {
    return instance({
      url: `management/v1/releasetime`,
      method: "GET",
      headers,
    });
  }
  function updateReleaseTime(data) {
    return instance({
      url: `management/v1/releasetime/update`,
      method: "PUT",
      headers,
      data,
    });
  }

  function getVenueDetail(venueID) {
    return instance({
      url: `management/v1/venues/${venueID}`,
      method: "GET",
      headers,
    });
  }
  function createVenue(data) {
    return instance({
      url: "management/v1/venues",
      method: "POST",
      data,
      headers,
    });
  }
  function updateVenue(data, venueID) {
    return instance({
      url: `management/v1/venues/${venueID}`,
      method: "PUT",
      headers,
      data,
    });
  }
  function scanTheaterPass(filter) {
    let params = {
      ...filter,
    };
    return instance({
      url: "booking/v1/bookings/scan-theater-pass",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getShowsCurrentDate() {
    return instance({
      url: "show-schedule/v1/show-schedule-details",
      method: "GET",
      headers,
    });
  }
  function createUnit(data) {
    return instance({
      url: "management/v1/units",
      method: "POST",
      data,
      headers,
    });
  }

  function updateUnit(data, unitID) {
    return instance({
      url: `management/v1/units/${unitID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function getUnitDetail(unitID) {
    return instance({
      url: `management/v1/units/${unitID}/detail`,
      method: "GET",
      headers,
    });
  }

  function updateUnitStatus(data, unitID) {
    return instance({
      url: `management/v1/units/${unitID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }
  function getListSeatingPlan(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/seatingplan",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setSeatingPlanList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            seatingPlans: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function createSeatingPlan(data) {
    return instance({
      url: "management/v1/seatingplan",
      method: "POST",
      data,
      headers,
    });
  }

  function updateSeatingPlanStatus(data, seatingPlanStatusID) {
    return instance({
      url: `management/v1/seatingplan/${seatingPlanStatusID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function getAssignSeatBySeating(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    delete params.id;
    return instance({
      url: `management/v1/seatingplan/${filter.id}/assign-seat`,
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setAssignSeatingPlanList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            assignSeatingPlans: res.data.meta.total,
          }));
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function updateAssignSeatingPlan(data, seatingPlan) {
    return instance({
      url: `management/v1/seatingplan/${seatingPlan}/assign-seat`,
      method: "PUT",
      headers,
      data,
    });
  }

  function getListShowScheduleDetail(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "show-schedule/v1/show-schedules/details",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setShowScheduleList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            showsScheduleDetail: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }



  function updateAssignSeatByShowScheduleDetail(data, seatingPlan) {
    return instance({
      url: `show-schedule/v1/show-schedule-details/${seatingPlan}/assign-seat`,
      method: "PUT",
      headers,
      data,
    });
  }

  function getAssignSeatByShowScheduleDetail(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    delete params.id;
    return instance({
      url: `show-schedule/v1/show-schedule-details/${filter.id}/assign-seat`,
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setShowScheduleDetailList(res.data.data ?? []);
          setTotalRows((preState) => ({
            ...preState,
            showsScheduleDetailList: res.data.meta.total,
          }));
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function createShowSchedule(data) {
    return instance({
      url: "show-schedule/v1/show-schedules",
      method: "POST",
      data,
      headers,
    });
  }

  function createShowTimeSchedule(data, showsScheduleID) {
    return instance({
      url: `show-schedule/v1/show-schedules/${showsScheduleID}/show-times`,
      method: "POST",
      headers,
      data,
    });
  }

  function updateShowTimeSchedule(data, seatingPlanStatusID, showTimeID) {
    return instance({
      url: `show-schedule/v1/show-schedules/${seatingPlanStatusID}/show-times/${showTimeID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function getListBookingDetails(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "booking/v1/booking-details",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setShowsScheduleBookingDetail(res.data.data ?? []);
          setTotalRows((preState) => ({
            ...preState,
            showsScheduleBookingDetail: res.data.meta.total,
          }));
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getShowScheduleDetail(showID) {
    return instance({
      url: `show-schedule/v1/show-schedules/details/${showID}`,
      method: "GET",
      headers,
    });
  }

  function getListInvoice(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "booking/v1/bookings/e-invoice",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setInvoiceList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            invoice: res.data.meta.total,
          }));
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getListTicketPrice(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/ticket-prices",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setTicketPriceList(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            ticketPrices: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getListHistoryTicketPrice(params) {
    return instance({
      url: "management/v1/ticket-prices/history",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function createTicketPrice(data) {
    return instance({
      url: "management/v1/ticket-prices",
      method: "POST",
      data,
      headers,
    });
  }
  function updateTicketPrice(data, ticketPriceID) {
    return instance({
      url: `management/v1/ticket-prices/${ticketPriceID}`,
      method: "PUT",
      headers,
      data,
    });
  }
  function getTicketPriceDetail(paymentMethodID) {
    return instance({
      url: `management/v1/ticket-prices/${paymentMethodID}`,
      method: "GET",
      headers,
    });
  }
  function publishInvoice(data) {
    return instance({
      url: "management/v1/invoices",
      method: "POST",
      data,
      headers,
    });
  }
  function publishBulkInvoice(data) {
    return instance({
      url: "management/v1/invoices/bulk",
      method: "POST",
      data,
      headers,
    });
  }
  function sendETicket(data, bookingID) {
    return instance({
      url: `booking/v1/bookings/${bookingID}/send-eticket`,
      method: "POST",
      data,
      headers,
    });
  }
  function validateShowTime(schedule_id, data) {
    return instance({
      url: `show-schedule/v1/show-schedules/${schedule_id}/validate-show-times`,
      method: "POST",
      data,
      headers,
    })
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        return err.response.status;
      });
  }
  function updateShowScheduleStatus(data, showScheduleID) {
    return instance({
      url: `show-schedule/v1/show-schedules/${showScheduleID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }
  function checkUnitName(data) {
    return instance({
      url: "management/v1/units/check-name",
      method: "POST",
      data,
      headers,
    });
  }
  function getListTarget(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "management/v1/targets",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setTargetList(res.data.data ?? []);
          setTotalRows((preState) => ({
            ...preState,
            listTarget: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getTargetDetail(targetID) {
    return instance({
      url: `management/v1/targets/${targetID}`,
      method: "GET",
      headers,
    });
  }

  function createTarget(data) {
    return instance({
      url: "management/v1/targets",
      method: "POST",
      data,
      headers,
    });
  }

  function updateTarget(data, targetID) {
    return instance({
      url: `management/v1/targets/${targetID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function updateTargetStatus(data, targetID) {
    return instance({
      url: `management/v1/targets/${targetID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

   function updateCopyAssignSeatMaster(seatingPlan) {
    return instance({
      url: `show-schedule/v1/show-schedule-details/${seatingPlan}/copy-assign-seat`,
      method: "PUT",
      headers
    });
  }
};

export { useBookingActions };
