import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchTable2 from 'components/Tables/SearchTable2';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { totalRow } from 'state/roles-permissions';
import { AddIcon } from '@chakra-ui/icons';
import { checkForbidden } from 'helpers/checkForbidden';
import { useCMSActions } from 'hooks/useCMS';
import Editor from 'components/Editor/Editor2';
import { locationWebAtom } from 'state/cms';
import { columnsDataLocationWeb } from 'variables/columnsData';

function DataTableLocationWeb() {
  const textColor = useColorModeValue('gray.700', 'white');
  const listLocationWeb = useRecoilValue(locationWebAtom);
  const totalRows = useRecoilValue(totalRow);
  const cmsActions = useCMSActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentVenueWeb, setCurrentVenueWeb] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newVenueWeb, setNewVenueWeb] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();

  useEffect(() => {
    if (listLocationWeb) {
      const toolTypes = listLocationWeb.map((i) => ({
        ...i,
        filters: { name: '' },
        typeSwitch: 'locationWeb',
      }));
      setNewVenueWeb(toolTypes);
    }
  }, [listLocationWeb]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListLocations(
      {
        name: '',
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({
    name,
    description,
    content,
    id,
    isActive,
    slug,
    code,
    short_name,
    history,
    fact_and_figure,
    order,
    map,
    trailer,
  }) {
    const params = {
      name,
      description,
      content,
      id,
      status: isActive ? 1 : 2,
      slug,
      code,
      short_name,
      history,
      fact_and_figure,
      order,
      map,
      trailer,
    };
    const listKeys = ['name'];
    const languages = listKeys
      .map((i) => {
        if (watch(`${i}_vn`)) {
          return {
            code: 'vi',
            locale_key: i,
            locale_value: watch(`${i}_vn`),
          };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    params.languages = languages;
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createLocations(params);
      } else {
        res = await cmsActions.updateLocations(params, currentVenueWeb);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? 'Create Location Web success'
          : 'Update Location Web success';
        toast({
          description: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        await cmsActions.getListLocations(
          {
            name: '',
          },
          0,
          localStorage && localStorage.getItem('currentPageSizeStore')
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }

  async function editNews(newsID) {
    const newsData = await cmsActions.getDetailLocations(newsID);
    if (newsData) {
      const { name, description, status, id, languages } = newsData.data.data;
      setValue('name', name);
      setValue('description', description);
      setValue('isActive', status === 1 ? true : false);
      setCurrentVenueWeb(id);
      if (languages) {
        languages.forEach((e) => {
          setValue(`${e.locale_key}_vn`, e.locale_value);
        });
      }
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Location Web Management
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(42) && (
              <Button
                size='sm'
                onClick={() => {
                  onOpen();
                  setIsCreate(true);
                }}
                leftIcon={<AddIcon />}
                colorScheme='blue'
                variant='outline'
              >
                Add Location Web
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              reset();
            }}
            size='4xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader>
                  {isCreate ? 'Add Location Web' : 'Edit Location Web'}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Tabs isFitted variant='enclosed'>
                    <TabList>
                      <Tab
                        _focus={{ outline: 'none' }}
                        _selected={{ color: 'white', bg: 'blue.500' }}
                        fontWeight={'bold'}
                        fontSize={'18px'}
                      >
                        English
                      </Tab>
                      <Tab
                        _focus={{ outline: 'none' }}
                        _selected={{ color: 'white', bg: 'blue.500' }}
                        fontWeight={'bold'}
                        fontSize={'18px'}
                      >
                        Viet Nam
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <FormControl isRequired>
                          <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                            Name
                          </FormLabel>
                          <Input
                            fontSize='sm'
                            ms='4px'
                            type='text'
                            placeholder='Name'
                            maxLength={250}
                            size='md'
                            name='name'
                            {...register('name')}
                          />
                        </FormControl>
                        <FormControl mt={3}>
                          <FormLabel
                            color={textColor}
                            fontWeight='bold'
                            fontSize='xs'
                          >
                            Description
                          </FormLabel>
                          <Editor name='description' control={control} />
                        </FormControl>
                      </TabPanel>
                      <TabPanel>
                        <FormControl>
                          <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                            Name
                          </FormLabel>
                          <Input
                            fontSize='sm'
                            ms='4px'
                            type='text'
                            placeholder='Name'
                            maxLength={250}
                            size='md'
                            name='name_vn'
                            {...register('name_vn')}
                          />
                        </FormControl>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  <FormControl>
                    <Switch
                      id='isChecked'
                      defaultChecked={isCreate && true}
                      name='isActive'
                      {...register('isActive')}
                      size='sm'
                      ms='4px'
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    {isCreate ? 'Create' : 'Update'}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <SearchTable2
          tableData={newVenueWeb}
          columnsData={columnsDataLocationWeb}
          totalRow={totalRows.venueWeb}
          edit={editNews}
          actionsActive={['edit']}
          listPermissions={[43]}
          isFetching={isFetching}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableLocationWeb;
