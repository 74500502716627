import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Grid,
  Select,
} from '@chakra-ui/react';
import { parseISO } from 'date-fns';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchTable2 from 'components/Tables/SearchTable2';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { totalRow } from 'state/roles-permissions';
import { AddIcon } from '@chakra-ui/icons';
import { checkForbidden } from 'helpers/checkForbidden';
import { useCMSActions } from 'hooks/useCMS';
import Editor from 'components/Editor/Editor2';
import DatePickerInput from 'components/CustomDateInput/CustomDateInput';
import { useRatePlanActions } from 'hooks/useRatePlanActions';
import { columnsDataRecruitment } from 'variables/columnsData';
import { recruitmentAtom, locationWebAtom } from 'state/cms';

function DataTableRecruitment() {
  const textColor = useColorModeValue('gray.700', 'white');
  const listNews = useRecoilValue(recruitmentAtom);
  const totalRows = useRecoilValue(totalRow);
  const listLocationWeb = useRecoilValue(locationWebAtom);

  const cmsActions = useCMSActions();
  const ratePlanActions = useRatePlanActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentNewsID, setCurrentNewsID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListNews, setNewListNews] = useState([]);
  const [previewImage, setPreviewImage] = useState('');

  const validationSchema = Yup.object().shape({
    position: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const imageURL = watch('image_url');
  useEffect(() => {
    cmsActions.getListLocations();
  }, []);
  useEffect(async () => {
    if (imageURL && imageURL?.length > 0) {
      const formData = new FormData();
      formData.append('file', imageURL[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewImage(data.url);
    }
  }, [imageURL]);
  useEffect(() => {
    if (listNews) {
      const toolTypes = listNews.map((i) => ({
        ...i,
        filters: { name: '', status: 1 },
        typeSwitch: 'recruitment',
      }));
      setNewListNews(toolTypes);
    }
  }, [listNews]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListRecruitment(
      {
        name: '',
        status: 1,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({
    position,
    description,
    id,
    status,
    posting_date,
    closing_date,
    isActive,
    responsibilities,
    requirements,
    location_id,
  }) {
    const params = {
      position,
      description,
      id,
      image_url: previewImage,
      status,
      posting_date,
      closing_date,
      status: isActive ? 1 : 2,
      responsibilities,
      requirements,
      location_id: parseInt(location_id),
    };
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createNews(params);
      } else {
        res = await cmsActions.updateNews(params, currentNewsID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        setPreviewImage('');
        const message = isCreate
          ? 'Create Recruitment success'
          : 'Update Recruitment success';
        toast({
          description: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        await cmsActions.getListRecruitment(
          {
            name: '',
            status: 1,
          },
          0,
          localStorage && localStorage.getItem('currentPageSizeStore')
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }

  async function editNews(newsID) {
    const newsData = await cmsActions.getDetailRecruitment(newsID);
    if (newsData) {
      const {
        position,
        description,
        id,
        image_url,
        status,
        posting_date,
        closing_date,
        responsibilities,
        requirements,
        location_id,
      } = newsData.data.data;
      setValue('position', position);
      setValue('description', description);
      setValue('responsibilities', responsibilities);
      setValue('requirements', requirements);
      setValue('location_id', location_id);
      setPreviewImage(image_url);
      setValue('posting_date', parseISO(posting_date), 1);
      setValue('closing_date', parseISO(closing_date), 1);
      setValue('isActive', status === 1 ? true : false);
      setCurrentNewsID(id);
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Recruitment Management
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(42) && (
              <Button
                size='sm'
                onClick={() => {
                  onOpen();
                  setIsCreate(true);
                }}
                leftIcon={<AddIcon />}
                colorScheme='blue'
                variant='outline'
              >
                Add Recruitment
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              reset();
              setPreviewImage('');
            }}
            size='6xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader>
                  {isCreate ? 'Add Recruitment' : 'Edit Recruitment'}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Grid
                    templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}
                    gap='12px'
                    mt={2}
                  >
                    <FormControl isRequired>
                      <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                        Position
                      </FormLabel>
                      <Input
                        fontSize='sm'
                        ms='4px'
                        type='text'
                        placeholder='Position'
                        maxLength={250}
                        size='md'
                        name='position'
                        {...register('position')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}
                    gap='12px'
                    mt={2}
                  >
                    <FormControl>
                      <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                        Location
                      </FormLabel>
                      <Select
                        ms='4px'
                        type='text'
                        defaultValue='0'
                        size='sm'
                        name='location_id'
                        {...register('location_id')}
                        width='200px'
                      >
                        {listLocationWeb.map((i) => (
                          <option key={i.id} value={i.id}>
                            {i.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                        Responsibilities
                      </FormLabel>
                      <Input
                        fontSize='sm'
                        ms='4px'
                        type='text'
                        placeholder='Responsibilities'
                        maxLength={250}
                        size='md'
                        name='responsibilities'
                        maxW={250}
                        {...register('responsibilities')}
                      />
                    </FormControl>
                  </Grid>

                  <FormControl mt={3}>
                    <FormLabel
                      color={textColor}
                      fontWeight='bold'
                      fontSize='xs'
                    >
                      Description
                    </FormLabel>
                    <Editor name='description' control={control} />
                  </FormControl>
                  <Grid
                    templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}
                    gap='12px'
                    mt={2}
                  >
                    <FormControl>
                      <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                        Requirements
                      </FormLabel>
                      <Input
                        fontSize='sm'
                        ms='4px'
                        type='text'
                        placeholder='Requirements'
                        maxLength={250}
                        size='md'
                        name='requirements'
                        maxW={250}
                        {...register('requirements')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}
                    gap='12px'
                    mt={2}
                  >
                    <FormControl>
                      <FormLabel
                        color={textColor}
                        fontWeight='bold'
                        fontSize='xs'
                      >
                        Start Date
                      </FormLabel>
                      <Controller
                        name='posting_date'
                        control={control}
                        render={({ field }) => (
                          <DatePickerInput
                            onChange={(value) => field.onChange(value)}
                            selected={watch('posting_date')}
                            showTimeSelect
                            startDate={watch('posting_date')}
                            selectsStart
                            endDate={watch('closing_date')}
                          />
                        )}
                      />
                      <Text color='red' fontSize={14} mb='10px' ml='5px'>
                        {errors.posting_date?.message}
                      </Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        color={textColor}
                        fontWeight='bold'
                        fontSize='xs'
                      >
                        End Date
                      </FormLabel>
                      <Controller
                        name='closing_date'
                        control={control}
                        render={({ field }) => (
                          <DatePickerInput
                            onChange={(value) => field.onChange(value)}
                            selected={watch('closing_date')}
                            showTimeSelect
                            selectsEnd
                            startDate={watch('posting_date')}
                            endDate={watch('closing_date')}
                            minDate={watch('posting_date')}
                          />
                        )}
                      />
                      <Text color='red' fontSize={14} mb='10px' ml='5px'>
                        {errors.closing_date?.message}
                      </Text>
                    </FormControl>
                  </Grid>
                  <Grid
                    templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}
                    gap='12px'
                    mt={2}
                  >
                    <FormControl>
                      <FormLabel
                        color={textColor}
                        fontWeight='bold'
                        fontSize='xs'
                      >
                        File
                      </FormLabel>
                      <Input
                        fontSize='sm'
                        ms='4px'
                        type='file'
                        placeholder='Image'
                        size='sm'
                        name='image_url'
                        {...register('image_url')}
                      />
                    </FormControl>
                  </Grid>
                  <FormControl>
                    <Switch
                      id='isChecked'
                      defaultChecked={isCreate && true}
                      name='isActive'
                      {...register('isActive')}
                      size='sm'
                      ms='4px'
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    {isCreate ? 'Create' : 'Update'}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                      setPreviewImage('');
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <SearchTable2
          tableData={newListNews}
          columnsData={columnsDataRecruitment}
          totalRow={totalRows.recruitments}
          edit={editNews}
          actionsActive={['edit']}
          listPermissions={[43]}
          isFetching={isFetching}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableRecruitment;
