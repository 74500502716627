import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Box,
  Select,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { columnsDataRole } from 'variables/columnsData';
import { useRolePermissionActions } from 'hooks/useRolePermissionActions';
import { rolesAtom } from 'state/roles-permissions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { permissionsAtom } from 'state/roles-permissions';
import { totalRow } from 'state/roles-permissions';
import { AddIcon } from '@chakra-ui/icons';
import { checkForbidden } from 'helpers/checkForbidden';
import { BsSearch } from 'react-icons/bs';
import { RxReset } from 'react-icons/rx';
import SearchTable2 from 'components/Tables/SearchTable2';

function DataTableRole() {
  const textColor = useColorModeValue('gray.700', 'white');
  const roles = useRecoilValue(rolesAtom);
  const permissions = useRecoilValue(permissionsAtom);
  const totalRows = useRecoilValue(totalRow);
  const roleAction = useRolePermissionActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [currentRoleID, setCurrentRoleID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newRoles, setNewRoles] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);

  useEffect(() => {
    if (permissions.length > 0) {
      setNodes(permissions);
    }
  }, [permissions]);

  const handleCheckboxChange = (checked) => {
    setChecked(checked);
  };
  const onExpand = (value) => {
    setExpanded(value);
  };
  const resetState = () => {
    setChecked([]);
    setExpanded([]);
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    description: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    setError,
    formState,
    reset,
    setValue,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const RoleStatus = [
    { Id: '0', Name: 'All' },
    { Id: '1', Name: 'Active' },
    { Id: '2', Name: 'InActive' },
  ];
  const nameSearch = watchSearch('nameSearch');
  const statusSearch = watchSearch('statusSearch');

  useEffect(() => {
    roleAction.getPermissions();
  }, []);

  const recallApi = async (fetchDataOptions) => {
    await roleAction.getAllRoles(
      {
        name: nameSearch,
        status: statusSearch == undefined ? 1 : statusSearch,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
  };
  useEffect(() => {
    if (roles) {
      const newRoles = roles.map((i) => ({
        ...i,
        typeSwitch: 'role',
        filters: { name: nameSearch, status: statusSearch },
      }));
      setNewRoles(newRoles);
    }
  }, [roles]);

  async function onSubmitSearch({ nameSearch, statusSearch }) {
    setIsFetching(true);
    await roleAction.getAllRoles(
      { name: nameSearch, status: statusSearch },
      0,
      localStorage && localStorage.getItem('currentPageSizeStore')
        ? localStorage.getItem('currentPageSizeStore')
        : 10
    );
    setRefreshPagingDefault(true);
    setIsFetching(false);
  }

  async function onSubmit({ name, description, isActive }) {
    const params = {
      name,
      description,
      permissions_ids: checked.map((i) => parseInt(i)),
      status: isActive ? 1 : 2,
    };
    if (checked.length < 1) {
      setError('permission', { message: 'Please choose 1 permission' });
      return;
    }
    try {
      let res;
      if (isCreate) {
        res = await roleAction.createRole(params);
      } else {
        res = await roleAction.updateRole(params, currentRoleID);
      }

      if (res.status === 200) {
        onClose();
        resetState();
        reset();
        const message = isCreate
          ? 'Create role success'
          : 'Update role success';
        toast({
          description: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }
  function getIdsWithNoChildren(data, result = []) {
    for (const item of data) {
      if (item.children.length === 0) {
        result.push(item.id);
      } else {
        getIdsWithNoChildren(item.children, result);
      }
    }
    return result;
  }
  async function editRole(roleId) {
    const roleDetails = await roleAction.getRoleDetail(roleId);
    if (roleDetails) {
      const {
        name,
        description,
        permission,
        id,
        status,
      } = roleDetails.data.data;
      setValue('name', name);
      setValue('description', description);
      setValue('isActive', status === 1 ? true : false);
      const result = permission.reduce(
        (a, o) => (
          (a[o.parent_id] ??= []).push({ ...o, children: (a[o.id] ??= []) }), a
        ),
        {}
      )['0'];
      const permissionIDs = getIdsWithNoChildren(result);
      setCurrentRoleID(id);
      setChecked(permissionIDs);
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Role Management
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(42) && (
              <Button
                size='sm'
                onClick={() => {
                  onOpen();
                  setIsCreate(true);
                  setNodes(permissions);
                }}
                leftIcon={<AddIcon />}
                colorScheme='blue'
                variant='outline'
              >
                Add Role
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              reset();
              resetState();
            }}
            size='2xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader>
                  {isCreate ? 'Create New Role' : 'Update Role'}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Name
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Name'
                      maxLength={250}
                      mb={
                        errors.name?.message ? '5px' : { lg: '24px', sm: '0' }
                      }
                      size='md'
                      name='name'
                      {...register('name')}
                      isInvalid={errors.name}
                      errorBorderColor='red.300'
                    />
                    <Text color='red' fontSize={14} mb='10px' ml='5px'>
                      {errors.name?.message}
                    </Text>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Description
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Description'
                      mb={
                        errors.description?.message
                          ? '5px'
                          : { lg: '24px', sm: '0' }
                      }
                      size='md'
                      {...register('description')}
                      maxLength={1000}
                      name='description'
                      isInvalid={errors.description?.message}
                      errorBorderColor='red.300'
                    />
                    <Text color='red' mb='10px' ml='5px'>
                      {errors.description?.message}
                    </Text>
                    <Text color='red' ml='5px'>
                      {errors.apiError && errors.apiError?.message}
                    </Text>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Permissions
                    </FormLabel>
                    <CheckboxTree
                      checked={checked}
                      expanded={expanded}
                      nodes={nodes}
                      onCheck={handleCheckboxChange}
                      onExpand={onExpand}
                      showNodeIcon={false}
                      iconsClass='fa5'
                      icons={{
                        check: (
                          <span
                            className='rct-icon rct-icon-check'
                            style={{ color: 'var(--chakra-colors-blue-500)' }}
                          />
                        ),
                        uncheck: (
                          <span
                            className='rct-icon rct-icon-uncheck'
                            style={{ color: 'var(--chakra-colors-blue-500)' }}
                          />
                        ),
                        halfCheck: (
                          <span
                            className='rct-icon rct-icon-half-check'
                            style={{ color: 'var(--chakra-colors-blue-500)' }}
                          />
                        ),
                        expandClose: (
                          <i
                            className='fas fa-plus'
                            style={{ color: 'var(--chakra-colors-blue-500)' }}
                          />
                        ),
                        expandOpen: (
                          <i
                            className='fas fa-minus'
                            style={{ color: 'var(--chakra-colors-blue-500)' }}
                          />
                        ),
                        expandAll: <i className='fas fa-plus-square' />,
                        collapseAll: <i className='fas fa-minus-square' />,
                        leaf: <i className='fas fa-leaf' />,
                      }}
                    />
                    <Text color='red' ml='5px'>
                      {errors.permission && errors.permission?.message}
                    </Text>
                  </FormControl>
                  <FormControl>
                    <Switch
                      id='isChecked'
                      defaultChecked={isCreate && true}
                      name='isActive'
                      {...register('isActive')}
                      size='sm'
                      ms='4px'
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    {isCreate ? 'Create' : 'Update'}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                      resetState();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <Card>
          <form
            id='user-search-form'
            onSubmit={handleSubmitSearch(onSubmitSearch)}
          >
            <Flex
              direction='row'
              alignItems='center'
              mb={3}
              gap={4}
              flexWrap={'wrap'}
            >
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='xs' fontWeight='semibold'>
                  Name
                </FormLabel>
                <Input
                  _placeholder={{ color: 'gray.400' }}
                  variant='main'
                  type='text'
                  placeholder='Name'
                  minW='80px'
                  maxW='185px'
                  size='sm'
                  fontSize='sm'
                  name='nameSearch'
                  {...registerSearch('nameSearch')}
                  _focus={{ borderColor: 'blue.500' }}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Status
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue='1'
                  size='sm'
                  name='statusSearch'
                  {...registerSearch('statusSearch')}
                  width='100px'
                >
                  {RoleStatus.map((item, index) => (
                    <option key={index} value={item.Id}>
                      {item.Name}
                    </option>
                  ))}
                </Select>
              </Box>
            </Flex>
            <Flex justify='center' align='center' gap='8px' mt={3}>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<RxReset />}
                  variant='primary'
                  w='88px'
                  h='33px'
                  onClick={() => {
                    resetSearch();
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<BsSearch />}
                  variant='primary'
                  w='88px'
                  h='33px'
                  type='submit'
                >
                  Search
                </Button>
              </Box>
            </Flex>
          </form>
        </Card>
        <SearchTable2
          tableData={newRoles}
          columnsData={columnsDataRole}
          totalRow={totalRows.roles}
          actionsActive={['edit']}
          listPermissions={[43]}
          edit={editRole}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          isFetching={isFetching}
          refreshPagingDefault={refreshPagingDefault}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableRole;
