import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Textarea,
  Image,
  Box,
  Select,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchTable2 from 'components/Tables/SearchTable2';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { totalRow } from 'state/roles-permissions';
import { AddIcon } from '@chakra-ui/icons';
import { checkForbidden } from 'helpers/checkForbidden';
import { useBookingActions } from 'hooks/useBookingActions';
import { listShowAtom } from 'state/show';
import { columnsDataShow } from 'variables/columnsData';
import { useRatePlanActions } from 'hooks/useRatePlanActions';
import { STATUS } from 'variables/general';
import { BsSearch } from 'react-icons/bs';
import { RxReset } from 'react-icons/rx';
function DataTableShow() {
  const textColor = useColorModeValue('gray.700', 'white');
  const listShows = useRecoilValue(listShowAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const ratePlanActions = useRatePlanActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentShowID, setCurrentShowID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [logoImage, setLogoImage] = useState();
  const [newListShows, setNewListShows] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);
  const validationSchema = Yup.object().shape({
    code: Yup.string().required('This field is required'),
    name: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset, setValue } = useForm(
    formOptions
  );
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const statusSearch = watchSearch('statusSearch');
  const nameSearch = watchSearch('nameSearch');
  const codeSearch = watchSearch('codeSearch');

  useEffect(() => {
    if (listShows) {
      const newListShow = listShows.map((i) => ({
        ...i,
        typeSwitch: 'show',
        filters: {
          name: nameSearch,
          status: statusSearch ? statusSearch : 1,
          code: codeSearch,
        },
      }));
      setNewListShows(newListShow);
    }
  }, [listShows]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListShow(
      {
        status: statusSearch ? statusSearch : 1,
        name: nameSearch,
        code: codeSearch,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({ name, description, code, isActive, color }) {
    const params = {
      name,
      code,
      description,
      status: isActive ? 1 : 2,
      logo: logoImage,
      color,
    };
    try {
      let res;
      if (isCreate) {
        res = await bookingActions.createShow(params);
      } else {
        res = await bookingActions.updateShow(params, currentShowID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? 'Create Show success'
          : 'Update Show success';
        toast({
          description: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        await bookingActions.getListShow(
          {
            status: statusSearch ? statusSearch : 1,
            name: nameSearch,
            code: codeSearch,
          },
          0,
          localStorage && localStorage.getItem('currentPageSizeStore')
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }
  async function onSubmitSearch({ nameSearch, statusSearch, codeSearch }) {
    setIsFetching(true);
    await bookingActions.getListShow(
      {
        name: nameSearch,
        status: statusSearch,
        code: codeSearch,
      },
      0,
      localStorage && localStorage.getItem('currentPageSizeStore')
        ? localStorage.getItem('currentPageSizeStore')
        : 10
    );
    setRefreshPagingDefault(true);
    setIsFetching(false);
  }
  async function editShow(showID) {
    const showDetails = await bookingActions.getShowDetail(showID);
    if (showDetails) {
      const {
        name,
        description,
        code,
        id,
        status,
        logo,
        color,
      } = showDetails.data.data;
      setValue('name', name);
      setValue('description', description);
      setValue('code', code);
      setValue('isActive', status === 1 ? true : false);
      setCurrentShowID(id);
      setLogoImage(logo);
      setValue('color', color);
    }
    setIsCreate(false);
    onOpen();
  }
  const uploadLogo = async (val, type) => {
    const formData = new FormData();
    formData.append('file', val.target.files[0]);
    const {
      data: { data },
    } = await ratePlanActions.uploadImageRatePlanConfig(formData);
    setLogoImage(data.url);
  };
  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Show Management
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(87) && (
              <Button
                size='sm'
                onClick={() => {
                  onOpen();
                  setIsCreate(true);
                }}
                leftIcon={<AddIcon />}
                colorScheme='blue'
                variant='outline'
              >
                Add Show
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              reset();
            }}
            size='2xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader>
                  {isCreate ? 'Add New Show' : 'Edit Show'}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Code
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Name'
                      maxLength={250}
                      mb={errors.name?.code ? '5px' : { lg: '24px', sm: '0' }}
                      size='md'
                      name='code'
                      {...register('code')}
                      isInvalid={errors.code}
                      errorBorderColor='red.300'
                    />
                    <Text color='red' fontSize={14} mb='10px' ml='5px'>
                      {errors.code?.message}
                    </Text>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Name Show
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Name'
                      mb={
                        errors.name?.message ? '5px' : { lg: '24px', sm: '0' }
                      }
                      size='md'
                      name='name'
                      {...register('name')}
                      isInvalid={errors.name}
                      errorBorderColor='red.300'
                    />
                    <Text color='red' fontSize={14} mb='10px' ml='5px'>
                      {errors.name?.message}
                    </Text>
                  </FormControl>
                  <FormControl mt={4} mb={2}>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Color
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Color Hex'
                      mb={
                        errors.color?.message ? '5px' : { lg: '24px', sm: '0' }
                      }
                      size='md'
                      name='color'
                      {...register('color')}
                    />
                  </FormControl>
                  <FormControl mt={4} mb={2}>
                    <FormLabel
                      color={textColor}
                      fontWeight='bold'
                      fontSize='xs'
                    >
                      Logo
                    </FormLabel>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='file'
                      size='sm'
                      onChange={(val) => uploadLogo(val, 1)}
                    />
                    {logoImage && (
                      <Image
                        src={logoImage}
                        width='200px'
                        height='200px'
                        objectFit='contain'
                      />
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                      Description
                    </FormLabel>
                    <Textarea
                      rows={4}
                      {...register('description')}
                      maxLength={1000}
                      name='description'
                      mb={
                        errors.description_vi?.message
                          ? '5px'
                          : { lg: '24px', sm: '0' }
                      }
                    />
                    <Text color='red' ml='5px'>
                      {errors.apiError && errors.apiError?.message}
                    </Text>
                  </FormControl>
                  <FormControl>
                    <Switch
                      id='isChecked'
                      defaultChecked={isCreate && true}
                      name='isActive'
                      {...register('isActive')}
                      size='sm'
                      ms='4px'
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    {isCreate ? 'Create' : 'Update'}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <Card>
          <form
            id='user-search-form'
            onSubmit={handleSubmitSearch(onSubmitSearch)}
          >
            <Flex
              direction='row'
              alignItems='center'
              mb={3}
              gap={4}
              flexWrap={'wrap'}
            >
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='xs' fontWeight='semibold'>
                  Show Name
                </FormLabel>
                <Input
                  _placeholder={{ color: 'gray.400' }}
                  variant='main'
                  type='text'
                  placeholder='Name'
                  minW='80px'
                  maxW='185px'
                  size='sm'
                  fontSize='sm'
                  name='nameSearch'
                  {...registerSearch('nameSearch')}
                  _focus={{ borderColor: 'blue.500' }}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='xs' fontWeight='semibold'>
                  Code
                </FormLabel>
                <Input
                  _placeholder={{ color: 'gray.400' }}
                  variant='main'
                  type='text'
                  placeholder='Code'
                  minW='80px'
                  maxW='185px'
                  size='sm'
                  fontSize='sm'
                  name='codeSearch'
                  {...registerSearch('codeSearch')}
                  _focus={{ borderColor: 'blue.500' }}
                />
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Status
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue='1'
                  size='sm'
                  name='statusSearch'
                  {...registerSearch('statusSearch')}
                  width='100px'
                >
                  {STATUS.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
            </Flex>
            <Flex justify='center' align='center' gap='8px' mt={3}>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<RxReset />}
                  variant='primary'
                  w='88px'
                  h='33px'
                  onClick={() => {
                    resetSearch();
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<BsSearch />}
                  variant='primary'
                  w='88px'
                  h='33px'
                  type='submit'
                >
                  Search
                </Button>
              </Box>
            </Flex>
          </form>
        </Card>
        <SearchTable2
          tableData={newListShows}
          columnsData={columnsDataShow}
          totalRow={totalRows.shows}
          edit={editShow}
          actionsActive={['edit']}
          listPermissions={[88]}
          isFetching={isFetching}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          refreshPagingDefault={refreshPagingDefault}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableShow;
