import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Switch,
  Stack,
  Box,
  Select,
  Grid,
  Input,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { columnsDataTarget } from "variables/columnsData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { useBookingActions } from "hooks/useBookingActions";
import {
  locationListAtom,
  listShowAtom,
  listTargetAtom,
  listChannelAtom,
} from "state/show";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import { STATUS } from "variables/general";
import { Select as ReactSelect } from "chakra-react-select";
import { NumericFormat } from "react-number-format";

function DataTableTarget() {
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "200px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 20,
    }),
  };

  const textColor = useColorModeValue("gray.700", "white");
  const listTarget = useRecoilValue(listTargetAtom);
  const listLocation = useRecoilValue(locationListAtom);
  const listShow = useRecoilValue(listShowAtom);
  const listChannel = useRecoilValue(listChannelAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFetching, setIsFetching] = useState(false);
  const [newListTarget, setNewListTarget] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);
  const [currentTargetID, setCurrentTargetID] = useState();
  const [isCreate, setIsCreate] = useState(true);

  const validationSchema = Yup.object().shape({
    show: Yup.object().required("This field is required"),
    location: Yup.object().required("This field is required"),
    channel: Yup.object().required("This field is required"),
    year: Yup.object().required("This field is required"),
    month: Yup.object().required("This field is required"),
    target_net: Yup.string().required("This field is required"),
    target_ticket: Yup.string().required("This field is required"),
    target_value: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const statusSearch = watchSearch("statusSearch");
  const showID = watchSearch("show_id");
  const locationID = watchSearch("location_id");
  const yearID = watchSearch("year");
  const monthID = watchSearch("month");
  const channelID = watchSearch("channel_id");
  const toast = useToast();

  useEffect(() => {
    bookingActions.getListVenue({ status: 1 }, 0, "");
    bookingActions.getListShow({ status: 1 }, 0, "");
    bookingActions.getListLocations({ status: 1 }, 0, "");
    bookingActions.getListChannel({ status: 1 }, 0, "");
  }, []);
  useEffect(() => {
    if (listTarget) {
      const newListTarget = listTarget.map((i) => ({
        ...i,
        typeSwitch: "listTarget",
        filters: {
          status: statusSearch,
          show_id: showID,
          location_id: locationID,
          year: yearID,
          month: monthID,
          channel_id: channelID,
        },
      }));
      setNewListTarget(newListTarget);
    }
  }, [listTarget]);

  async function onSubmitSearch({
    statusSearch,
    show_id,
    location_id,
    year,
    month,
    channel_id,
  }) {
    setIsFetching(true);
    await bookingActions.getListTarget(
      { status: statusSearch, show_id, location_id, year, month, channel_id },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setRefreshPagingDefault(true);
    setIsFetching(false);
  }
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListTarget(
      {
        status: statusSearch ? statusSearch : 1,
        show_id: showID,
        location_id: locationID,
        year: yearID,
        month: monthID,
        channel_id: channelID,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({
    isActive,
    show,
    target_net,
    target_ticket,
    target_value,
    location,
    channel,
    year,
    month,
  }) {
    const params = {
      show_id: show.id,
      location_id: location.id,
      channel_id: channel.id,
      target_net: parseInt(target_net.replace(/^0+|,/g, "")),
      target_ticket: parseInt(target_ticket.replace(/^0+|,/g, "")),
      target_value: parseInt(target_value.replace(/^0+|,/g, "")),
      year: year.id,
      month: month.id,
      status: isActive ? 1 : 2,
    };
    try {
      let res;
      if (isCreate) {
        res = await bookingActions.createTarget(params);
      } else {
        res = await bookingActions.updateTarget(params, currentTargetID);
      }
      if (res.status === 200) {
        onClose();
        const message = isCreate
          ? "Create Target success"
          : "Update Target success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await bookingActions.getListTarget(
          {
            show_id: showID,
            status: statusSearch ? statusSearch : 1,
            location_id: locationID,
            year: yearID,
            month: monthID,
            channel_id: channelID,
          },
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
        );
      }
    } catch (err) {
      console.warn(err);
    } finally {
      onClose();
      reset();
    }
  }

  async function editTarget(targetId) {
    const target = await bookingActions.getTargetDetail(targetId);
    if (target) {
      const {
        target_value,
        target_net,
        target_ticket,
        id,
        status,
        channel,
        location,
        year,
        month,
        show,
        show_id,
        channel_id,
        location_id,
      } = target.data.data;
      setValue("target_value", target_value.toString());
      setValue("target_net", target_net.toString());
      setValue("target_ticket", target_ticket.toString());
      setValue("channel", { name: channel.name, id: channel_id });
      setValue("location", { name: location.name, id: location_id });
      setValue("year", { name: year, id: year });
      setValue("month", { name: month, id: month });
      setValue("show", { name: show.name, id: show_id });
      setValue("isActive", status === 1 ? true : false);
      setCurrentTargetID(id);
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Card px="0px">
      <CardHeader px="22px" mb="12px">
        <Flex direction="column" display="block">
          <Text
            float="left"
            color={textColor}
            fontSize="lg"
            fontWeight="bold"
            mb="6px"
          >
            List Target Management
          </Text>
          <Stack float="right" direction="row" spacing={4}>
            {checkForbidden(91) && (
              <Button
                size="sm"
                onClick={() => {
                  onOpen();
                  setIsCreate(true);
                  reset();
                }}
                leftIcon={<AddIcon />}
                colorScheme="green"
                variant="outline"
              >
                Add Target
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              reset();
              onClose();
            }}
            size="2xl"
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader>
                  {isCreate ? "Add New Target" : "Edit Target"}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Grid
                    gap={{ base: "8px", lg: "16px" }}
                    templateColumns={{
                      base: "repeat(1, 1fr)",
                      lg: "repeat(2, 1fr)",
                    }}
                  >
                    <FormControl mb={{ lg: "24px", sm: "4px" }} isRequired>
                      <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                        Show
                      </FormLabel>
                      <Controller
                        name="show"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            focusBorderColor="blue.500"
                            chakraStyles={chakraStyles}
                            options={listShow}
                            placeholder="Select Show"
                            getOptionLabel={(i) => i.name}
                            getOptionValue={(option) => option.id}
                            size="sm"
                            className="chakra-select__input"
                          />
                        )}
                      />
                      <Text color="red" ml="5px">
                        {errors.show && errors.show?.message}
                      </Text>
                    </FormControl>
                    <FormControl mb={{ lg: "24px", sm: "4px" }} isRequired>
                      <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                        Location
                      </FormLabel>
                      <Controller
                        name="location"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            focusBorderColor="blue.500"
                            chakraStyles={chakraStyles}
                            options={listLocation}
                            placeholder="Select Location"
                            getOptionLabel={(i) => i.name}
                            getOptionValue={(option) => option.id}
                            size="sm"
                            className="chakra-select__input"
                          />
                        )}
                      />
                      <Text color="red" ml="5px">
                        {errors.location && errors.location?.message}
                      </Text>
                    </FormControl>
                  </Grid>
                  <Grid
                    gap={{ base: "8px", lg: "16px" }}
                    templateColumns={{ lg: "repeat(3, 1fr)", sm: "1fr" }}
                  >
                    <FormControl mb={{ lg: "24px", sm: "4px" }} isRequired>
                      <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                        Channel
                      </FormLabel>
                      <Controller
                        name="channel"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            focusBorderColor="blue.500"
                            chakraStyles={chakraStyles}
                            options={listChannel}
                            placeholder="Select Channel"
                            getOptionLabel={(i) => i.name}
                            getOptionValue={(option) => option.id}
                            size="sm"
                            className="chakra-select__input"
                          />
                        )}
                      />
                      <Text color="red" ml="5px">
                        {errors.channel && errors.channel?.message}
                      </Text>
                    </FormControl>
                    <FormControl mb={{ lg: "24px", sm: "4px" }} isRequired>
                      <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                        Year
                      </FormLabel>
                      <Controller
                        name="year"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            focusBorderColor="blue.500"
                            chakraStyles={chakraStyles}
                            options={Array.from({ length: 11 }, (_, i) => ({
                              id: new Date().getFullYear() - 5 + i,
                              name: (
                                new Date().getFullYear() -
                                5 +
                                i
                              ).toString(),
                            }))}
                            placeholder="Select Year"
                            getOptionLabel={(i) => i.name}
                            getOptionValue={(option) => option.id}
                            size="sm"
                            className="chakra-select__input"
                          />
                        )}
                      />
                      <Text color="red" ml="5px">
                        {errors.year && errors.year?.message}
                      </Text>
                    </FormControl>
                    <FormControl mb={{ lg: "24px", sm: "4px" }} isRequired>
                      <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                        Month
                      </FormLabel>
                      <Controller
                        name="month"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            focusBorderColor="blue.500"
                            chakraStyles={chakraStyles}
                            options={[
                              { id: 1, name: 1 },
                              { id: 2, name: 2 },
                              { id: 3, name: 3 },
                              { id: 4, name: 4 },
                              { id: 5, name: 5 },
                              { id: 6, name: 6 },
                              { id: 7, name: 7 },
                              { id: 8, name: 8 },
                              { id: 9, name: 9 },
                              { id: 10, name: 10 },
                              { id: 11, name: 11 },
                              { id: 12, name: 12 },
                            ]}
                            placeholder="Select Month"
                            getOptionLabel={(i) => i.name}
                            getOptionValue={(option) => option.id}
                            size="sm"
                            className="chakra-select__input"
                          />
                        )}
                      />
                      <Text color="red" ml="5px">
                        {errors.month && errors.month?.message}
                      </Text>
                    </FormControl>
                  </Grid>
                  <Grid
                    gap={{ base: "8px", lg: "16px" }}
                    templateColumns={{ lg: "repeat(3, 1fr)", sm: "1fr" }}
                  >
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Target Net
                      </FormLabel>
                      <Controller
                        control={control}
                        name="target_net"
                        render={({ field }) => (
                          <NumericFormat
                            customInput={Input}
                            thousandSeparator=","
                            fontSize="sm"
                            ms="4px"
                            placeholder="Target Net"
                            size="sm"
                            {...field}
                          />
                        )}
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.target_net?.message}
                      </Text>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Target Ticket
                      </FormLabel>
                      <Controller
                        control={control}
                        name="target_ticket"
                        render={({ field }) => (
                          <NumericFormat
                            customInput={Input}
                            thousandSeparator=","
                            fontSize="sm"
                            ms="4px"
                            placeholder="Target Ticket"
                            size="sm"
                            {...field}
                          />
                        )}
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.target_ticket?.message}
                      </Text>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Target Value
                      </FormLabel>
                      <Controller
                        control={control}
                        name="target_value"
                        render={({ field }) => (
                          <NumericFormat
                            customInput={Input}
                            thousandSeparator=","
                            fontSize="sm"
                            ms="4px"
                            placeholder="Target Value"
                            size="sm"
                            {...field}
                          />
                        )}
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.target_value?.message}
                      </Text>
                    </FormControl>
                  </Grid>
                  <FormControl>
                    <Switch
                      id="isChecked"
                      defaultChecked={true}
                      name="isActive"
                      {...register("isActive")}
                      size="sm"
                      ms="4px"
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {isCreate ? "Create" : "Update"}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <Card>
          <form
            id="user-search-form"
            onSubmit={handleSubmitSearch(onSubmitSearch)}
          >
            <Flex
              direction="row"
              alignItems="center"
              mb={3}
              gap={4}
              flexWrap={"wrap"}
            >
              <Box direction="row" alignItems="center" display="block">
                <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                  Show
                </FormLabel>
                <Select
                  ms="4px"
                  type="text"
                  size="sm"
                  name="show_id"
                  {...registerSearch("show_id")}
                  width="200px"
                >
                  <option value="">All Show</option>
                  {listShow.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction="row" alignItems="center" display="block">
                <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                  Channel
                </FormLabel>
                <Select
                  ms="4px"
                  type="text"
                  size="sm"
                  name="channel_id"
                  {...registerSearch("channel_id")}
                  width="200px"
                >
                  <option value="">All Channel</option>
                  {listChannel.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction="row" alignItems="center" display="block">
                <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                  Location
                </FormLabel>
                <Select
                  ms="4px"
                  type="text"
                  size="sm"
                  name="location_id"
                  {...registerSearch("location_id")}
                  width="200px"
                >
                  <option value="">All Location</option>
                  {listLocation.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction="row" alignItems="center" display="block">
                <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                  Year
                </FormLabel>
                <Select
                  ms="4px"
                  type="text"
                  size="sm"
                  name="year"
                  {...registerSearch("year")}
                  width="200px"
                  defaultValue={new Date().getFullYear()}
                >
                  {Array.from(
                    { length: 11 },
                    (_, i) => new Date().getFullYear() - 5 + i
                  ).map((i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction="row" alignItems="center" display="block">
                <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                  Month
                </FormLabel>
                <Select
                  ms="4px"
                  type="text"
                  size="sm"
                  name="month"
                  {...registerSearch("month")}
                  width="200px"
                >
                  <option value="">All Month</option>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction="row" alignItems="center" display="block">
                <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                  Status
                </FormLabel>
                <Select
                  ms="4px"
                  type="text"
                  defaultValue="1"
                  size="sm"
                  name="statusSearch"
                  {...registerSearch("statusSearch")}
                  width="100px"
                >
                  {STATUS.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
            </Flex>
            <Flex justify="center" align="center" gap="8px" mt={3}>
              <Box direction="row" alignItems="center" display="block">
                <Button
                  leftIcon={<RxReset />}
                  variant="primary"
                  w="88px"
                  h="33px"
                  onClick={() => {
                    resetSearch();
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box direction="row" alignItems="center" display="block">
                <Button
                  leftIcon={<BsSearch />}
                  variant="primary"
                  w="88px"
                  h="33px"
                  type="submit"
                >
                  Search
                </Button>
              </Box>
            </Flex>
          </form>
        </Card>
        <SearchTable2
          tableData={newListTarget}
          columnsData={columnsDataTarget}
          totalRow={totalRows.listTarget}
          actionsActive={["edit"]}
          edit={editTarget}
          listPermissions={[43]}
          isFetching={isFetching}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          refreshPagingDefault={refreshPagingDefault}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableTarget;
