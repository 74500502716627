import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Box,
  Select,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchTable2 from 'components/Tables/SearchTable2';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  columnsDataShowSchedule,
  columnsDataShowScheduleDateTime,
} from 'variables/columnsData';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { totalRow } from 'state/roles-permissions';
import { AddIcon } from '@chakra-ui/icons';
import { checkForbidden } from 'helpers/checkForbidden';
import { useBookingActions } from 'hooks/useBookingActions';
import {
  showsScheduleListAtom,
  venueListAtom,
  listShowAtom,
  seatingPlanListAtom,
  showTimeListAtom,
} from 'state/show';
import { BsSearch } from 'react-icons/bs';
import { RxReset } from 'react-icons/rx';
import { STATUS } from 'variables/general';
import { Select as ReactSelect } from 'chakra-react-select';
import { useHistory } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
function DataTableShowSchedule() {
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: '20px',
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '200px',
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 20,
    }),
  };

  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');
  const listShowSchedule = useRecoilValue(showsScheduleListAtom);
  const listVenue = useRecoilValue(venueListAtom);
  const listShow = useRecoilValue(listShowAtom);
  const listSeatingPlan = useRecoilValue(seatingPlanListAtom);
  const showTimeList = useRecoilValue(showTimeListAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFetching, setIsFetching] = useState(false);
  const [newShowSchedule, setNewShowSchedule] = useState([]);
  const [dateTimeSelected, setDateTimeSelected] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);

  const validationSchema = Yup.object().shape({
    show: Yup.object().required('This field is required'),
    venue: Yup.object().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
    setError,
    clearErrors,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const venue = watch('venue');
  const show = watch('show');
  const seatingPlan = watch('seatingPlan');

  const statusSearch = watchSearch('statusSearch');
  const showID = watchSearch('show_id');
  const venueID = watchSearch('venue_id');

  useEffect(() => {
    bookingActions.getListVenue({ status: 1 }, 0, '');
    bookingActions.getListShow({ status: 1 }, 0, '');
    bookingActions.getListShowTime({ status: 1 }, 0, '');
  }, []);
  useEffect(() => {
    if (listShowSchedule) {
      const newListShowSchedule = listShowSchedule.map((i) => ({
        ...i,
        typeSwitch: 'showsSchedule',
        filters: { status: statusSearch, show_id: showID, venue_id: venueID },
      }));
      setNewShowSchedule(newListShowSchedule);
    }
  }, [listShowSchedule]);
  useEffect(() => {
    if (show && venue) {
      bookingActions.getListSeatingPlan({
        status: 1,
        venue_id: venue.id,
        show_id: show.id,
      });
    }
  }, [show, venue]);
  async function onSubmitSearch({ statusSearch, show_id, venue_id }) {
    setIsFetching(true);
    await bookingActions.getListShowSchedule(
      { status: statusSearch, show_id, venue_id },
      0,
      localStorage && localStorage.getItem('currentPageSizeStore')
        ? localStorage.getItem('currentPageSizeStore')
        : 10
    );
    setRefreshPagingDefault(true);
    setIsFetching(false);
  }
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListShowSchedule(
      {
        status: statusSearch ? statusSearch : 1,
        show_id: showID,
        venue_id: venueID,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  const confirmAdd = () => {
    clearErrors('timeSelectedError');
    const selectedTimeIds = watch('time');
    const selectedTimes = showTimeList
      .filter((time) => selectedTimeIds.includes(time.id.toString()))
      .map((time) => time.name);

    const selectedDateTime = selectedTimes.map((time, index) => ({
      id: `${index + 1}_${moment(watch('date')).format('DD/MM/YYYY')}`,
      date: moment(watch('date')).format('DD/MM/YYYY'),
      times: time,
    }));

    setDateTimeSelected((prevSelected) => [
      ...prevSelected,
      ...selectedDateTime,
    ]);
    setValue(
      'date',
      new Date(watch('date')).setDate(new Date(watch('date')).getDate() + 1)
    );
  };
  async function onSubmit({ isActive, show, venue, seatingPlan }) {
    if (!seatingPlan) {
      toast({
        description: 'Please add seating plan',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }
    if (dateTimeSelected.length < 1) {
      setError('timeSelectedError', {
        message: 'Please choose at least one date time',
      });
      return;
    }

    const newShowTime = dateTimeSelected.map((i) => ({
      date: moment(i.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      time: i.times,
    }));
    const params = {
      venue_id: venue.id,
      show_id: show.id,
      seatingplan_id: seatingPlan.id,
      status: isActive ? 1 : 2,
      show_times: newShowTime,
    };
    try {
      const res = await bookingActions.createShowSchedule(params);
      if (res.status === 200) {
        onClose();
        reset();
        const message = 'Create Show Schedule success';
        toast({
          description: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        await bookingActions.getListShowSchedule(
          {
            show_id: showID,
            venue_id: venueID,
            status: statusSearch ? statusSearch : 1,
          },
          0,
          localStorage && localStorage.getItem('currentPageSizeStore')
        );
      }
    } catch (err) {
      console.warn(err);
      // toast({
      //   description: err.response.data.meta.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "top-right",
      // });
    }
  }

  const deleteDateTime = (item) => {
    const newItem = dateTimeSelected.filter((i) => i.id !== item);
    setDateTimeSelected(newItem);
  };

  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Show Schedule Management
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(91) && (
              <Button
                size='sm'
                onClick={() => {
                  onOpen();
                }}
                leftIcon={<AddIcon />}
                colorScheme='blue'
                variant='outline'
              >
                Add Show Schedule
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              reset();
              setDateTimeSelected([]);
            }}
            size='2xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader>Add New Show Schedule</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <FormControl mb={{ lg: '24px', sm: '4px' }} isRequired>
                    <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                      Venue
                    </FormLabel>
                    <Controller
                      name='venue'
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          focusBorderColor='blue.500'
                          chakraStyles={chakraStyles}
                          options={listVenue}
                          placeholder='Select Venue'
                          getOptionLabel={(i) => i.name}
                          getOptionValue={(option) => option.id}
                          size='sm'
                          className='chakra-select__input'
                        />
                      )}
                    />
                    <Text color='red' ml='5px'>
                      {errors.venue && errors.venue?.message}
                    </Text>
                  </FormControl>
                  <FormControl mb={{ lg: '24px', sm: '4px' }} isRequired>
                    <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                      Show
                    </FormLabel>
                    <Controller
                      name='show'
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          focusBorderColor='blue.500'
                          chakraStyles={chakraStyles}
                          options={listShow}
                          placeholder='Select Show'
                          getOptionLabel={(i) => i.name}
                          getOptionValue={(option) => option.id}
                          size='sm'
                          className='chakra-select__input'
                        />
                      )}
                    />
                    <Text color='red' ml='5px'>
                      {errors.show && errors.show?.message}
                    </Text>
                  </FormControl>
                  {show && venue && (
                    <FormControl mb={{ lg: '24px', sm: '4px' }} isRequired>
                      <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                        Seating Plan
                      </FormLabel>
                      <Controller
                        name='seatingPlan'
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            focusBorderColor='blue.500'
                            chakraStyles={chakraStyles}
                            options={listSeatingPlan}
                            placeholder='Select Seating Plan'
                            getOptionLabel={(i) => i.name}
                            getOptionValue={(option) => option.id}
                            size='sm'
                            className='chakra-select__input'
                          />
                        )}
                      />
                    </FormControl>
                  )}
                  {venue && show && listSeatingPlan.length < 1 && (
                    <Flex>
                      Seating plan is empty, click{' '}
                      <a
                        onClick={() =>
                          history.push('/admin/setting/seatingplan')
                        }
                        style={{
                          paddingLeft: '5px',
                          paddingRight: '5px',
                          color: 'blue',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        here
                      </a>
                      to add seating plan.
                    </Flex>
                  )}
                  {seatingPlan && (
                    <Flex alignItems='center' gap={10}>
                      <FormControl maxW='400px' width='auto' isRequired>
                        <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                          Date
                        </FormLabel>
                        <Controller
                          name='date'
                          control={control}
                          render={({ field }) => (
                            <ReactDatePicker
                              customInput={<Input />}
                              className='booking-date-range-search'
                              onChange={(value) => field.onChange(value)}
                              selected={field?.value}
                              dateFormat='dd/MM/yyyy'
                              showYearDropdown
                              minDate={new Date()}
                              calendarStartDay={1}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl maxW='400px'>
                        <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                          Time
                        </FormLabel>
                        <Controller
                          name='time'
                          control={control}
                          render={({ field }) => (
                            <CheckboxGroup {...field}>
                              <Flex flexWrap={'wrap'} gap={2}>
                                {showTimeList.map((i) => (
                                  <Checkbox value={i.id.toString()} key={i.id}>
                                    {i.name}
                                  </Checkbox>
                                ))}
                              </Flex>
                            </CheckboxGroup>
                          )}
                        />
                      </FormControl>
                    </Flex>
                  )}
                  {seatingPlan && (
                    <Box mt={2} mb={2}>
                      <Button
                        variant='primary'
                        h='33px'
                        onClick={() => confirmAdd()}
                      >
                        Save and Add more
                      </Button>
                    </Box>
                  )}
                  {dateTimeSelected?.length > 0 && (
                    <div style={{ zIndex: 10000 }}>
                      <SearchTable2
                        tableData={dateTimeSelected}
                        columnsData={columnsDataShowScheduleDateTime}
                        totalRow={dateTimeSelected.length}
                        actionsActive={['delete']}
                        deleteRow={(i) => deleteDateTime(i)}
                        manualPagination={false}
                        listPermissions={[43]}
                        manualSorting={false}
                      />
                    </div>
                  )}
                  <Text color='red' ml='5px'>
                    {errors.timeSelectedError &&
                      errors.timeSelectedError.message}
                  </Text>
                  <FormControl>
                    <Switch
                      id='isChecked'
                      defaultChecked={true}
                      name='isActive'
                      {...register('isActive')}
                      size='sm'
                      ms='4px'
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    Create
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                      setDateTimeSelected([]);
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <Card>
          <form
            id='user-search-form'
            onSubmit={handleSubmitSearch(onSubmitSearch)}
          >
            <Flex
              direction='row'
              alignItems='center'
              mb={3}
              gap={4}
              flexWrap={'wrap'}
            >
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='xs' fontWeight='semibold'>
                  Show
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  size='sm'
                  name='show_id'
                  {...registerSearch('show_id')}
                  width='200px'
                >
                  <option value=''>All Show</option>
                  {listShow.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel ms='4px' fontSize='xs' fontWeight='semibold'>
                  Venue
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  size='sm'
                  name='venue_id'
                  {...registerSearch('venue_id')}
                  width='200px'
                >
                  <option value=''>All Venue</option>
                  {listVenue.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <FormLabel fontWeight='semibold' fontSize='xs' ms='4px'>
                  Status
                </FormLabel>
                <Select
                  ms='4px'
                  type='text'
                  defaultValue='1'
                  size='sm'
                  name='statusSearch'
                  {...registerSearch('statusSearch')}
                  width='100px'
                >
                  {STATUS.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
            </Flex>
            <Flex justify='center' align='center' gap='8px' mt={3}>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<RxReset />}
                  variant='primary'
                  w='88px'
                  h='33px'
                  onClick={() => {
                    resetSearch();
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box direction='row' alignItems='center' display='block'>
                <Button
                  leftIcon={<BsSearch />}
                  variant='primary'
                  w='88px'
                  h='33px'
                  type='submit'
                >
                  Search
                </Button>
              </Box>
            </Flex>
          </form>
        </Card>
        <SearchTable2
          tableData={newShowSchedule}
          columnsData={columnsDataShowSchedule}
          totalRow={totalRows.showsSchedule}
          actionsActive={['showDetailManage']}
          showDetailManage={(item) => {
            localStorage.setItem(
              'currentShow',
              JSON.stringify({
                venueName: item.venue.name,
                showName: item.show.name,
                seatingPlanName: item.seatingplan.name,
              })
            );
            history.push(`/admin/management/schedule-show/detail/${item.id}`);
          }}
          listPermissions={[]}
          isFetching={isFetching}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          refreshPagingDefault={refreshPagingDefault}
        />
      </CardBody>
    </Card>
  );
}

export default DataTableShowSchedule;
