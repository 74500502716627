import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Grid,
  Image,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchTable2 from 'components/Tables/SearchTable2';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { totalRow } from 'state/roles-permissions';
import { AddIcon } from '@chakra-ui/icons';
import { checkForbidden } from 'helpers/checkForbidden';
import { useCMSActions } from 'hooks/useCMS';
import Editor from 'components/Editor/Editor2';
import { columnsDataPartner } from 'variables/columnsData';
import { useRatePlanActions } from 'hooks/useRatePlanActions';
import { partnerAtom } from 'state/cms';

function DataTablePartner() {
  const textColor = useColorModeValue('gray.700', 'white');
  const listPartner = useRecoilValue(partnerAtom);
  const totalRows = useRecoilValue(totalRow);
  const cmsActions = useCMSActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentNewsGroupID, setCurrentNewsGroupID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListPartner, setNewListPartner] = useState([]);
  const [previewLogo, setPreviewLogo] = useState('');
  const ratePlanActions = useRatePlanActions();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const imageURL = watch('logo');

  useEffect(async () => {
    if (imageURL && imageURL?.length > 0) {
      const formData = new FormData();
      formData.append('file', imageURL[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewLogo(data.url);
    }
  }, [imageURL]);
  useEffect(() => {
    if (listPartner) {
      const partners = listPartner.map((i) => ({
        ...i,
        filters: { name: '', status: 1 },
        typeSwitch: 'partner',
      }));
      setNewListPartner(partners);
    }
  }, [listPartner]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListPartners(
      {
        name: '',
        status: 1,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({ name, description, url, id, status, isActive }) {
    const params = {
      name,
      logo: previewLogo,
      url,
      description,
      id,
      status,
      status: isActive ? 1 : 2,
    };
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createPartners(params);
      } else {
        res = await cmsActions.updatePartners(params, currentNewsGroupID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? 'Create News Partner success'
          : 'Update News Partner success';
        toast({
          description: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        await cmsActions.getListPartners(
          {
            name: '',
            status: 1,
          },
          0,
          localStorage && localStorage.getItem('currentPageSizeStore')
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }

  async function editNews(partnerID) {
    const partnerData = await cmsActions.getDetailPartners(partnerID);
    if (partnerData) {
      const {
        name,
        logo,
        url,
        id,
        status,
        description,
      } = partnerData.data.data;
      setValue('name', name);
      setPreviewLogo(logo);
      setValue('url', url);
      setValue('description', description);
      setValue('isActive', status === 1 ? true : false);
      setCurrentNewsGroupID(id);
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Card px='0px'>
      <CardHeader px='22px' mb='12px'>
        <Flex direction='column' display='block'>
          <Text
            float='left'
            color={textColor}
            fontSize='lg'
            fontWeight='bold'
            mb='6px'
          >
            Partner Management
          </Text>
          <Stack float='right' direction='row' spacing={4}>
            {checkForbidden(42) && (
              <Button
                size='sm'
                onClick={() => {
                  onOpen();
                  setIsCreate(true);
                }}
                leftIcon={<AddIcon />}
                colorScheme='blue'
                variant='outline'
              >
                Add Partner
              </Button>
            )}
          </Stack>
          <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              reset();
              setPreviewLogo('');
            }}
            size='4xl'
          >
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalContent>
                <ModalHeader>
                  {isCreate ? 'Add Partner' : 'Edit Partner'}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Grid
                    templateColumns={{
                      sm: '1fr 1fr',
                      lg: 'repeat(2, 1fr)',
                    }}
                    gap='30px'
                    mt={2}
                  >
                    <FormControl isRequired>
                      <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                        Name
                      </FormLabel>
                      <Input
                        fontSize='sm'
                        ms='4px'
                        type='text'
                        placeholder='Name'
                        maxLength={250}
                        size='md'
                        name='name'
                        {...register('name')}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                        Url
                      </FormLabel>
                      <Input
                        fontSize='sm'
                        ms='4px'
                        type='text'
                        placeholder='Url'
                        maxLength={250}
                        size='md'
                        name='url'
                        maxW={250}
                        {...register('url')}
                      />
                    </FormControl>
                  </Grid>
                  <FormControl mt={3}>
                    <FormLabel
                      color={textColor}
                      fontWeight='bold'
                      fontSize='xs'
                    >
                      Description
                    </FormLabel>
                    <Editor name='description' control={control} />
                  </FormControl>
                  <Grid
                    templateColumns={{
                      sm: '1fr 1fr',
                      lg: 'repeat(2, 1fr)',
                    }}
                    gap='30px'
                    mt={2}
                  >
                    <FormControl>
                      <FormLabel
                        color={textColor}
                        fontWeight='bold'
                        fontSize='xs'
                      >
                        Logo
                      </FormLabel>
                      <Input
                        fontSize='sm'
                        ms='4px'
                        type='file'
                        placeholder='Logo'
                        size='sm'
                        name='logo'
                        {...register('logo')}
                      />
                      {previewLogo && <Image src={previewLogo} />}
                    </FormControl>
                  </Grid>
                  <FormControl>
                    <Switch
                      id='isChecked'
                      defaultChecked={isCreate && true}
                      name='isActive'
                      {...register('isActive')}
                      size='sm'
                      ms='4px'
                    >
                      Active
                    </Switch>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme='blue'
                    mr={3}
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    {isCreate ? 'Create' : 'Update'}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      reset();
                      setPreviewLogo('');
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
      </CardHeader>
      <CardBody>
        <SearchTable2
          tableData={newListPartner}
          columnsData={columnsDataPartner}
          totalRow={totalRows.newsGroup}
          edit={editNews}
          actionsActive={['edit']}
          listPermissions={[43]}
          isFetching={isFetching}
          loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
        />
      </CardBody>
    </Card>
  );
}

export default DataTablePartner;
