import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "state/recoil-root";
import instance from "helpers/instance";
import { totalRow } from "state/roles-permissions";

import { checkPaymentAtom } from "state/check-payment";

const useCheckPayment = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [commission, setCheckPayment] = useRecoilState(checkPaymentAtom);
    const [totalRows, setTotalRows] = useRecoilState(totalRow);
  const headers = {
    Authorization: `Bearer ${auth?.access_token}`,
  };
  return {
    getListCheckPayment,
    exportCheckPayment
  };


  function getListCheckPayment(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "booking/v1/reports/payment",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
          setCheckPayment(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            checkPayment: res.data.meta.total,
          }));
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function exportCheckPayment(filter, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      ...filter,
      offset,
      limit: pageSize,
    };
    headers.responseType = "blob";
    return instance({
      url: "booking/v1/reports/payment/export",
      method: "GET",
      params,
      responseType: "blob",
      headers,
    });
  }

  
}
export { useCheckPayment };
