import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "state/recoil-root";
import instance from "helpers/instance";
import { totalRow } from "state/roles-permissions";

import { vouchersAtom } from "state/vouchers";

const useVoucherTrackingReport = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [vouchers, setVouchers] = useRecoilState(vouchersAtom);
    const [totalRows, setTotalRows] = useRecoilState(totalRow);
  const headers = {
    Authorization: `Bearer ${auth?.access_token}`,
  };
  return {
    getListVoucherTracking,
    exportVoucherList
  };


  function getListVoucherTracking(filter, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: "voucher/v1/vouchers/reports",
      method: "GET",
      headers,
      params,
    })
      .then((res) => {
        if (res.status === 200) {
            setVouchers(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            vouchers: res.data.meta.total,
          }));
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function exportVoucherList(filter, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      ...filter,
      offset,
      limit: pageSize,
    };
    headers.responseType = "blob";
    return instance({
      url: "voucher/v1/vouchers/reports/export",
      method: "GET",
      params,
      responseType: "blob",
      headers,
    });
  }

  
}
export { useVoucherTrackingReport };
